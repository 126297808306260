import axios from "axios";

const uri = `${process.env.REACT_APP_BACKEND_KEY}/services`;

const getAllServices = async () => {
  try {
    const response = await axios.get(`${uri}/fetch`, {});
    return response;
  } catch (error) {
    return error.response;
  }
};
const getService = async (_id) => {
  try {
    const response = await axios.post(`${uri}/fetch`, { _id });
    return response;
  } catch (error) {
    return error.response;
  }
};
const createService = async (service) => {
  try {
    const response = await axios.post(`${uri}/crud`, { service });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error.response.data);
    return error.response;
  }
};

const updateService = async (service) => {
  try {
    // const response = await axios.put(`${uri}/crud`, {
    //   _id: service._id,
    //   service,
    // });
    console.log(service);
    return { status: 200 };
  } catch (error) {
    return error.response;
  }
};

const deleteService = async (id) => {
  console.log(id)
  try {
    const response = await axios.delete(`${uri}/crud`, { data: { _id: id } });
    return response;
  } catch (error) {
    return error.response;
  }
};
export {
  getAllServices,
  getService,
  updateService,
  createService,
  deleteService,
};
