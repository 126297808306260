import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Input,
  Textarea,
  Table,
  Tbody,
  Tr,
  Td,
  useToast,
  Heading,
  Divider,
  Switch,
  Tabs,
  TabList,
  Tab,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { createService } from "../../../services/admin/services";
import { useSelector } from "react-redux";
import EmpBar from "../../EmpBar";
import ServiceForms from "../../../utils/ServiceForms";

const CreateService = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [serviceData, setServiceData] = useState({
    name: "",
    description: "",
    cost: 0,
    documentsRequired: "",
    formsRequired: [],
    newIncService: false,
    serviceType: "All",
  });

  const currentUser = useSelector((state) => state.employee).state;
  useEffect(() => {
    if (!currentUser.isAdmin) {
      navigate("/employee");
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServiceData({ ...serviceData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // console.log(serviceData.documentsRequired.includes(","))
    if (serviceData.documentsRequired.includes(","))
      serviceData.documentsRequired =
        serviceData.documentsRequired.split(/,\s|,/);

    const response = await createService(serviceData);
    if (response.status === 200) {
      toast({
        title: "Service Created",
        description: "Service has been created successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/employee/admin/services");
    } else {
      toast({
        title: "OOPS! Something went wrong!",
        description: "Please try again later. Check if the server is up.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <EmpBar />
      {window.history.length >= 1 && (
        <Button
          bg="black"
          color="white"
          leftIcon={<ArrowBackIcon />}
          _hover={{ bg: "black.500", color: "yellow" }}
          _active={{ bg: "gray.200", color: "black" }}
          variant="solid"
          ml="50px"
          onClick={() => {
            window.history.back();
          }}
        >
          Go Back
        </Button>
      )}
      <Box margin="50px">
        <Heading>Create a new Service</Heading>
        <Divider h="5px" />
        <form onSubmit={handleFormSubmit}>
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Td width="15vw" fontWeight="bold">
                  Name
                </Td>
                <Td>
                  <FormControl>
                    <Input
                      type="text"
                      name="name"
                      value={serviceData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Description</Td>
                <Td>
                  <FormControl>
                    <Textarea
                      name="description"
                      value={serviceData.description}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Cost (Range)</Td>
                <Td>
                  <FormControl>
                    <Input
                      type="number"
                      name="cost"
                      value={serviceData.cost}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Documents Required (comma-separated)</Td>
                <Td>
                  <FormControl>
                    <Input
                      type="text"
                      name="documentsRequired"
                      value={serviceData.documentsRequired}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Service for New Incorporation</Td>
                <Td>
                  <FormControl>
                    <Switch
                      name="new"
                      isChecked={serviceData.newIncService}
                      onChange={() => {
                        setServiceData((prevData) => ({
                          ...prevData,
                          newIncService: !prevData.newIncService,
                        }));
                      }}
                      size="lg"
                      borderColor="black"
                      variant="redThumb"
                      colorScheme="brand"
                    />
                  </FormControl>
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Service Type</Td>
                <Td>
                  <Tabs variant="soft-rounded" colorScheme="yellow">
                    <TabList>
                      <Tab
                        onClick={() => {
                          setServiceData({
                            ...serviceData,
                            serviceType: "All",
                          });
                        }}
                        rounded="full"
                        // isSelected={serviceData.serviceType === "All"}
                      >
                        All
                      </Tab>
                      <Tab
                        onClick={() => {
                          setServiceData({
                            ...serviceData,
                            serviceType: "Corporate",
                          });
                        }}
                        rounded="full"
                        // isSelected={serviceData.serviceType === "Corporate"}/
                      >
                        Corporate
                      </Tab>
                      <Tab
                        onClick={() => {
                          setServiceData({
                            ...serviceData,
                            serviceType: "TradeName",
                          });
                        }}
                        rounded="full"
                        // isSelected={serviceData.serviceType === "TradeName"}
                      >
                        TradeName
                      </Tab>
                      <Tab
                        onClick={() => {
                          setServiceData({
                            ...serviceData,
                            serviceType: "Partnership",
                          });
                        }}
                        rounded="full"
                        // isSelected={serviceData.serviceType === "Partnership"}
                      >
                        Partnership
                      </Tab>
                      <Tab
                        onClick={() => {
                          setServiceData({
                            ...serviceData,
                            serviceType: "New",
                          });
                        }}
                        rounded="full"
                        // isSelected={serviceData.serviceType === "New"}
                      >
                        New/First Time
                      </Tab>
                    </TabList>
                  </Tabs>
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Divider h="200px" />

          {serviceData && serviceData.formsRequired && (
            <ServiceForms
              modify={true}
              service={serviceData}
              setService={setServiceData}
            />
          )}

          <Button type="submit" mt={4} colorScheme="teal">
            Create Service
          </Button>
        </form>
      </Box>
    </>
  );
};

export default CreateService;
