import React from 'react';
import { Box, Button, Center, Heading, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';

const ThankYou = () => {
    const navigate = useNavigate()
    return (
        <>
        <NavBar />

            <Box textAlign="center" mt="35px">
                <Heading as="h1" size="xl" mb={4}>
                    Thank You!
                </Heading>
                <Text fontSize="xl" mb={6}>
                    We will get back to you within the next 2 - 12 business hours (Mountain Time).
                </Text>
                <Text fontSize="xl" mb={6}>
                    Thanks for your submission!
                </Text>
                <Center>
                    {/* You can replace the imageSrc with your own image URL */}
                    <img src="/images/thankYou.png" alt="Thank You" />
                </Center>
                <Button colorScheme="yellow" size="lg" mt={8} onClick={() => navigate("/")}>
                    Go back to home page
                </Button>
            </Box>
        </>
    );
};

export default ThankYou;
