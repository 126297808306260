import React from "react";
import {
  Flex,
  Heading,
  VStack,
  Button,
  Divider,
  List,
  ListItem,
  ListIcon,
  Text,
  Link,
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../utils/NavBar.js";
import Disclaimer from "../../utils/Disclaimer.js";
import ModalDisclaimer from "../../utils/ModalDisclaimer.js";

const Numbered = () => {
  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing={4}>          
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/main/business-type");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading as="h1">Register a Trade Name</Heading>

          <Heading as="h2">Things to know before you register a Trade Name:</Heading>

          <VStack spacing={4}>
            <List>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                You will be permitted to conduct business under that name.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Trade names are not unique to you / your business.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Trade names are not protected, meaning anybody can use the name you have chosen.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                The owner will be liable for all obligations and liabilities of the business.
              </ListItem>
            </List>
          </VStack>
          <Button
            colorScheme="green"
            width={{base:"100%", md:"auto"}}
            onClick={() => {
              navigate("/guest/new/tradename");
            }}
          >
            Continue to register Trade Name
          </Button>

          <Divider height="2vh" />

          <Heading as="h2">Recommendations / Insights</Heading>

          <Heading as="h3" color="blue.500">
            Benefits of choosing a Corporation over a Trade Name
          </Heading>
          <List>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="blue.500" />
              Have flexibility that a cooperation allows for its shareholders including: share structure, tax planning, goodwill and relative ease of selling.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="blue.500" />
              Limit your personal liability.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="blue.500" />
              Oversight by directors.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="blue.500" />
              Shareholder structuring.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="blue.500" />
              Have your unique name secured.
            </ListItem>
          </List>

          <Flex width="100%" justifyContent="center">
            <VStack>
              <Button
                colorScheme="blue"
                bgColor="blue.500"
                onClick={() => {
                  navigate("/main/corp-type");
                }}
                fontSize="3xl"
                p="9"
                mt="24px"
                mb="24px"
              >
                Switch to Corporation
              </Button>

              <Heading as="h5" fontSize="140%" mb="24px">
                OR
              </Heading>

              <Text as="h5" fontSize="140%" mb="24px">
                If you are a <b>professional</b>, you may need to have a &nbsp;
                <Link
                  isExternal
                  href="https://www.lawsociety.ab.ca/lawyers-and-students/membership-services/professional-corporations/"
                  style={{ color: "#03a9f4", textDecoration: "underline" }}
                >
                  Professional Corporation
                </Link>
                .
              </Text>
            </VStack>
          </Flex>
        </VStack>
      </Flex>

      <Disclaimer />

      <ModalDisclaimer />
    </>
  );
};

export default Numbered;
