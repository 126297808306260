import React from "react";

import {
  Heading,
  Input,
  FormLabel,
  Grid,
  GridItem,
  FormControl,
  Select
} from "@chakra-ui/react";

import MaintenanceReminder from "../../../../utils/MaintenanceReminder";

const AccountInformation = ({ request, setRequest }) => {
  const handleChangeAccountInfo = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      accountInfo: {
        ...prevRequest.accountInfo,
        [field]: value,
      },
    }));
  };

  const handleAnnualMgmtChange = (value, priceType) => {
    let bool = value ? true : false;
    setRequest((prevReq) => ({ ...prevReq, annualMgmt: bool }));
    request.togglePrice(value, priceType);
  };

  return (
    <>
      <Heading mb="15px">Personal Information</Heading>

      <FormControl>
        <Grid
          templateColumns={{ base: '1fr', md: 'auto 1fr' }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <FormLabel pr="20">
              My first name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.firstName}
              onChange={(e) => handleChangeAccountInfo("firstName", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My last name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.lastName}
              onChange={(e) => handleChangeAccountInfo("lastName", e.target.value)}
            />
          </GridItem>

          {request.accountInfo.title != undefined && (
          <>
            <GridItem>
              <FormLabel pr="20">
                My title is
              </FormLabel>
            </GridItem>
            <GridItem>
              <Input
                w={{ base: "100%", md: "30ch" }}
                value={request.accountInfo.title}
                onChange={(e) => handleChangeAccountInfo("title", e.target.value)}
              />
            </GridItem>
          </>
          )}

          <GridItem>
            <FormLabel pr="20">
              My phone number is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.phone}
              onChange={(e) => handleChangeAccountInfo("phone", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My email is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.email}
              onChange={(e) => handleChangeAccountInfo("email", e.target.value)}
            />
          </GridItem>

          {request.accountInfo.relation != undefined && (
          <>
            <GridItem>
              <FormLabel pr="20">
                My relation to the corporation is
              </FormLabel>
            </GridItem>
            <GridItem>
              <Select
                w={{ base: "100%", md: "30ch" }}
                value={request.accountInfo.relation}
                onChange={(e) => handleChangeAccountInfo("relation", e.target.value)}
              >
                <option key={0} value={"agent for service"}>agent for service</option>
                <option key={1} value={"director"}>director</option>
                <option key={2} value={"shareholder"}>shareholder</option>
                <option key={3} value={"solicitor"}>solicitor</option>
              </Select>
            </GridItem>
          </>
          )}
        </Grid>
      </FormControl>

      <br />
      
      <MaintenanceReminder
        setValue={(value) =>
          handleAnnualMgmtChange(value, "SubscribeAnnualMaintenance")
        }
        enabled={request.prices.SubscribeAnnualMaintenance.enabled}
      />
    </>
  );
};

export default AccountInformation;
