import axios from "axios";

const uri = `${process.env.REACT_APP_BACKEND_KEY}/requests`;

export const createRequest = async (request) => {
  
  try {
    const response = await axios.post(`${uri}`, { request: request });
    console.log(response);
    return response;
  } catch (error) {
    return error.response;
  }
};
