import React from "react";
import {
  Flex,
  Heading,
  VStack,
  Button,
  Divider,
  Text,
  Center
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";
import ModalDisclaimer from "../../../utils/ModalDisclaimer.js";

const NonProfitSocieties = ({ request, setRequest }) => {
  const navigate = useNavigate();

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing={4}>
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/main");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading as="h1" mb="24px">
            Non-Profit / Societies
          </Heading>

          <Center width="100%">
            <VStack>
              <Heading as="h2" textAlign="center">
                More information coming soon!
              </Heading>
              <Heading as="h3" textAlign="center">
                Please contact us for help setting this up.
              </Heading>
              <Text textAlign={{base: "center", lg: "left"}} fontWeight="bold">
                {process.env.REACT_APP_EMAIL}
              </Text>
             </VStack>
          </Center>
          
        </VStack>
      </Flex>

      <Disclaimer />

      <ModalDisclaimer />
    </>
  );
};

export default NonProfitSocieties;
