import React from "react";
import NavBar from "../../../utils/NavBar.js";
import CompanyComponent from "../register/CompanyComponent.js";
import Disclaimer from "../../../utils/Disclaimer.js";

const TradeName = () => {
  return (
    <>
      <NavBar />
      <CompanyComponent bType={"soleProprietorship"} />
      <Disclaimer />
    </>
  );
};

export default TradeName;
