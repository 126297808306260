import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  useDisclosure,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const InfoButton = ({ info }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger className="popover-trigger">
        <InfoOutlineIcon className="popover-trigger-icon" cursor="pointer" mb="14px"/>
      </PopoverTrigger>
      <PopoverContent className="popover-content">
        <PopoverArrow className="popover-arrow" />
        <PopoverCloseButton />
        <PopoverBody>{info}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default InfoButton;
