import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  useToast,
  HStack,
  Heading,
  Divider,
  Box,
  VStack,
} from "@chakra-ui/react";
import {
  updateForm,
  deleteForm,
  createForm,
  getAllForms,
} from "../../../services/admin/forms.js";
import { ArrowBackIcon } from "@chakra-ui/icons";
import EmpBar from "../../EmpBar.js";
import { FaLink } from "react-icons/fa";

const FormList = () => {
  const [forms, setForms] = useState([]);
  const [editingForm, setEditingForm] = useState({});
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const [selectedForm, setSelectedForm] = useState(null);
  const [newForm, setNewForm] = useState({
    name: "",
    formLink: "",
    guideLink: "",
  });
  const toast = useToast();

  const loadForms = async () => {
    try {
      const response = await getAllForms();
      if (response.status === 200) {
        setForms(response.data.forms);
      } else {
        // Handle error using toast
        toast({
          title: "Error",
          description: "Failed to load forms.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Handle error using toast
      console.log(error);
      toast({
        title: "Error Try again",
        description: "Failed to load forms. Maybe Server error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditForm = (form) => {
    setEditingForm(form);
  };

  const handleUpdateForm = async () => {
    try {
      const response = await updateForm(editingForm);
      if (response.status === 200) {
        toast({
          title: "Form Updated",
          description: "Form has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (response.status === 409) {
        toast({
          title: "Form Already Exists",
          description: "Form with same name already exists.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to update form.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update form.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      loadForms();
      setEditingForm({});
    }
  };

  const handleDeleteConfirmation = (form) => {
    setSelectedForm(form);
    onDeleteOpen();
  };

  const handleDeleteForm = async () => {
    try {
      const response = await deleteForm(selectedForm._id);
      if (response.status === 200) {
        toast({
          title: "Form Deleted",
          description: "Form has been deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to delete form. Check server",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete form.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      loadForms();
      onDeleteClose();
      setSelectedForm(null);
    }
  };

  const handleAddForm = async () => {
    try {
      const response = await createForm(newForm);
      if (response.status === 200) {
        toast({
          title: "Form Added",
          description: "New form has been created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (response.status === 409) {
        toast({
          title: "Form Already Exists",
          description: "Form with same name already exists.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to create new form.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Check if server is open.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      loadForms();
      onAddClose();
      setNewForm("");
    }
  };

  useEffect(() => {
    loadForms();
  }, []);

  return (
    <>
      <EmpBar />
      {window.history.length >= 1 && (
        <Button
          bg="black"
          color="white"
          leftIcon={<ArrowBackIcon />}
          _hover={{ bg: "black.500", color: "yellow" }}
          _active={{ bg: "gray.200", color: "black" }}
          variant="solid"
          ml="50px"
          onClick={() => {
            window.history.back();
          }}
        >
          Go Back
        </Button>
      )}
      <Box m="50px">
        <HStack justify={"space-between"}>
          <Heading>Manage Forms</Heading>
          <Button
            size="lg"
            colorScheme="teal"
            variant="solid"
            onClick={() => {
              onAddOpen();
            }}
          >
            Add New Form
          </Button>
        </HStack>
        <Divider h="5vh" />
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th w="5%">Serial Number</Th>
              <Th w="45%">Form</Th>
              <Th w="10%">Form Link</Th>
              <Th w="10%">Guide Link</Th>
              <Th w="30%">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {forms.map((form, index) => (
              <Tr key={form._id}>
                <Td>{index + 1}</Td>
                <Td>
                  {editingForm._id === form._id ? (
                    <VStack align="start">
                      <HStack>
                        <Text w="200px" fontWeight="bold">
                          Name
                        </Text>
                        <Input
                          value={editingForm.name || ""}
                          autoFocus
                          onChange={(e) =>
                            setEditingForm({
                              ...editingForm,
                              name: e.target.value,
                            })
                          }
                        />
                      </HStack>
                      <HStack>
                        <Text w="200px" fontWeight="bold">
                          Form Link
                        </Text>
                        <Input
                          value={editingForm.formLink || ""}
                          placeholder="Enter form link"
                          onChange={(e) =>
                            setEditingForm({
                              ...editingForm,
                              formLink: e.target.value,
                            })
                          }
                        />
                      </HStack>
                      <HStack>
                        <Text w="200px" fontWeight="bold">
                          Guide Link
                        </Text>
                        <Input
                          value={editingForm.guideLink || ""}
                          placeholder="Enter guide link"
                          onChange={(e) =>
                            setEditingForm({
                              ...editingForm,
                              guideLink: e.target.value,
                            })
                          }
                        />
                      </HStack>
                    </VStack>
                  ) : (
                    <Text fontWeight={"bold"} fontSize={"xl"}>{form.name}</Text>
                  )}
                </Td>
                <Td>
                  {editingForm._id === form._id
                    ? "Fill the form"
                    : form.formLink && (
                      <Button
                      fontSize={"90%"}
                      p="4"
                      m="1"
                      colorScheme="black"
                      variant={"outline"}
                      aria-label="View Guide"
                      rightIcon={<FaLink />}
                      onClick={() => window.open(form.formLink)}
                      size="xs"
                    >
                      View
                    </Button>
                    
                      )}
                </Td>
                <Td>
                  {editingForm._id === form._id
                    ? "Fill the form"
                    : form.guideLink && (
                        <Button
                          fontSize={"90%"}
                          p="4"
                          m="1"
                          aria-label="View Guide"
                          colorScheme="black"
                          variant={"outline"}
                          rightIcon={<FaLink />}
                          onClick={() => window.open(form.guideLink, "_blank")}
                          size="xs"
                        >
                          View
                        </Button>
                      )}
                </Td>

                <Td>
                  <HStack>
                    {editingForm._id === form._id ? (
                      <HStack>
                        <Button
                          colorScheme="green"
                          onClick={handleUpdateForm}
                          w="45%"
                          mr="10%"
                        >
                          Update
                        </Button>
                        <Button
                          colorScheme="yellow"
                          onClick={() => setEditingForm({})}
                          w="45%"
                        >
                          Cancel
                        </Button>
                      </HStack>
                    ) : (
                      <Button
                        w="100%"
                        colorScheme="yellow"
                        onClick={() => handleEditForm(form)}
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      w="100%"
                      colorScheme="red"
                      onClick={() => handleDeleteConfirmation(form)}
                    >
                      Delete
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Add Form modal */}
      {/* Add Form modal */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Form</ModalHeader>
          <ModalBody>
            <Text>Name</Text>
            <Input
              placeholder="Enter form name"
              mb={5}
              value={newForm.name}
              onChange={(e) => setNewForm({ ...newForm, name: e.target.value })}
            />
            <Text>Form Link</Text>
            <Input
              mb={5}
              placeholder="Enter form link"
              value={newForm.formLink}
              onChange={(e) =>
                setNewForm({ ...newForm, formLink: e.target.value })
              }
            />
            <Text>Guide Link</Text>
            <Input
              mb={5}
              placeholder="Enter guide link"
              value={newForm.guideLink}
              onChange={(e) =>
                setNewForm({ ...newForm, guideLink: e.target.value })
              }
            />
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button variant="ghost" color="black" onClick={onAddClose}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={handleAddForm}>
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Form</ModalHeader>
          <ModalBody>
            Do you really want to delete "
            {selectedForm && <b>{selectedForm.name}</b>}" form?
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button variant="ghost" color="black" onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteForm}>
                Yes, Delete
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FormList;
