import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Text,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  useToast,
  HStack,
  Heading,
  Divider,
  Box,
  Icon,
  Switch,
} from "@chakra-ui/react";
import {
  updateUser,
  deactivateUser,
  createUser,
  getAllUsers,
} from "../../../services/admin/users.js";
import { ArrowBackIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState({});
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({
    name: "",
    username: "",
    isAdmin: false,
  });
  const toast = useToast();

  const loadUsers = async () => {
    try {
      const response = await getAllUsers();
      if (response.status === 200) {
        setUsers(response.data.users);
      } else {
        // Handle error using toast
        toast({
          title: "Error",
          description: "Failed to load users.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Handle error using toast
      console.log(error);
      toast({
        title: "Error Try again",
        description: "Failed to load users. Maybe Server error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
  };

  const handleUpdateUser = async () => {
    try {
      const response = await updateUser(editingUser);
      if (response.status === 200) {
        toast({
          title: "User Updated",
          description: "User has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (response.status === 409) {
        toast({
          title: "User Already Exists",
          description: "User with same name already exists.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to update user.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      loadUsers();
      setEditingUser({});
    }
  };

  const handleDeleteConfirmation = (user) => {
    setSelectedUser(user);
    onDeleteOpen();
  };

  const handleDeleteUser = async () => {
    try {
      onDeleteClose();
      const response = await deactivateUser(selectedUser._id);
      if (response.status === 200) {
        toast({
          title: "User Deleted",
          description: "User has been deactivated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to deactivate user. Check server",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to deactivate user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      loadUsers();
      setSelectedUser(null);
    }
  };

  const handleResetPassword = async (user) => {
    try {
      if (user) user.password = "Password";
      const response = await updateUser(user);
      if (response.status === 200) {
        toast({
          title: "Password Reset",
          description: "User's password reset successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: response.data.msg,
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Failed to update user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      loadUsers();
    }
  };

  const handleAddUser = async () => {
    try {
      newUser.password = "Password";
      newUser.isActive = true;
      const response = await createUser(newUser);
      if (response.status === 200) {
        toast({
          title: "User Added",
          description: "New user has been created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (response.status === 409) {
        toast({
          title: "User Already Exists",
          description: "User with same name already exists.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to create new user.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Check if server is open.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      loadUsers();
      onAddClose();
      setNewUser("");
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <>
      {/* <EmpBar /> */}
      {window.history.length >= 1 && (
        <Button
          bg="black"
          color="white"
          leftIcon={<ArrowBackIcon />}
          _hover={{ bg: "black.500", color: "yellow" }}
          _active={{ bg: "gray.200", color: "black" }}
          variant="solid"
          ml="50px"
          onClick={() => {
            window.history.back();
          }}
        >
          Go Back
        </Button>
      )}
      <Box m="50px">
        <HStack justify={"space-between"}>
          <Heading>Manage Users</Heading>
          <Button
            size="lg"
            colorScheme="teal"
            variant="solid"
            onClick={() => {
              onAddOpen();
            }}
          >
            Add New User
          </Button>
        </HStack>
        <Divider h="5vh" />
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th w="5%">Serial Number</Th>
              <Th w="35%">Name</Th>
              <Th w="20%">User Name</Th>
              <Th w="5%">Admin</Th>
              <Th w="5%">Deactivated</Th>
              <Th w="25%">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user, index) => (
              <Tr key={user._id}>
                <Td>{index + 1}</Td>
                <Td>
                  {editingUser._id === user._id ? (
                    <Input
                      value={editingUser.name || ""}
                      autoFocus
                      onChange={(e) =>
                        setEditingUser({
                          ...editingUser,
                          name: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <Text fontWeight={"bold"} fontSize={"xl"}>
                      {user.name}
                    </Text>
                  )}
                </Td>
                <Td>
                  {editingUser._id === user._id ? (
                    <Input
                      value={editingUser.username || ""}
                      autoFocus
                      onChange={(e) =>
                        setEditingUser({
                          ...editingUser,
                          username: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <Text>{user.username}</Text>
                  )}
                </Td>
                <Td>
                  {editingUser._id === user._id ? (
                    <Switch
                      id="isAdmin"
                      isChecked={editingUser.isAdmin}
                      value={editingUser.isAdmin || false}
                      autoFocus
                      size="lg"
                      border="2px solid teal"
                      borderRadius={25}
                      onChange={(e) =>
                        setEditingUser({
                          ...editingUser,
                          isAdmin: !editingUser.isAdmin,
                        })
                      }
                    />
                  ) : (
                    user.isAdmin && (
                      <>
                        <Icon as={CheckIcon} />
                      </>
                    )
                  )}
                </Td>

                <Td>
                  {editingUser._id === user._id ? (
                    <Switch
                      id="isDeActive"
                      border="2px solid red"
                      borderRadius={25}
                      isChecked={!editingUser.isActive}
                      value={!editingUser.isActive || true}
                      autoFocus
                      colorScheme="red"
                      size="lg"
                      onChange={(e) =>
                        setEditingUser({
                          ...editingUser,
                          isActive: !editingUser.isActive,
                        })
                      }
                    />
                  ) : (
                    !user.isActive && (
                      <>
                        <Icon as={CloseIcon} />
                      </>
                    )
                  )}
                </Td>

                <Td>
                  <HStack>
                    {editingUser._id === user._id ? (
                      <HStack>
                        <Button
                          colorScheme="green"
                          onClick={handleUpdateUser}
                          w="45%"
                          mr="10%"
                        >
                          Update
                        </Button>
                        <Button
                          colorScheme="yellow"
                          onClick={() => setEditingUser({})}
                          w="45%"
                        >
                          Cancel
                        </Button>
                      </HStack>
                    ) : (
                      <Button
                        w="100%"
                        colorScheme="yellow"
                        onClick={() => handleEditUser(user)}
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      w="100%"
                      colorScheme="orange"
                      variant="solid"
                      onClick={() => {
                        handleResetPassword(user);
                      }}
                    >
                      Reset p
                    </Button>
                    <Button
                      w="100%"
                      colorScheme="red"
                      onClick={() => handleDeleteConfirmation(user)}
                    >
                      Deactivate
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Add User modal */}
      {/* Add User modal */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New User</ModalHeader>
          <ModalBody>
            <Text>Name</Text>
            <Input
              placeholder="Enter user name"
              mb={5}
              value={newUser.name}
              onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
            />
            <Text>User Name</Text>
            <Input
              mb={5}
              placeholder="Enter user name"
              value={newUser.username}
              onChange={(e) =>
                setNewUser({ ...newUser, username: e.target.value })
              }
            />
            <HStack>
              <Text>Admin ?</Text>
              <Switch
                id="isAdmin"
                isChecked={newUser.isAdmin}
                value={newUser.isAdmin || false}
                autoFocus
                size="lg"
                border="2px solid teal"
                borderRadius={25}
                onChange={(e) =>
                  setNewUser({ ...newUser, isAdmin: !newUser.isAdmin })
                }
              />
            </HStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button variant="ghost" color="black" onClick={onAddClose}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={handleAddUser}>
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete User</ModalHeader>
          <ModalBody>
            Do you really want to deactivate "
            {selectedUser && <b>{selectedUser.name}</b>}" user?
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button variant="ghost" color="black" onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteUser}>
                Yes, Delete
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UsersList;
