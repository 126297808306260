import React from "react";
import { Center, Link } from "@chakra-ui/react";

const BannerLine = ({ line, link, linkText, isExternal }) => {
  return (
    <Center
      display="inline-block"
      fontSize="xl"
      p="4"
      w="100%"
      bgColor={"#000001"}
      color={"white"}
      mb="15"
    >
      {line} {}
      <Link
        textAlign={"center"}
        color="#03a9f4" // Start with blue color
        transition="color 0.5s ease-out"
        _hover={{ textDecoration: "none" }}
        // Assuming 'link' is a URL and you want to open it in a new tab
        href={link}
        isExternal={isExternal}
      >
        {linkText} !
      </Link>
    </Center>
  );
};

export default BannerLine;
