import React from "react";

import {
  FormControl,
  Heading,
  Text,
  Input,
  FormLabel,Grid, GridItem
} from "@chakra-ui/react";
import Province from "../../../../utils/Province";

const IncServInformation = ({ request, setRequest }) => {
  const currentMonthIndex = new Date().getMonth();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = months[currentMonthIndex];
  const handleChangeBusinessInfo = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      businessInfo: {
        ...prevRequest.businessInfo,
        [field]: value,
      },
    }));
  };

  // const handleSuffixChange = (preference, suffix) => {
  //   const currentValue = request.businessInfo[preference] || "";
  //   handleChangeBusinessInfo(preference, `${currentValue} ${suffix}`);
  // };

  return (
    <>
      <Heading mb="15px">Business Information</Heading>

      <FormControl>
        <Grid
          templateColumns={{ base: '1fr', md: 'auto 1fr' }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <FormLabel>
              My company's business name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.businessName}
              onChange={(e) => handleChangeBusinessInfo("businessName", e.target.value)}
            />
          </GridItem>
  
          <GridItem>
            <FormLabel>
              My company's Corporate Access Number is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.corporateAccessNumber}
              onChange={(e) => handleChangeBusinessInfo("corporateAccessNumber", e.target.value)}
            />
          </GridItem>
        </Grid>
      </FormControl>

      <br />

      <Text fontWeight="bold">The new street address of registered office will be:</Text>

      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
        gap={4}
      >
        <GridItem>
          <FormLabel>Address Line 1</FormLabel>
          <Input
            value={request.businessInfo.streetLine1}
            onChange={(e) => handleChangeBusinessInfo("streetLine1", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Address Line 2</FormLabel>
          <Input
            value={request.businessInfo.streetLine2}
            onChange={(e) => handleChangeBusinessInfo("streetLine2", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>City</FormLabel>
          <Input
            value={request.businessInfo.city}
            onChange={(e) => handleChangeBusinessInfo("city", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Province</FormLabel>
          <Province
            value={request.businessInfo.province}
            onChange={(e) =>
              handleChangeBusinessInfo("province", e.target.value)
            }
          />
        </GridItem>
        <GridItem>
          <FormLabel>Country</FormLabel>
          <Input            
            value={request.businessInfo.country}
            onChange={(e) => handleChangeBusinessInfo("country", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Postal Code</FormLabel>
          <Input
            value={request.businessInfo.postalCode}
            onChange={(e) => handleChangeBusinessInfo("postalCode", e.target.value)}
          />
        </GridItem>
      </Grid>
    </>
  );
};
export default IncServInformation;
