import React from "react";
import {
  HStack,
  Heading,
  VStack,
  Button,
  Divider,
  Text,
  Flex
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";

const DecideOwners = ({ setSingleOwner, mainPage }) => {
  const navigate = useNavigate();

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
       <VStack align="start" className="container" spacing="4">
          {mainPage && (
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate("/main");
              }}
            >
              Back
            </Button>
          )}

          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/main/business-type");
            }}
          >
            Back
          </Button>
          
          <Divider className="divider" />

          <Heading>Are there multiple owners?</Heading>
          <Text fontSize="150%" mb="8">
            Are you the only owner of the business or do you have (or are
            planning to) have more than one owner for your business in the future?
          </Text>
          <Text fontSize="120%">If there are multiple owners, please select <b>Yes</b></Text>
          <Text fontSize="120%" mb="24px">If you are the only owner, please select <b>No</b></Text>

          <HStack spacing={4}>
            <Button className="btn-black" onClick={() => { navigate('/decide/business-name-protection');}}>
              Yes
            </Button>
            <Button className="btn-black" onClick={() => { navigate('/decide/business-name-protection');}}>
              No
            </Button>
          </HStack>    
        </VStack>
      </Flex>

      <Disclaimer />
    </>
  );
};

export default DecideOwners;
