import {
  Box,  
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  VStack,
  Heading,
  ListItem,
  OrderedList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import NavBar from "../../../utils/NavBar";
import { ArrowBackIcon, ArrowForwardIcon, SpinnerIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import Disclaimer from "../../../utils/Disclaimer";

const Results = ({ liability, singleOnwer, bNameProtection }) => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (liability === "" || singleOnwer === "" || bNameProtection === "")
  //     navigate("/decide");

  //   console.log();
  // }, []);

  return (
    <>
      <NavBar pageTitle="Results" />
      {/* <Flex align="center" justify="space-around" maxWidth="500px">
        <Heading>Disclamer</Heading>

        <Text border="2px ">
          The result below is based on the selection, Snappy Corporation do not
          take any responsibility or give you any advice for which structure you
          should choose, as we are not a law firm. Thanks for understanding
        </Text>
      </Flex>
      <Divider height="5vh" /> */}

      {/* Buttons */}
      <Flex justifyContent="center" mt="50px">        
        <VStack align="start" className="container" spacing="4">
          <HStack spacing="4">
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate("/main/business-type");
              }}
            >
              Back
            </Button>
            <Button
              bgColor="gray"
              color="white"
              leftIcon={<SpinnerIcon />}
              onClick={() => {
                navigate("/decide");
              }}
            >
              Start Over
            </Button>
          </HStack>

          {/* Result */}
          <Divider className="divider" />

          <Center>
            <Heading as="h2">
              Best Suitable Structure:
            </Heading>
          </Center>
          <Center>
            <Heading as="h2" color="green.500" fontStyle="italic">
              {!liability && singleOnwer && !bNameProtection 
                ? "Both works"
                : "Incorporation"}
            </Heading>
          </Center>

          <Center>
            <Box align="justify-content">
              <Heading as="h3" color="blue.500">
                Explanation:
              </Heading>

              {!(
                !liability &&
                singleOnwer &&
                !bNameProtection 
              ) ? (
                <OrderedList color="blue.500">
                  {liability && (
                    <ListItem>
                      Since you want to have personal liability protection, you should not proceed with a sole proprietorship.
                    </ListItem>
                  )}
                  {bNameProtection && (
                    <ListItem>
                      Since you want to protect your unique business name, you should not proceed with a sole proprietorship.
                    </ListItem>
                  )}
                  {!singleOnwer && (
                    <ListItem >
                      Since there are multiple owners, you cannot have a sole proprietorship.
                    </ListItem>
                  )}
                </OrderedList>
              ) : (
                <>
                  <Text>
                    Based on your answers, either a sole properitorship or a corporation would be suitable for your business needs.
                  </Text>
                  <Divider height="2vh" />
                  <Text>
                    If you are unsure on how to proceed, please reach out to Snappy --- and we will assist you in obtaining legal and/or tax advice.
                  </Text>
                  <Button colorScheme="gray" variant="outline">
                    Contact us
                  </Button>
                </>
              )}
            </Box>
          </Center>

          <Center>
            <Button
              className="btn-black"
              size="lg"
              onClick={() => {
                navigate("/main/business-type");
              }}
            >
              Continue <ArrowForwardIcon />
            </Button>
          </Center>
        </VStack>
      </Flex>

      <Disclaimer />
    </>
  );
};

export default Results;
