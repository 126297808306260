import React from "react";
import CompanyComponent from "../register/CompanyComponent.js";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";

const Partnership = () => {

  return (
    <>
      <NavBar />
      <CompanyComponent bType={"partnership"} />
      <Disclaimer />
    </>
  );
};

export default Partnership;
