import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  IconButton,
  HStack,
  ListItem,
  List,
  Button,
  FormLabel,
  Select,
  Input,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const Delete = ({ request, setRequest }) => {
  const [list, setList] = useState(request.partners.deletedPartners.length >= 1 ? request.partners.deletedPartnersList : request.partners.currentPartners);
  const [selectedPartner, setSelectedPartner] = useState("Select");

  useEffect(() => {
    let data = [];

    if (request.partners.deletedPartners.length >= 1){
      data = request.partners.deletedPartnersList;
    } else {
      data = request.partners.currentPartners;
    }

    setList(data);
  }, [request.partners.deletedPartners, request.partners.currentPartners]);

  const handleRemovePartner = () => {
    let deletedPartner = null;

    if (selectedPartner && selectedPartner !== "Select") {

      if (/\d/.test(selectedPartner)) {
        const [corpName, corpAccessNumber] = selectedPartner.split(", ");
        deletedPartner = request.partners.currentPartners.find(
          (partner) =>
            partner.corpName === corpName && partner.corpAccessNumber === corpAccessNumber
        );
      } else {
        const [lastName, firstName] = selectedPartner.split(", ");
        deletedPartner = request.partners.currentPartners.find(
          (partner) =>
            partner.firstName === firstName && partner.lastName === lastName
        );
      }

      if (deletedPartner) {
        let updatedDeletedPartners = [
          ...request.partners.deletedPartners,
          {...deletedPartner, revokeDate:new Date().toISOString().split("T")[0] }
        ];

        let updatedList = list.filter(partner => partner.key !== deletedPartner.key);

        setRequest((prevRequest) => ({
          ...prevRequest,
          partners: {
            ...prevRequest.partners,
            deletedPartners: updatedDeletedPartners,
            deletedPartnersList: updatedList,
            results: [
              ...prevRequest.partners.results
            ].filter(
              (partner) =>
                !updatedDeletedPartners.some(
                  deleted => deleted.key === partner.key
                )
            )
          },
        }));

        setList(updatedList);
      }
      setSelectedPartner(Select);
    }
  };

  const handleRestorePartner = (key) => {
    const restoredPartner = request.partners.deletedPartners.find(partner => partner.key === key);

    setRequest((prevRequest) => ({
      ...prevRequest,
      partners: {
        ...prevRequest.partners,
        deletedPartners: prevRequest.partners.deletedPartners.filter(
          partner => partner.key !== key
        ),
        results: [
          ...prevRequest.partners.results,
          restoredPartner
        ]
      },
    }));

    setList((prevList) => [
      ...prevList,
      restoredPartner
    ]);

    setSelectedPartner(Select);
  };

  const handleRevokeDateChange = (key, value) => {
    setRequest((prevRequest) => {
     const updatedDeletedPartners = prevRequest.partners.deletedPartners.map((partner) => {
         if (partner.key == key){
           partner.revokeDate = value;
         }

         return partner;
       }
     );

     return {
       ...prevRequest,
       partners: {
         ...prevRequest.partners,
         deletedPartners: updatedDeletedPartners
       },
     };
   });
 };

  return (
    <>
      <Heading as="h4" color="red.800">
        Removed Partners
      </Heading>

      <Grid
        templateColumns={{ base: "1fr auto", md: "auto auto 1fr" }}
        alignItems="center"
        width="100%"
        gap="2"
      >
        <GridItem colSpan={{base:3, md:1}}>
          <FormLabel>
            Partner to be removed:
          </FormLabel>
        </GridItem>
        <GridItem>
          <Select
            w={{ base: "100%", md: "30ch" }}
            value={selectedPartner}
            defaultValue="Select"
            onChange={(e) => setSelectedPartner(e.target.value)}
          >
            <option key="0">Select</option>
            {list.map((partner, index) => (
              <option key={partner.key}>{`${partner.lastName}${partner.corpName}, ${partner.firstName}${partner.corpAccessNumber}`}</option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <Button
            colorScheme="red"
            p="2"
            rightIcon={<CloseIcon />}
            onClick={handleRemovePartner}
            disabled={!selectedPartner}
            aria-label="Remove Partner"
          >
            Remove
          </Button>
        </GridItem>
      </Grid>

      <FormLabel mt="12px !important">
        Partners that are being <i>deleted</i> are as follows:
      </FormLabel>

      <Box my={4}>
        <List
          borderBottom="solid 1px gray"
          ml={{base:"0", md:"10%"}}
          w={{base:"100%", md:"60%"}}
          color="red.500"
        >
          {request.partners.deletedPartners &&
            request.partners.deletedPartners.map((partner, index) => (
              <ListItem key={partner.key}>
                <HStack justify="space-between">
                  <Text width="60%" fontWeight="bold" mb="0 !important">
                    {partner.firstName} {partner.lastName}
                    {partner.corpName} {partner.corpAccessNumber}
                  </Text>
                  <Input
                    type="date"
                    width="150px"
                    value={partner.revokeDate}
                    onChange={(e) => handleRevokeDateChange(partner.key, e.target.value)}
                  />
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => handleRestorePartner(partner.key)}
                    aria-label="Restore Partner"
                  />
                </HStack>
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
};

export default Delete;
