// buyNowReducer.js
const initialState = {
  buyNow: [],
};
const buyNowReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_BUY_NOW":
      // handle adding to the buyNow
      return {
        ...state,
        buyNow: [...state.buyNow, action.payload],
      };

    case "REMOVE_FROM_BUY_NOW":
      // handle removing from the buyNow
      return {
        ...state,
        buyNow: state.buyNow.filter((item) => item._id !== action.payload),
      };
    case "SET_SERVICE":
      return {
        buyNow: action.payload,
      };
    default:
      return state;
  }
};

export default buyNowReducer;
