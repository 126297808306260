import React, { useState, useEffect } from "react";
import {
  Heading,
  Text,
  Flex,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import YesNoField from "../../../../utils/YesNoField";

const Agreement = ({ request, setRequest }) => {
  
  const [ownAgreement, setOwnAgreement] = useState("");
  const [NURAgreement, setNURAgreement] = useState("");

  const handleYesNoChange = (value, type) => {
    request.toggleYesNo(value, type);

    if (value === 'Yes'){
      setOwnAgreement(true);
      request.togglePrice('No', 'PartnershipAgreement');
      setRequest((prevReq) => ({
        ...prevReq,
        partnerInfo: {
          ...prevReq.partnerInfo,
          partnershipAgreement: "",
          partnershipAgreementRequirements: ""
        }
      }));
    } else {
      setOwnAgreement(false);
      setRequest((prevReq) => ({
        ...prevReq,
        partnerInfo: {
          ...prevReq.partnerInfo,
          partnershipAgreement: ""
        }
      }));
    }
  };

  const handleYesNoNURChange = (value, type) => {
    request.togglePrice(value, type);

    if (value === 'Yes'){
      setNURAgreement(true);
      setRequest((prevReq) => ({
        ...prevReq,
        partnerInfo: {
          ...prevReq.partnerInfo,
          partnershipAgreement: "Snappy Corp"
        }
      }));
    } else {
      setNURAgreement(false);
      setRequest((prevReq) => ({
        ...prevReq,
        partnerInfo: {
          ...prevReq.partnerInfo,
          partnershipAgreement: "",
          partnershipAgreementRequirements: ""
        }
      }));
    }
  };

  useEffect(() => {
    if (request.partnerInfo.partnershipAgreement === "Snappy Corp") {
    }
  });

  const ownEnabled = request.yesno.PartnershipAgreement_Own.enabled;
  const templateEnabled = request.prices.PartnershipAgreement.enabled;

  return (
    <>
      <Heading>Partnership Agreement</Heading>
      <Text>
        A legal arrangement that governs how two or more legal entities or
        individuals in operating a business together. It includes the rights and
        obligations of the partners, covering aspects such as liability
        participation, and profit sharing.
      </Text>

      <YesNoField
        label={"Do you have your own Partnership Agreement?"}
        about={"If you have your own Partnership Agreement, usually lawyers or your previous experience might help you choose this option."}
        setValue={(value) => handleYesNoChange(value, 'PartnershipAgreement_Own')}
        enabled={ownEnabled}
      />

      {(ownAgreement === true || ownEnabled === true) && (
        <>
          <FormLabel color="blue.500" mt="12px !important">
            Enter your Partnership Agreement in here:
          </FormLabel>
          <Flex>
            <Textarea
              h="400px"
              border={"solid 2px #38a169"}
              autoFocus
              value={request.partnerInfo.partnershipAgreement}
              onChange={(e) =>
                setRequest((prevReq) => ({
                  ...prevReq,
                  partnerInfo: {
                    ...prevReq.partnerInfo,
                    partnershipAgreement: e.target.value
                  }
                }))
              }
            />
          </Flex>
        </>
      )}

      {(ownAgreement === false || ownEnabled === false) && (
        <YesNoField
          label={"Would you like us to help you and your partners create an agreement?"}
          about={"Approximately $250; depends upon agreement."}
          setValue={(value) => handleYesNoNURChange(value, 'PartnershipAgreement')}
          enabled={templateEnabled}
          price={request.prices.PartnershipAgreement.value}
        />
      )}

      {((ownAgreement === false && NURAgreement === true)
      || (ownEnabled === false && templateEnabled === true))
      && (
        <>
          <Heading as="h3" mt="12px !important">
            Outline your requirements:
          </Heading>
          <Text>
            Please include, the ownership structure, how profits should be
            divided, responsibilities, and other relevant information. Our
            lawyers will get back to you.
          </Text>
          <Textarea
            h="200px"
            border={"solid 2px #38a169"}
            autoFocus
            value={request.partnerInfo.partnershipAgreementRequirements}
            onChange={(e) =>
              setRequest((prevReq) => ({
                ...prevReq,
                partnerInfo: {
                  ...prevReq.partnerInfo,
                  partnershipAgreementRequirements: e.target.value
                }
              }))
            }
          />
          <Text color="green.500" fontWeight="bold" mt="12px !important">
            Thanks! We will get back to you.{" "}
          </Text>
        </>
      )}
      {((ownAgreement === false && NURAgreement === false)
      || (ownEnabled === false && templateEnabled === false))
      && (
        <>
          <FormLabel color="red">
            {/* You would require a Partnership Agreement to register your partnership. */}
          </FormLabel>
          <Text
            mt="24px !important"
            color="green.500"
            fontWeight="bold !important"
          >
            Please have us assist you in creating a partnership agreement or draft, otherwise bring your own Partnership Agreement.
          </Text>
        </>
      )}
    </>
  );
};

export default Agreement;
