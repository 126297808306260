import React, { useEffect, useState } from "react";
import NavBar from "../../utils/NavBar.js";
import CompanyComponent from "../new/register/CompanyComponent.js";
import Disclaimer from "../../utils/Disclaimer.js";

const Named = () => {
  return (
    <>
      <NavBar />
      <CompanyComponent bType={"named"} />
      <Disclaimer />
    </>
  );
};

export default Named;
