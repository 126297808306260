import React from "react";
import {
  Flex,
  HStack,
  Heading,
  VStack,
  Button,
  Divider,
  Text,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";

const DecideLimitedLiability = ({ setLiability }) => {
  const navigate = useNavigate();

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing={4}>
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/decide/business-name-protection");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading>Do you want to limit your personal liability?</Heading>

          <Text fontSize="150%" mb="24px">
            If you want to protect yourself against personal responsibility for the debts and obligations of the business, press <b>Yes</b>.
          </Text>
          
          <HStack spacing={4}>
            <Button className="btn-black" onClick={()=>{navigate('/decide/results')}}>Yes</Button>
            <Button className="btn-black" onClick={()=>{navigate('/decide/results')}}>No</Button>
          </HStack>
        </VStack>
      </Flex>

      <Disclaimer />
    </>
  );
};

export default DecideLimitedLiability;
