import React from "react";
import { Flex, Box, VStack, Heading, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const NavBar = ({ pageTitle }) => {
  return (
    <Flex className="nav-banner" justifyContent="center" alignItems="center">
      <VStack className="container">
        <Link to={"/"}>
          <Box textAlign="center" my="-24px">
            <Image
              className="logo"
              title="Snappy Corporations Logo" 
              src="/images/logo.png" 
              width={{base:"90px", lg:"150px"}}
            />
          </Box>
        </Link>
        
        {pageTitle && <Heading as="h3" fontSize="20px" color="orange">{pageTitle}</Heading>}
        
      </VStack>
    </Flex>    
  );
};

export default NavBar;
