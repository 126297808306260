import React from "react";
import {
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
const Disclaimer = () => {
  return (<>
    <Flex className="disclaimer" justifyContent="center">
      <Box className="container" >
        <Text>
          <b>DISCLAIMER:</b>
          <br />
          We are not legal professionals and this tool is not intended to provide legal advice. It's designed to assist you in your endeavors. Thank you for using our service. All prices are in CAD dollars, unless otherwise specified.
        </Text>
      </Box>
    </Flex>
  </>
  );
};

export default Disclaimer;
