import React from "react";
import {
  Flex,
  HStack,
  Heading,
  VStack,
  Button,
  Divider,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../utils/NavBar.js";
import Disclaimer from "../utils/Disclaimer.js";
import ModalDisclaimer from "../utils/ModalDisclaimer.js";

const StartPage = ({ request, setRequest }) => {
  const navigate = useNavigate();

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" mt="50px">        
        <VStack align="start" className="container" spacing="4">
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/");
            }}
          >
            Back
          </Button>
          
          <Divider className="divider" />

          <Heading>Are you conducting business in Alberta?</Heading>

          <HStack spacing={4}>
            <Button className="btn-black" onClick={()=>{navigate('/main/business')}}>Yes</Button>
            <Button className="btn-black" onClick={()=>{navigate('/main/location/extra-provincial')}}>No</Button>
          </HStack>
        </VStack>
      </Flex>

      <Disclaimer />

      <ModalDisclaimer />
    </>
  );
};

export default StartPage;
