import React, { useState } from "react";
import {
  Heading,
  Button,
  useDisclosure,
  useToast,
  Text,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Input,
  FormControl,
  FormLabel,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { handleSendInquiryEmail } from "../../../../services/email";
import { snappyCorpPriceList } from "../../../../services/price";

const Type = ({ setRequest, handleNext }) => {
  const toast = useToast();
  const [emailData, setEmailData] = useState({
    address: "",
    message: "",
    name: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  // NOTE: this code is exactly the same as in PartnersInfo
  const handlePartnershipTypeChange = (e) => {
    let type = e.target.value;
    let defaultPrice = 0;

    if (type === 'General'){
      defaultPrice = snappyCorpPriceList.GeneralPartnership;

      setRequest((prevReq) => ({
        ...prevReq,
        reviewFieldHidden: {
          ...prevReq.reviewFieldHidden,
          partnership: {
            ...prevReq.reviewFieldHidden.partnership,
            businessInfo: {
              ...prevReq.reviewFieldHidden.partnership.businessInfo,
              homeJurisdiction: false,
              homeJurisdiction_RegNo: false,
              homeJurisdiction_BusNo: false,
              streetLine1: false,
              streetLine2: false,
              city: false,
              province: false,
              country: false,
              postalCode: false
            }
          }
        }
      }));

    } else if (type === 'LLP'){
      defaultPrice = snappyCorpPriceList.LimitedLiabilityPartnership;

      setRequest((prevReq) => ({
        ...prevReq,
        reviewFieldHidden: {
          ...prevReq.reviewFieldHidden,
          partnership: {
            ...prevReq.reviewFieldHidden.partnership,
            businessInfo: {
              ...prevReq.reviewFieldHidden.partnership.businessInfo,
              homeJurisdiction: undefined,
              homeJurisdiction_RegNo: undefined,
              homeJurisdiction_BusNo: undefined,
              streetLine1: undefined,
              streetLine2: undefined,
              city: undefined,
              province: undefined,
              country: undefined,
              postalCode: undefined
            }
          }
        }
      }));
    } else if (type === 'LP'){
      defaultPrice = snappyCorpPriceList.LimitedPartnership;
    } else {
      defaultPrice = 0;
    }

    setRequest((prevReq) => ({
      ...prevReq,
      price: defaultPrice,
      prices: {
        ...prevReq.prices,
        default: defaultPrice
      },
      partnerInfo: {
        ...prevReq.partnerInfo,
        partnershipType: type
      }
    }));

    handleNext();
  };

  const handleSendEmail = async () => {
    (await handleSendInquiryEmail(emailData))
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    onClose();
  };

  return (
    <>
      <Heading>Please choose the type of Partnership</Heading>

      <br />

      <Grid
        templateColumns={{ base: '1fr', md: 'auto 1fr auto auto' }}
        gap={6}
        alignItems="top"
      >
        <GridItem>
          <Text mb="0 !important" fontWeight="bold">
            General Partnership
          </Text>
        </GridItem>
        <GridItem>
          <Text mb="0 !important">
            Partnership where two or more entities can work together.
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="30px" color="green" fontWeight="bold"  mb="0 !important">
            ${snappyCorpPriceList.GeneralPartnership}
          </Text>
        </GridItem>
        <GridItem>
          <Button
            onClick={handlePartnershipTypeChange}
            // onClick={() => {
            //   setRequest((prevRequest) => ({
            //     ...prevRequest,
            //     partnerInfo: {
            //       ...prevRequest.partnerInfo,
            //       partnershipType: "General"
            //     },
            //     price: snappyCorpPriceList.GeneralPartnership,
            //     prices: {
            //       ...prevRequest.prices,
            //       default: snappyCorpPriceList.GeneralPartnership,
            //     }
            //   }));
            //   handleNext();
            // }}
            colorScheme="grey"
            variant="outline"
            width="120px"
            value="General"
          >
            Select
          </Button>
        </GridItem>

        <GridItem></GridItem>
        <GridItem></GridItem>
        <GridItem></GridItem>
        <GridItem></GridItem>

        <GridItem>
          <Text mb="0 !important" fontWeight="bold">
            Limited Liability Partnership
          </Text>
        </GridItem>
        <GridItem>
          <Text mb="0 !important">
            A limited liability partnership (LLP) is a partnership in which some or all partners have limited liabilities. In an LLP, each partner is protected from being responsible or liable for another partner's misconduct or negligence; setting it apart from traditional partnerships.
            <br /><br />
            LLPs must have a professional endorsement from the governing body of an eligible profession.
            <br /><br />
            LLPs practice in professions such as chartered professional accounting, chiropractic, dentistry, law, optometry, and medicine.
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="30px" color="green" fontWeight="bold"  mb="0 !important">
            ${snappyCorpPriceList.LimitedLiabilityPartnership}
          </Text>
        </GridItem>
        <GridItem>
         <Button
            onClick={handlePartnershipTypeChange}
            // onClick={() => {
            //   setRequest((prevRequest) => ({
            //     ...prevRequest,
            //     partnerInfo: {
            //       ...prevRequest.partnerInfo,
            //       partnershipType: "LLP"
            //     },
            //     price:snappyCorpPriceList.LimitedLiabilityPartnership,
            //     prices: {
            //       ...prevRequest.prices,
            //       default: snappyCorpPriceList.LimitedLiabilityPartnership,
            //     }
            //   }));
            //   handleNext();
            // }}
            colorScheme="grey"
            variant="outline"
            width="120px"
            value="LLP"
          >
            Select
          </Button>
        </GridItem>

        <GridItem></GridItem>
        <GridItem></GridItem>
        <GridItem></GridItem>
        <GridItem></GridItem>

        <GridItem>
          <Text mb="0 !important" fontWeight="bold">
            Limited Partnership
          </Text>
        </GridItem>
        <GridItem>
          <Text mb="0 !important">
            Partnership where the general partner is liable for the obligations of the partnership and the limited partners are liable for their contribution to the partnership.
            <br /><br />
            This type of partnership requires some original documents to be executed at Snappy Corp, Calgary. Please reach out to a Snappy Corp representative for further information.
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="30px" color="green" fontWeight="bold"  mb="0 !important">
            ${snappyCorpPriceList.LimitedPartnership}
          </Text>
        </GridItem>
        <GridItem>
          <Button
            colorScheme="purple"
            variant={"outline"}
            onClick={onOpen}
            width="120px"
          >
            Inquire now!
          </Button>
        </GridItem>
      </Grid>

      <br />

      {/* <Table>
        <Tbody>
          <Tr>
            <Th>General Partnership</Th>
            <Td>
              <p>Partnership where two or more entities can work together.</p>
            </Td>
            <Td fontSize="200%" color="green">
              ${snappyCorpPriceList.GeneralPartnership}
            </Td>
            <Td>
              <Button
                onClick={() => {
                  setRequest((prevRequest) => ({
                    ...prevRequest,
                    partnershipType: "General",
                    price: snappyCorpPriceList.GeneralPartnership,
                    prices: {
                      ...prevRequest.prices,
                      default: snappyCorpPriceList.GeneralPartnership,
                    }
                  }));
                  handleNext();
                }}
                colorScheme="grey"
                variant="outline"
              >
                Select
              </Button>
            </Td>
          </Tr>
          <Tr>
            <Th>Limited Liability Partnership </Th>
            <Td>
              <p>
                A limited liability partnership (LLP) is a partnership in which some or all partners have limited liabilities. In an LLP, each partner is protected from being responsible or liable for another partner's misconduct or negligence; setting it apart from traditional partnerships.
              </p>
              <p>
                LLPs must have a professional endorsement from the governing body of an eligible profession.
              </p>
              <p>
                LLPs practice in professions such as chartered professional accounting, chiropractic, dentistry, law, optometry, and medicine.
              </p>
            </Td>
            <Td fontSize="200%" color="green">
              ${snappyCorpPriceList.LimitedLiabilityPartnership}
            </Td>
            <Td>
              <Button
                onClick={() => {
                  setRequest((prevRequest) => ({
                    ...prevRequest,
                    partnershipType: "LLP",
                    price:snappyCorpPriceList.LimitedLiabilityPartnership,
                    prices: {
                      ...prevRequest.prices,
                      default: snappyCorpPriceList.LimitedLiabilityPartnership,
                    }
                  }));
                  handleNext();
                }}
                colorScheme="grey"
                variant="outline"
              >
                Select
              </Button>
            </Td>
          </Tr>
          <Tr>
            <Th>Limited Partnership </Th>
            <Td>
              <p>
                Partnership where the general partner is liable for the obligations of the partnership and the limited partners are liable for their contribution to the partnership.
              </p>
              <p>
                This type of partnership requires some original documents to be executed at Snappy Corp, Calgary. Please reach out to a Snappy Corp representative for further information.
              </p>
            </Td>
            <Td fontSize="200%" color="green">
              ${snappyCorpPriceList.LimitedPartnership}
            </Td>
            <Td>
              <Center mt="50px">
                <Button
                  colorScheme="purple"
                  variant={"outline"}
                  onClick={onOpen}
                >
                  Inquire now!
                </Button>
              </Center>
            </Td>
          </Tr>
        </Tbody>
      </Table> */}

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" color="green.500">
              We will contact you as soon as possible!
            </Heading>
            <Text>Please provide your details here.</Text>
            <FormControl>
              <FormLabel>Your Name:</FormLabel>
              <Input
                placeholder="Your Name"
                mb={4}
                value={emailData.name}
                onChange={(e) =>
                  setEmailData({ ...emailData, name: e.target.value })
                }
              />
              <FormLabel>Your Email Address:</FormLabel>
              <Input
                placeholder="Email Address"
                mb={4}
                value={emailData.address}
                onChange={(e) =>
                  setEmailData({ ...emailData, address: e.target.value })
                }
              />
              <FormLabel>Your Inquiry:</FormLabel>
              <Textarea
                placeholder="Message"
                rows={4}
                value={emailData.message}
                onChange={(e) =>
                  setEmailData({ ...emailData, message: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button
                variant="ghost"
                color="black"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button colorScheme="green" onClick={handleSendEmail}>
                Submit
              </Button>
              <Button
                colorScheme="yellow"
                variant="solid"
                onClick={() => {
                  window.location.href = "tel:+1(403) 262 9999";
                }}
              >
                Call Us
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Type;
