import axios from "axios";

const uri = `${process.env.REACT_APP_BACKEND_KEY}/forms`;

const getAllForms = async () => {
  try {
    console.log("get ALl");
    const response = await axios.get(`${uri}/fetch`, {});
    return response;
  } catch (error) {
    return error.response;
  }
};
const createForm = async (form) => {
  try {
    const response = await axios.post(`${uri}/crud`, { form });
    console.log(form);
    return response;
  } catch (error) {
    return error.response;
  }
};

const updateForm = async (form) => {
  try {
    const _id = form._id;
    delete form._id;
    const response = await axios.put(`${uri}/crud`, { _id, form });
    return response;
  } catch (error) {
    return error.response;
  }
};

const deleteForm = async (id) => {
  try {
    const response = await axios.delete(`${uri}/crud`, { data: { _id: id }
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
export { getAllForms, updateForm, createForm, deleteForm };
