import React from "react";
import YesNoField from "../../../../utils/YesNoField.js";
import { Heading, Text, VStack, Divider } from "@chakra-ui/react";
import { OfficersInfo } from "./Officers";
import Bylaws from "./ByLaws";
import UnanimousShareholderAgreement from "./UnanimousShareholderAgreement.js";

const MinuteBook = ({ request, setRequest, companyType }) => {
  
  const handleMinuteBookChange = (value, priceType) => {
    let bool = value === 'Yes' ? true : false;

    setRequest((prevReq) => ({
      ...prevReq,
      minuteBook: bool
    }));

    request.togglePrice(value, priceType);

    // must reset other add-ons contained in Minute Book
    if (value === 'No'){
      request.togglePrice('No', 'UnanimousShareholderAgreement');
    }
  };

  const enabled = request.prices.MeetingMinutes.enabled;

  return (
    <VStack className="minutebook" align="left" height="460px" overflowY="scroll">
      <Heading>Minute Book</Heading>

      <Text>
        Snappy Corporations is able to assist you in drafting further constating documents for your corporation or sole proprietorship, including Unanimous Shareholder Agreements and Bylaws, as well as resolutions and meeting minutes appointing directors, officers and other ancillary service providers. 
      </Text>

      <Bylaws request={request} setRequest={setRequest} />

      <UnanimousShareholderAgreement request={request} setRequest={setRequest} />

      <Divider className="subdivider" />

      <Heading as="h3">First Time Meeting Minutes</Heading>

      <YesNoField
        label={
          "Would you like us to help with drafting your corporation's first meeting minutes?"
        }
        about={
          "To set up officers, bylaws, unanimous shareholders agreement, and all schedules for your company for the first time."
        }
        setValue={(value) => handleMinuteBookChange(value, "MeetingMinutes")}
        enabled={request.prices.MeetingMinutes.enabled}
        price={request.prices.MeetingMinutes.value}
      />
      {enabled === true && (
        <OfficersInfo request={request} setRequest={setRequest} companyType={companyType} />
      )}

    </VStack>
  );
};

export default MinuteBook;
