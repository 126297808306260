import React from "react";
import Extraprovincial from "./Extraprovincial";
import { Routes, Route } from "react-router-dom";
import Numbered from "./Numbered";
import Named from "./Named";
import RouteNotFound from "../../utils/RouteNotFound";

const ChecklistRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/extraprovincial" element={<Extraprovincial />} />
        <Route path="/numberedInc" element={<Numbered />} />
        <Route path="/namedInc" element={<Named />} />
        
        <Route path="/*" element={<RouteNotFound />} />
      </Routes>
    </>
  );
};

export default ChecklistRoutes;
