import { useEffect, useState } from "react";
import {
  Heading,
  Textarea,
  Text,
  FormLabel,
  Flex,
} from "@chakra-ui/react";

import YesNoField from "../../../../utils/YesNoField";

const Bylaws = ({ request, setRequest }) => {

  const [ownBylaws, setOwnBylaws] = useState("");
  const [NURBylaws, setNURBylaws] = useState("No Bylaws");

  const handleYesNoChange = (value, type) => {
    request.toggleYesNo(value, type);

    if (value === 'Yes'){
      setOwnBylaws(true);
      setNURBylaws(false);
      request.toggleYesNo('No', 'ByLaws_Template');
      setRequest((prevReq) => ({
        ...prevReq,
        minuteBookInfo: {
          ...prevReq.minuteBookInfo,
          bylaws: ""
        }
      }));
    } else {
      setOwnBylaws(false);
      setRequest((prevReq) => ({
        ...prevReq,
        minuteBookInfo: {
          ...prevReq.minuteBookInfo,
          bylaws: NURBylaws
        }
      }));
    }
  };

  const handleYesNoNURChange = (value, type) => {
    request.toggleYesNo(value, type);

    if (value === 'Yes'){
      setNURBylaws(true);
      setRequest((prevReq) => ({
        ...prevReq,
        minuteBookInfo: {
          ...prevReq.minuteBookInfo,
          bylaws: "Snappy Corp"
        }
      }));
    } else {
      setNURBylaws(false);
      setRequest((prevReq) => ({
        ...prevReq,
        minuteBookInfo: {
          ...prevReq.minuteBookInfo,
          bylaws: "No Bylaws."
        }
      }));
    }
  };


  const ownEnabled = request.yesno.ByLaws_Own.enabled;
  const templateEnabled = request.yesno.ByLaws_Template.enabled;

  return (
    <>
      <Heading as="h3" mt="24px !important">Bylaws</Heading>

      <Text>
        Bylaws specify the rights and power of shareholders, directors, officers and the procedures that govern the corporation. This document specifies the uniques goals and needs of your corporation.
        <br />
        When Bylaws for an Alberta corporation are created, they must be written in a manner that complies with corporate laws of Alberta.
      </Text>

      <YesNoField
        label={"Do you have your own bylaws?"}
        about={"If you have your own bylaws, usually lawyers or your previous experience might help you choose this option."}
        setValue={(value) => handleYesNoChange(value, 'ByLaws_Own')}
        enabled={ownEnabled}
      />

      {(ownBylaws === true || ownEnabled === true) && (
        <>
          <FormLabel color="blue.500" mt="12px !important">
            Enter your bylaws in here:
          </FormLabel>
          <Flex>
            <Textarea
              h="300px"
              autoFocus
              value={request.minuteBookInfo.bylaws}
              onChange={(e) =>
                setRequest((prevReq) => ({
                  ...prevReq,
                  minuteBookInfo: {
                    ...prevReq.minuteBookInfo,
                    bylaws: e.target.value
                  }
                }))
              }
            />
          </Flex>
        </>
      )}

      {(ownBylaws === false || ownEnabled === false) && (

        <YesNoField
          label={"Do you want to use a generic corporate template of bylaws?"}
          about={"We with the help of lawyers have created generalized bylaws, which hundreds of companies found the right fit for them."}
          setValue={(value) => handleYesNoNURChange(value, 'ByLaws_Template')}
          enabled={templateEnabled}
        />

      )}

      {((ownBylaws === false && NURBylaws === true)
      || (ownEnabled === false && templateEnabled === true))
      && (
        <>
          <Text color="green.500" fontWeight="bold" my="24px !important">Bylaws will be attached.</Text>
          <Text>Thanks!</Text>
        </>
      )}
      {((ownBylaws === false && NURBylaws === false)
      || (ownEnabled === false && templateEnabled === false))
      && (
        <>
          <Text color="red" fontWeight="bold" my="24px !important">
            It is recommended to have your corporation's bylaws.
          </Text>

          <Text>
            Although Bylaws are not legally required within Alberta, if the bylaws do not exist or do not articulate certain rules, then the requirements outlined by the business corporation laws in Alberta will determine how your Alberta corporation is governed. This may result in undesirable outcomes for your corporation.
          </Text>
        </>
      )}
    </>
  );
};

export default Bylaws;
