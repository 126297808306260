import React from "react";
import { Route, Routes } from "react-router-dom";
import ChangeAddress from "./changeAddress/ChangeAddress";
import AmendPartners from "./changePartners/Main";
import AmendPartnershipHome from "./AmendPartnershipHome";

const ExistingPartnersIndex = () => {
  return (
    <Routes>
      <Route path="/changeAddress" element={<ChangeAddress />} />
      <Route path="/amendPartners" element={<AmendPartners />} /> 
      <Route path="/" element={<AmendPartnershipHome />} />
    </Routes>
  );
};

export default ExistingPartnersIndex;
