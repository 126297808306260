import React from "react";
import { Flex, Heading, Button, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const RouteNotFound = () => {
  const history = useNavigate();

  const handleGoBack = () => {
    window.history.back();
  };

  const handleGoHome = () => {
    history("/");
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="80vh"
      bg="gray.100"
    >
      <Image
        src="https://aemorph.com/wp-content/uploads/404-page-best-practices.jpg"
        alt="404 Error"
        width="70vh"
        mb="4"
      />
      <Heading fontSize="6xl" color="red.500" mb="4">
        404 Error
      </Heading>
      <Heading fontSize="xl" mb="8">
        Oops! The page you're looking for doesn't exist.
      </Heading>
      <Flex>
        {
        window.history.length >=1 &&
          <Button
          colorScheme="teal"
          variant="solid"
          mr="4"
          onClick={handleGoBack}
        >
          Go Back
        </Button>}
        <Button colorScheme="teal" variant="outline" onClick={handleGoHome}>
          Go Home
        </Button>
      </Flex>
    </Flex>
  );
};

export default RouteNotFound;
