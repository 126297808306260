// cartActions.js
export const loginEmployee = (user) => ({
    type: "LOGIN",
    payload: user,
  });
  
  export const logoutEmployee = () => ({
    type: "LOGOUT",
  });
  
  