import React from "react";
import {
  Flex,
  HStack,
  Heading,
  VStack,
  Button,
  Divider,
  Text,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";

const BusinessType = ({setBType}) => {

  const navigate = useNavigate({ setBType });

  return (
    
    <>
      <NavBar />

      <Flex justifyContent="center" mt="50px">
        <VStack align="start" className="container" spacing={4}>
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/main");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading>Tell us about your business.</Heading>

          <Text fontSize="150%" mb="24px">
            Are you incorporating a new business or do you require services for your current business?
          </Text>

          <HStack spacing={4}>
            <Button className="btn-black" onClick={()=>{navigate('/main/business-type')}}>New</Button>
            <Button className="btn-black" onClick={()=>{navigate('/main/existing-business')}}>Existing</Button>
          </HStack>
        </VStack>
      </Flex>

      <Disclaimer />
    </>
  );
};

export default BusinessType;
