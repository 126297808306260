import React, { useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Heading,
  VStack,
  Button,
  Divider,
  List,
  ListItem,
  ListIcon,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";
import { handleSendInquiryEmail } from "../../../services/email.js";
import ModalDisclaimer from "../../../utils/ModalDisclaimer.js";

const CancelOrDissolve = () => {
  const toast = useToast();
  const [emailData, setEmailData] = useState({
    address: "",
    message: "",
    name: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSendEmail = async () => {
    (await handleSendInquiryEmail(emailData))
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    onClose();
  };

  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <Flex justifyContent="center" mt="50px">
        <Box className="container">
          <VStack align="start" spacing={4}>
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>

            <Divider className="divider" />

            <Heading as="h1">Cancel / Dissolve a Corporation</Heading>

            <Heading as="h2">
              Here are some important points to consider before canceling an
              extra-provincial registration in Alberta or dissolving an Alberta
              corporation:
            </Heading>

            <List>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                All debts and obligations need to be settled before dissolution.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Distribute any remaining assets amongst shareholders.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                File final tax returns and ensure all tax liabilities are
                resolved.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Notify all relevant shareholders and authorities about the
                dissolution.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Cancel any business licenses or permits related to your
                corporation.
              </ListItem>
            </List>

            <Button colorScheme="green" onClick={onOpen} width={{base:"100%", md:"auto"}}>
              Contact Us to Start the Process
            </Button>
          </VStack>
        </Box>
      </Flex>

      <Disclaimer />

      <ModalDisclaimer />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" color="green.500">
              We will contact you as soon as possible!
            </Heading>
            <Text>Please provide your details here.</Text>
            <FormControl>
              <FormLabel>Your Name:</FormLabel>
              <Input
                placeholder="Your Name"
                mb={4}
                value={emailData.name}
                onChange={(e) =>
                  setEmailData({ ...emailData, name: e.target.value })
                }
              />
              <FormLabel>Your Email Address:</FormLabel>
              <Input
                placeholder="Email Address"
                mb={4}
                value={emailData.address}
                onChange={(e) =>
                  setEmailData({ ...emailData, address: e.target.value })
                }
              />
              <FormLabel>Your Inquiry:</FormLabel>
              <Textarea
                placeholder="Message"
                rows={4}
                value={emailData.message}
                onChange={(e) =>
                  setEmailData({ ...emailData, message: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack spaciong="2">
              <Button
                variant="ghost"
                colorScheme="black"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button colorScheme="green" onClick={handleSendEmail}>
                Submit
              </Button>
              <Button
                colorScheme="yellow"
                variant="solid"
                onClick={() => {
                  window.location.href = "tel:+1(403) 262 9999";
                }}
              >
                Call Us
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelOrDissolve;
