import React, { useState } from "react";
import { Route, Routes} from "react-router-dom";
import DecideBNameProtection from "./DecideNameProtection";
import DecideLimitedLiability from "./DecideLiability";
import DecideOwners from "./DecideOwners";
import Results from "./Results";

const DecideForMe = () => {
  const [liability, setLiability] = useState("");
  const [singleOnwer, setSingleOnwer] = useState("");
  const [bNameProtection, setBNameProtection] = useState("");
  const [fundsRequired, setFundsRequired] = useState("");

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<DecideOwners setSingleOwner={setSingleOnwer} />}
        />
        {/* <Route
          path="/funds"
          element={<DecideFundRaising setFundsRequired={setFundsRequired} />}
        /> */}
        <Route
          path="/business-name-protection"
          element={
            <DecideBNameProtection setBNameProtection={setBNameProtection} />
          }
        />
        <Route
          path="/ltd-liability"
          element={<DecideLimitedLiability setLiability={setLiability} />}
        />
        <Route
          path="/results"
          element={
            <Results
              liability={liability}
              singleOnwer={singleOnwer}
              bNameProtection={bNameProtection}
              fundsRequired={fundsRequired}
            />
          }
        />
      </Routes>
    </>
  );
};

export default DecideForMe;
