import React from "react";
import {
  Text,
  Heading,
  Input,
  FormLabel,
  Grid,
  GridItem,
  FormControl,
  Select,
} from "@chakra-ui/react";
import Province from "../../../utils/Province";

const AccountInformation = ({ companyType, serviceType, request, setRequest }) => {
  const handleChangeAccountInfo = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      accountInfo: {
        ...prevRequest.accountInfo,
        [field]: value,
      },
    }));
  };

  return (
    <>
      <Heading>Personal Information</Heading>

      <FormControl>
        <Grid
          templateColumns={{ base: '1fr', md: 'auto 1fr' }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <FormLabel pr="20">
              My first name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.firstName}
              onChange={(e) => handleChangeAccountInfo("firstName", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My last name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.lastName}
              onChange={(e) => handleChangeAccountInfo("lastName", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My phone number is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.phone}
              onChange={(e) => handleChangeAccountInfo("phone", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My email is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.email}
              onChange={(e) => handleChangeAccountInfo("email", e.target.value)}
            />
          </GridItem>
          {companyType === "soleProprietorship" && (
          <>
          <GridItem>
            <FormLabel pr="20">
              My occupation is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.occupation}
              onChange={(e) => handleChangeAccountInfo("occupation", e.target.value)}
            />
          </GridItem>
          </>
          )}
          <GridItem>
            <FormLabel pr="20">
              My relation to the business is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Select
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.relation}
              onChange={(e) => handleChangeAccountInfo("relation", e.target.value)}
            >
              {(companyType === 'named' || companyType === 'numbered' || serviceType === 'NewExtraProvincial') && (
              <option key={0} value={"incorporator"}>incorporator</option>
              )}
              {(companyType === 'partnership' || companyType === 'soleProprietorship') && (
              <option key={0} value={"owner"}>owner</option>
              )}
              {companyType !== 'soleProprietorship' && (
              <option key={1} value={"agent for service"}>agent for service</option>
              )}
              {companyType !== 'soleProprietorship' && (
              <option key={2} value={"solicitor"}>solicitor</option>
              )}
            </Select>
          </GridItem>

          {/* <GridItem>
              <FormLabel pr="20">
                My relation to the corporation is
              </FormLabel>
            </GridItem>
            <GridItem>
              <Select
                w={{ base: "100%", md: "30ch" }}
                value={request.accountInfo.relation}
                onChange={(e) => handleChangeAccountInfo("relation", e.target.value)}
              >
                <option key={0} value={"agent for service"}>agent for service</option>
                <option key={1} value={"director"}>director</option>
                <option key={2} value={"shareholder"}>shareholder</option>
                <option key={3} value={"solicitor"}>solicitor</option>
              </Select>
            </GridItem> */}

        </Grid>
      </FormControl>

      <br />

      <Text fontWeight="bold">My address is:</Text>

      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
        gap={3}
      >
        <GridItem>
          <FormLabel>Address Line 1</FormLabel>
          <Input
            value={request.accountInfo.streetLine1}
            onChange={(e) => handleChangeAccountInfo("streetLine1", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Address Line 2</FormLabel>
          <Input
            value={request.accountInfo.streetLine2}
            onChange={(e) => handleChangeAccountInfo("streetLine2", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>City</FormLabel>
          <Input
            value={request.accountInfo.city}
            onChange={(e) => handleChangeAccountInfo("city", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Province</FormLabel>
          <Province
            value={request.accountInfo.province}
            onChange={(e) =>
              handleChangeAccountInfo("province", e.target.value)
            }
          />
        </GridItem>
        <GridItem>
          <FormLabel>Country</FormLabel>
          <Input
            value={request.accountInfo.country}
            onChange={(e) => handleChangeAccountInfo("country", e.target.value)}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Postal Code</FormLabel>
          <Input
            value={request.accountInfo.postalCode}
            onChange={(e) => handleChangeAccountInfo("postalCode", e.target.value)}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default AccountInformation;
