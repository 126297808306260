import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  HStack,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";


const BStrucRowTitle = ({ title, info }) => {
  return (
    <HStack >
      <Text mb="0 !important">{title}</Text>
      {info && <Popover>
        <PopoverTrigger className="popover-trigger">
          <InfoOutlineIcon className="popover-trigger-icon" cursor="pointer" />
        </PopoverTrigger>
        <PopoverContent className="popover-content">
          <PopoverArrow className="popover-arrow"/>
          <PopoverCloseButton />
          <PopoverBody textAlign={"center"} paddingRight={"24px"}>{info}</PopoverBody>
        </PopoverContent>
      </Popover>}
    </HStack>
  );
};

export default BStrucRowTitle;
