import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  VStack,
  Button,
  HStack,
  Center,
  Divider,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import NavBar from "../../../../utils/NavBar";
import DirectorsAndOfficersInfo from "../provincial/Directors";
import Payment from "../Payment";
import AgentForService from "../provincial/AgentForService";
import AccountInformation from "../AccountInformation";
import AddOns from "../provincial/AddOns";
import HomeJurisdictionInfo from "./HomeJurisdictionInfo";
import ExtraBusInformation from "./BusinessInfo";
import Disclaimer from "../../../../utils/Disclaimer";
import { snappyCorpPriceList } from "../../../../services/price";
import Review from "../Review";

const NewExtraprovincialRegister = ({ bType }) => {

  const { context } = useParams();

  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const [request, setRequest] = useState({
    serviceType: "NewExtraProvincial",
    price: context == 'nwpta' ? snappyCorpPriceList.ExtraProvincialNWPTA : snappyCorpPriceList.ExtraProvincial,
    prices: {
      default: context == 'nwpta' ? snappyCorpPriceList.ExtraProvincialNWPTA : snappyCorpPriceList.ExtraProvincial,
      AgentForService: {
        value: snappyCorpPriceList.SnappyAgent,
        enabled: false,
      },
      SubscribeAnnualMaintenance: {
        value: snappyCorpPriceList.SnappyAnnualSubscription,
        enabled: false,
      },
      FederalDiscount: {
        value: snappyCorpPriceList.FederalDiscount,
        enabled: false,
      },
      NwptaAgentForServiceDiscount: {
        value: snappyCorpPriceList.NwptaAgentForServiceDiscount,
        enabled: false,
      },
    },
    accountInfo: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      province: "AB",
      country: "Canada",
      postalCode: "",
      relation: "incorporator"
    },
    businessInfo: {
      businessName: "",
      email: "",
      previousCorpName: "",
      previousCorporateAccessNumber: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      province: "AB",
      country: "Canada",
      postalCode: ""
    },
    homeJurisdictionInfo: {
      principalBusiness: "",
      currentHomeJurisdiction: context === "nwpta" ? "BC" : "NB",
      homeBusinessNumber: "",
      homeRegistrationNumber: "",
      homeIncorporationDate: "",
    },
    directors: {
      officershipType: "By Myself",
      managers: "",
    },
    agent: {
      firstName: "",
      lastName: "",
      email: "",
      appointmentDate: "",
      streetLine1: "",
      streetLine2: "",
      city: "Calgary",
      province: "AB",
      country: "Canada",
      postalCode: ""
    },
    minuteBook: false,
    annualMgmt: false,
    review: [
      'accountInfo',
      'businessInfo',
      'homeJurisdictionInfo',
      'directors',
      'agent'
    ],
    reviewFieldCount: 0,
    reviewFieldNoDataCount: 0,
    reviewFieldNotRequired: {
      accountInfo: {
        streetLine2: false
      },
      businessInfo: {
        streetLine2: false,
        previousCorpName: false,
        previousCorporateAccessNumber: false
      },
      homeJurisdictionInfo: {
        streetLine2: false
      },
      agent: {
        streetLine2: false
      }
    },
    reviewFieldHidden: {
      homeJurisdictionInfo: {
        type: false
      },
      directors: {
        managers: false
      }
    },
    reviewFieldReadOnly: {
      accountInfo: {
        relation: false,
      },
      homeJurisdictionInfo: {
        currentHomeJurisdiction: false,
      },
      directors: {
        officershipType: false
      },
      agent: {
        province: false
      }
    },
    togglePrice: (value, priceType) => {
      setRequest((prevRequest) => {
        let bool = value === 'Yes' ? true : false;
        let updatedPrices = prevRequest.prices;
  
        updatedPrices[priceType].enabled = bool;

        return {
          ...prevRequest,
          prices: updatedPrices,
        };
      });

      request.updatePrice();
    },
    updatePrice: () => {
      setRequest((prevRequest) => {
        let updatedPrice = 0;

        updatedPrice += prevRequest.prices.default;

        for (const key in prevRequest.prices) {
          if (prevRequest.prices[key].enabled === true) {
            updatedPrice += prevRequest.prices[key].value;
          }
        }

        return {
          ...prevRequest,
          price: updatedPrice,
        };
      });
    },
  });

  //   // Define sections for each company type
  const sectionsMap = [
    "accountInformation",
    "businessInformation",
    "homeJurisdiction",
    "directors",
    "agentForService",
    "addOns",
    "review",
    "payment",
  ];

  const handlePrevious = () => {
    setSelectedSectionIndex((prevIndex) => Math.max(0, prevIndex - 1));
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setSelectedSectionIndex((prevIndex) =>
      Math.min(prevIndex + 1, sectionsMap.length - 1)
    );
    window.scrollTo(0, 0);
  };

  const renderMainComponent = () => {
    const sections = sectionsMap;
    const selectedSection = sections[selectedSectionIndex];
    switch (selectedSection) {
      case "accountInformation":
        return <AccountInformation companyType={bType} serviceType={request.serviceType} request={request} setRequest={setRequest} />;
      case "businessInformation":
        return (
          <ExtraBusInformation
            companyType={bType}
            request={request}
            setRequest={setRequest}
          />
        );
      case "homeJurisdiction":
        return (
          <HomeJurisdictionInfo
            companyType={bType}
            request={request}
            setRequest={setRequest}
            context={context}
          />
        );
      case "agentForService":
        return <AgentForService request={request} setRequest={setRequest} context={context} />;
      case "addOns":
        return (
          <>
            <AddOns request={request} setRequest={setRequest} />
          </>
        );
      case "directors":
        return (
          <DirectorsAndOfficersInfo request={request} setRequest={setRequest} />
        );
      case "review":
        return <Review request={request} setRequest={setRequest} handleNext={handleNext} />;
      case "payment":
        return <Payment request={request} setRequest={setRequest} />;
      default:
        return null;
    }
  };

  return (
    <>
      <NavBar />

      <Center mt="40px">
        <VStack align="start" className="container">
          <HStack w="90%" justifyContent="min-content">
            <Heading as="h1">Extra-Provincial Registration {context == 'nwpta' ? '(NWPTA)' : ''}</Heading>
          </HStack>

          <Divider className="divider" />

          {bType !== "" && (
            <Flex w="100%">
              {/* Sidebar */}
              <Box
                className="sidebar"
                w="25%"
              >
                <VStack align="start">
                  {sectionsMap?.map((section, index) => (
                    <Button
                      key={index}
                      onClick={() => setSelectedSectionIndex(index)}
                      variant={
                        selectedSectionIndex === index ? "solid" : "ghost"
                      }
                      bg={selectedSectionIndex === index ? "green.700" : ""}
                      w="100%"
                      color={selectedSectionIndex === index ? "white" : ""}
                      m={0}
                      _hover={{
                        bg:
                          selectedSectionIndex === index
                            ? "green.700"
                            : "green.400",
                        color: "white", // Set text color for better contrast
                      }}
                    >
                      {section
                        .replace("account", "personal")
                        .replace(/([A-Z])/g, " $1")
                        .trim()
                        .replace(/^\w/, (c) => c.toUpperCase())}{" "}
                    </Button>
                  ))}
                </VStack>
              </Box>

              {/* Main bar */}
              <Box w="75%" className="maincomponent">
                {renderMainComponent()}
                {/* Previous and Next Button */}
                
                <Flex className="nextprevious">
                  <Center width="100%">
                    {selectedSectionIndex !== 0 && (
                      <Button
                        className="btn-black"
                        leftIcon={<ArrowLeftIcon />}
                        onClick={handlePrevious}
                        aria-label="Previous"
                        mr={2}
                        disabled={selectedSectionIndex === 0 || !sectionsMap}
                      >
                        Previous
                      </Button>
                    )}
                    {selectedSectionIndex < sectionsMap.length - 1 && (
                      <Button
                        className="btn-black"
                        rightIcon={<ArrowRightIcon />}
                        onClick={handleNext}
                        aria-label="Next"
                        disabled={
                          selectedSectionIndex === sectionsMap?.length - 1 ||
                          !sectionsMap
                        }
                      >
                        Next
                      </Button>
                    )}
                  </Center>
                </Flex>

              </Box>
            </Flex>
          )}
        </VStack>
      </Center>

      <Disclaimer />
    </>
  );
};

export default NewExtraprovincialRegister;
