import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  useToast,
  Heading,
  Divider,
  Table,
  Tbody,
  Tr,
  Td,
  FormControl,
  Switch,
  Input,
  Textarea,
  HStack,
  Tab,
  Tabs,
  TabList,
  // Import other Chakra-UI components as needed
} from "@chakra-ui/react";
import { getService, updateService } from "../../../services/admin/services";
import { ArrowBackIcon } from "@chakra-ui/icons";
import EmpBar from "../../EmpBar";
import ServiceForms from "../../../utils/ServiceForms";

const EditService = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getService(id);
        if (response.status === 200) {
          setServiceData(response.data.service);
        } else {
          // Handle error here, e.g., show a toast message
        }
      } catch (error) {
        console.error("Error loading service: ", error);
      }
    };
    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServiceData({ ...serviceData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateService(serviceData);
      console.log(response);
      if (response.status === 200) {
        toast({
          title: "Service Updated",
          description: "Service has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(`/employee/admin/services/service/view/${id}`);
      } else {
        // Handle error here, e.g., show a toast message
        console.error("Error updating service: ", response.data.message);
        toast({
          title: "Something went wrong!",
          description: "Service could not be updated. Check if server is up.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Handle error here, e.g., show a toast message
      console.error("Error updating service: ", error);
    }
  };

  return (
    <>
      <EmpBar />
      {window.history.length >= 1 && (
        <Button
          bg="black"
          color="white"
          leftIcon={<ArrowBackIcon />}
          _hover={{ bg: "black.500", color: "yellow" }}
          _active={{ bg: "gray.200", color: "black" }}
          variant="solid"
          ml="50px"
          onClick={() => {
            window.history.back();
          }}
        >
          Go Back
        </Button>
      )}
      <Box margin="50px">
        {serviceData && (
          <form onSubmit={handleFormSubmit}>
            <HStack justify={"space-between"}>
              <Heading>Edit Service</Heading>
              <Button type="submit" mt={4} colorScheme="orange" size={"lg"}>
                Save Changes
              </Button>
            </HStack>
            <Divider h="5px" />
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td width="15vw" fontWeight="bold">
                    Name
                  </Td>
                  <Td>
                    <FormControl>
                      <Input
                        type="text"
                        name="name"
                        value={serviceData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Description</Td>
                  <Td>
                    <FormControl>
                      <Textarea
                        name="description"
                        value={serviceData.description}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Cost (Range)</Td>
                  <Td>
                    <FormControl>
                      <Input
                        type="number"
                        name="cost"
                        value={serviceData.cost}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">
                    Documents Required (comma-separated)
                  </Td>
                  <Td>
                    <FormControl>
                      <Input
                        type="text"
                        name="documentsRequired"
                        value={serviceData.documentsRequired}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Service for New Incorporation</Td>
                  <Td>
                    <FormControl>
                      <Switch
                        name="new"
                        isChecked={serviceData.newIncService}
                        onChange={() => {
                          setServiceData((prevData) => ({
                            ...prevData,
                            newIncService: !prevData.newIncService,
                          }));
                        }}
                        size="lg"
                        borderColor="black"
                        variant="redThumb"
                      />
                    </FormControl>
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Service Type</Td>
                  <Td>
                    <Tabs variant="soft-rounded" colorScheme="yellow">
                      <TabList>
                        <Tab
                          onClick={() => {
                            setServiceData({
                              ...serviceData,
                              serviceType: "All",
                            });
                          }}
                          rounded="full"
                          // isSelected={serviceData.serviceType === "All"}
                        >
                          All
                        </Tab>
                        <Tab
                          onClick={() => {
                            setServiceData({
                              ...serviceData,
                              serviceType: "Corporate",
                            });
                          }}
                          rounded="full"
                          // isSelected={serviceData.serviceType === "Corporate"}/
                        >
                          Corporate
                        </Tab>
                        <Tab
                          onClick={() => {
                            setServiceData({
                              ...serviceData,
                              serviceType: "TradeName",
                            });
                          }}
                          rounded="full"
                          // isSelected={serviceData.serviceType === "TradeName"}
                        >
                          TradeName
                        </Tab>
                        <Tab
                          onClick={() => {
                            setServiceData({
                              ...serviceData,
                              serviceType: "Partnership",
                            });
                          }}
                          rounded="full"
                          // isSelected={serviceData.serviceType === "Partnership"}
                        >
                          Partnership
                        </Tab>
                        <Tab
                          onClick={() => {
                            setServiceData({
                              ...serviceData,
                              serviceType: "New",
                            });
                          }}
                          rounded="full"
                          // isSelected={serviceData.serviceType === "New"}
                        >
                          New/First Time
                        </Tab>
                      </TabList>
                    </Tabs>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            {serviceData && serviceData.formsRequired && (
              <ServiceForms
                modify={true}
                service={serviceData}
                setService={setServiceData}
              />
            )}
            <Button type="submit" mt={4} colorScheme="orange" size={"lg"}>
              Save Changes
            </Button>
          </form>
        )}
      </Box>
    </>
  );
};

export default EditService;
