import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import LoginPage from "./Login";
import NavBar from "../utils/NavBar";
import ManageEmployeeAccount from "./ManageAccount";
import AdminHome from "./admin/AdminHome";
import Reminders from "./Reminders";

const EmployeeHome = () => {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/admin/*" element={<AdminHome />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/reminders" element={<Reminders />} />
        <Route path="/manage-my-account" element={<ManageEmployeeAccount />} />

        {/* <Route path="/" element={< />} />
    <Route path="/" element={< />} /> */}
      </Routes>
    </>
  );
};

export default EmployeeHome;
