import React from "react";
import {
  Box,
  Flex,
  Heading,
  VStack,
  Button,
  Divider,
  Text,
  Table,
  Th,
  Thead,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  CheckIcon,
  SmallCloseIcon,
  CalendarIcon,
} from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../utils/NavBar.js";
import BStrucRowTitle from "../utils/BStrucRow.js";
import Disclaimer from "../../utils/Disclaimer.js";

const BusinessStructure = () => {
  const navigate = useNavigate()

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" m="15px">
        <VStack align="start" className="container" spacing="4">
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/main/business");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading as="h1">Register a New Business</Heading>

          <Heading as="h2">Compare Your Options</Heading>

          <Text>
            Different type of entities have different legal and tax benefits for entrepreneurs.
          </Text>

          <Box overflowX="scroll" width="100%">
            <Table className="business-types-matrix">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th minWidth="200px">
                    <VStack>
                      <CalendarIcon boxSize="8" />
                      <Text fontSize="12px !important">Incorporation</Text>
                    </VStack>
                  </Th>

                  <Th minWidth="200px">
                    <VStack>
                      <CalendarIcon boxSize="8" />
                      <Text fontSize="12px !important">Partnership</Text>
                    </VStack>
                  </Th>

                  <Th minWidth="200px">
                    <VStack>
                      <CalendarIcon boxSize="8" />
                      <Text fontSize="12px !important">
                        Sole Proprietorship /<br /> Trade Name
                      </Text>
                    </VStack>
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {/* First: Limited Personal Liability */}
                <Tr>
                  <Td>
                    <BStrucRowTitle
                      title="Limited personal liability"
                      info="Incorporation enables you to protect yourself against personal liability for your business activities."
                    />
                  </Td>
                  <Td>
                    <CheckIcon color="green" boxSize="6" />
                  </Td>
                  <Td>
                    <SmallCloseIcon />
                  </Td>
                  <Td>
                    <SmallCloseIcon />
                  </Td>
                </Tr>

                {/* Second: Operate under a business name */}
                <Tr>
                  <Td>
                    <BStrucRowTitle
                      title="Operate under a business name"
                      info="Operate under a specific name for customers to remember who you are."
                    />
                  </Td>
                  <Td>
                    <CheckIcon color="green" boxSize="6" />
                  </Td>
                  <Td>
                    <CheckIcon color="green" boxSize="6" />
                  </Td>
                  <Td>
                    <CheckIcon color="green" boxSize="6" />
                  </Td>
                </Tr>

                {/* Third:  */}
                {/* <Tr>
                <Td>
                  <BStrucRowTitle
                    title="Want to have a personal gurantee?"
                    info="Apart from owners need to have a personal gurantee, which is _define."
                  />
                </Td>
                <Td>
                  <CheckIcon color="green" boxSize="6" />
                </Td>
                <Td>
                  <SmallCloseIcon />
                </Td>
                <Td>
                  <SmallCloseIcon />
                </Td>
              </Tr> */}

                {/* Fourth:   */}
                <Tr>
                  <Td>
                    <BStrucRowTitle
                      title="Protect your business name / have a unique name"
                      info="Only you can register a specific corporate name. Deter future business owners from using the same business name."
                    />
                  </Td>
                  <Td>
                    <CheckIcon color="green" boxSize="6" />
                  </Td>
                  <Td>
                    <SmallCloseIcon />
                  </Td>
                  <Td>
                    <SmallCloseIcon />
                  </Td>
                </Tr>

                {/* Fifth:  */}
                <Tr>
                  <Td>
                    <BStrucRowTitle title="Have multiple owners" />
                  </Td>
                  <Td>
                    <CheckIcon color="green" boxSize="6" />
                  </Td>
                  <Td>
                    <CheckIcon color="green" boxSize="6" />
                  </Td>
                  <Td>
                    <SmallCloseIcon />
                  </Td>
                </Tr>

                {/* Sixth: Lower corporate tax rate */}

                {/* {showMoreRows && ( */}
                  <>
                    {/* Hidden rows/ to be shown when view button is clicked */}
                    {/* <Tr>
                    <Td>
                      <BStrucRowTitle
                        title="Business Name Protection"
                        info="Deter future business owners from using the same business name."
                      />
                    </Td>
                    <Td>
                      <CheckIcon color="green" boxSize="6" />
                    </Td>
                    <Td>
                      <SmallCloseIcon />
                    </Td>
                    <Td>
                      <SmallCloseIcon />
                    </Td>
                  </Tr> */}
                    {/* <Tr>
                    <Td>
                      <BStrucRowTitle
                        title="Tax Structure"
                        info="Corporations must file a separate tax return."
                      />
                    </Td>
                    <Td>
                      <CheckIcon color="green" boxSize="6" />
                    </Td>
                    <Td>
                      <CheckIcon color="green" boxSize="6" />
                    </Td>
                    <Td>
                      <CheckIcon color="green" boxSize="6" />
                    </Td>
                  </Tr> */}
                    <Tr>
                      <Td>
                        <BStrucRowTitle
                          title="Obtain GST/HST number"
                          info="Ability to collect tax for goods and services sold."
                        />
                      </Td>
                      <Td>
                        <CheckIcon color="green" boxSize="6" />
                      </Td>
                      <Td>
                        <CheckIcon color="green" boxSize="6" />
                      </Td>
                      <Td>
                        <CheckIcon color="green" boxSize="6" />
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <BStrucRowTitle
                          title="Hire employees"
                          info="Add additional members to your staff."
                        />
                      </Td>
                      <Td>
                        <CheckIcon color="green" boxSize="6" />
                      </Td>
                      <Td>
                        <CheckIcon color="green" boxSize="6" />
                      </Td>
                      <Td>
                        <CheckIcon color="green" boxSize="6" />
                      </Td>
                    </Tr>
                    {/* <Tr>
                    <Td>
                      <BStrucRowTitle
                        title="Raise money & qualify for popular tax and grant programs"
                        info="Sometimes. Investors and government programs are applicable to corporations only."
                      />
                    </Td>
                    <Td>
                      <CheckIcon color="green" boxSize="6" />
                    </Td>
                    <Td>
                      <SmallCloseIcon />
                    </Td>
                    <Td>
                      <SmallCloseIcon />
                    </Td>
                  </Tr> */}
                    <Tr>
                      <Td>
                        <BStrucRowTitle
                          title="Open a business bank account"
                          info="Keep your business and personal finances separate."
                        />
                      </Td>
                      <Td>
                        <CheckIcon color="green" boxSize="6" />
                      </Td>
                      <Td>
                        <CheckIcon color="green" boxSize="6" />
                      </Td>
                      <Td>
                        <CheckIcon color="green" boxSize="6" />
                      </Td>
                    </Tr>
                  </>
                {/* )} */}

                {/* Final - Selection row */}
                <Tr>
                  <Td>
                    {/* <Button
                      colorScheme="orange"
                      onClick={() => {
                        setShowMoreRows(!showMoreRows);
                      }}
                    >
                      View {!showMoreRows ? "More" : "Less"}
                    </Button> */}
                  </Td>
                  <Td>
                    <Button
                      w="100%"
                      colorScheme="blue"
                      onClick={() => {
                        // setStructureType("Incorporation");
                        navigate("/main/corp-type");
                      }}
                    >
                      Select
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      w="100%"
                      colorScheme="blue"
                      onClick={() => {
                        // setStructureType("Partnership");
                        navigate("/main/new/partnership");
                      }}
                    >
                      Select
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      w="100%"
                      colorScheme="blue"
                      onClick={() => {
                        // setStructureType("Sole Properitorship");
                        navigate("/main/new/trade-name");
                      }}
                    >
                      Select
                    </Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
{/* 
          <Divider height="5vh" />

          <Heading>Having trouble with deciding?</Heading>

          <Text>
            If you are having trouble with figuring out what corporate structure to choose, try answering our questions and we can help you choose a structure based upon your answer, or contact us directly at:
            <br /><b>support@snappycorporation.com</b>
          </Text>

          <Button
            className="btn-black"
            onClick={() => {
              navigate("/decide");
            }}
          >
            Let's get Started
          </Button> */}
        </VStack>
      </Flex>

      <Disclaimer />
    </>
  );
};

export default BusinessStructure;
