import React from "react";
import {
  Grid,
  GridItem,
  Center,
  Flex,
  Heading,
  Button,
  Box,
  HStack,
  Image,
  Text,
  ListItem,
  ListIcon,
  List,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@chakra-ui/icons";

import StartPageCard from "../../utils/StartPCard";
import BannerLine from "../../utils/BannerLine.js";
import NavBar from "../../utils/NavBar.js";

import {  mainServicesData2 } from "./Services.js";
const ManageBusiness = () => {
  const navigate = useNavigate();


  return (
    <>
      <NavBar />
      {/* Amendments */}
      <div className="home-main">
        <Flex spacing="100px" justifyContent="center" paddingBottom="50px">
          <HStack className="container">
            <Box w="50%" paddingRight="50px">
              <Center>
                <Image src="/images/buildings.png" maxH="250px" />
              </Center>
            </Box>
            <Box w="50%">
              <Heading as="h2" fontSize="3xl">
                Annual Return
              </Heading>
              <Text> It helps to keep your business alive. </Text>
              <List mb={7}>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  For Corporation
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="blue.500" />
                  For Limited Liability Partnership
                </ListItem>
              </List>
              <Button
                className="btn-black"
                onClick={() => {
                  navigate("/annualReturn");
                }}
              >
                Let's get started !
              </Button>
            </Box>
          </HStack>
        </Flex>

        <BannerLine
          line="Let us be your corporation's agent for Service."
          linkText="Know more!"
        />
        {/* other services */}
        <Heading align="center">Other Common Services</Heading>
        <Center>
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={6}
            p={6}
          >
            {mainServicesData2.map((card, index) => (
              <GridItem key={index} colSpan={{ base: 1, md: 1, lg: 1 }}>
                <StartPageCard {...card} />
              </GridItem>
            ))}
          </Grid>
        </Center>
      </div>
    </>
  );
};

export default ManageBusiness;
