import React from "react";
import Banner from "./Banner";
import AboutUs from "./AboutUs";
import MainServices from "./MainServices";

const Home = () => {
  return (
    <>
      <Banner />
      <MainServices />
      <AboutUs />
    </>
  );
};

export default Home;
