import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Stack,
  VStack,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Link,
} from "@chakra-ui/react";

const MaintenanceReminder = ({ setValue, enabled }) => {
  const [yesAnnualMaintenance, setYesAnnualMaintenance] = useState(false);
  const [noAnnualMaintenance, setNoAnnualMaintenance] = useState(false);

  const handleYesDecideClick = () => {
    setValue("Yes");
    setYesAnnualMaintenance(true);
    setNoAnnualMaintenance(false);
  };

  const handleNoDecideClick = () => {
    setValue("No");
    setYesAnnualMaintenance(false);
    setNoAnnualMaintenance(true);
  };

  return (
    <div className="maintenanceReminder">
      {/* 
            link="/help/annualSubscription"
            
            */}
      <Box
        w="100%"
        p={4}
        border="1px solid rgba(0,0,0,0.15)"
        borderRadius={8}
        boxShadow="3px 3px 8px rgba(0,0,0,0.25)"
        mt="12px"
      >
        <Stack spacing={4}>
          <FormControl>
            <Grid
              templateColumns={{ md: "repeat(5, 1fr)", sm: "repeat(1, 1fr)" }}
              gap={4}
              alignItems="center"
            >
              <GridItem colSpan={{ md: 4, sm: 1 }} alignItems="center">
                <VStack align="left" pr="24px">
                  <Text
                    color="#666"
                    fontWeight="bold"
                    fontSize="20px !important"
                  >
                    Sign Up for Snappy Subscriptions!
                  </Text>
                  <Text>
                  If you sign up for Snappy Subscriptions we maintain your annual returns on a yearly basis for you. We would also keep your corporation up-to-date, change shareholders, directors, and agent for service.
                  <br />
                    <Link
                      color="#03a9f4" // Start with blue color
                      transition="color 0.5s ease-out"
                      _hover={{ textDecoration: "none" }}
                      // Assuming 'link' is a URL and you want to open it in a new tab
                      href={"/help/annualSubscription"}
                      isExternal={true}
                    >
                      
                      {" "}Learn More!
                    </Link>
                  </Text>
                  <Text color={"green.500"}>
                    All covered with an annual payment for{" "}
                    <span className="price">+$250 CAD</span>.
                  </Text>
                </VStack>
              </GridItem>
              <GridItem colSpan={{ md: 1, sm: 1 }} alignItems="center">
                <HStack spacing="2" justifyContent="end" width="100%">
                  <Button
                    colorScheme={
                      yesAnnualMaintenance || enabled === true ? "teal" : "gray"
                    }
                    variant={
                      yesAnnualMaintenance || enabled === true
                        ? "solid"
                        : "outline"
                    }
                    borderColor={"black"}
                    _hover={{ bg: "gray.400", color: "black" }}
                    onClick={handleYesDecideClick}
                  >
                    Yes
                  </Button>
                  <Button
                    colorScheme={
                      noAnnualMaintenance || enabled === false ? "teal" : "gray"
                    }
                    variant={
                      noAnnualMaintenance || enabled === false
                        ? "solid"
                        : "outline"
                    }
                    borderColor={"black"}
                    _hover={{ bg: "gray.400", color: "black" }}
                    onClick={handleNoDecideClick}
                  >
                    No
                  </Button>
                </HStack>
              </GridItem>
            </Grid>

            {/* <Stack direction="row" align="center" justify="space-between">
              <VStack align="left" pr="24px">
                <Heading as="h4">
                  Want to sign up for annual maintenance?
                </Heading>
                <Text>
                  Sign up for Snappy Subscriptions to keep your corporation up-to-date, change shareholders, directors, and agent for service.
                </Text>
                <Text color={"green.500"}>
                  All covered with an annual payment for{" "}
                  <span className="price">+$250 CAD</span>.
                </Text>
              </VStack>

              <Stack direction="row" spacing={2}>
                <Button
                  colorScheme={
                    yesAnnualMaintenance || enabled === true ? "teal" : "gray"
                  }
                  variant={
                    yesAnnualMaintenance || enabled === true
                      ? "solid"
                      : "outline"
                  }
                  borderColor={"black"}
                  _hover={{ bg: "gray.400", color: "black" }}
                  onClick={handleYesDecideClick}
                >
                  Yes
                </Button>
                <Button
                  colorScheme={
                    noAnnualMaintenance || enabled === false ? "teal" : "gray"
                  }
                  variant={
                    noAnnualMaintenance || enabled === false
                      ? "solid"
                      : "outline"
                  }
                  borderColor={"black"}
                  _hover={{ bg: "gray.400", color: "black" }}
                  onClick={handleNoDecideClick}
                >
                  No
                </Button>
              </Stack>
              
            </Stack> */}
          </FormControl>
        </Stack>
      </Box>

      {(noAnnualMaintenance || enabled === false) && (
        <Text mt="24px !important" fontWeight="bold" color="red">
          It is recommended to sign up for annual maintainence if you plan to
          make changes.
        </Text>
      )}
      {(yesAnnualMaintenance || enabled === true) && (
        <Text mt="24px !important" fontWeight="bold" color="green.500">
          We got you all set right there!
        </Text>
      )}
    </div>
  );
};

export default MaintenanceReminder;
