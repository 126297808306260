import axios from "axios";

const uri = `${process.env.REACT_APP_BACKEND_KEY}/email`;
export const handleSendInquiryEmail = async (emailData) => {
  console.log(emailData)
  try {
    const response = await axios.post(`${uri}/contact`, { emailData });
    console.log(response);
    return response.status === 200
  } catch (error) {
    return false;
  }
};
export const handleRequestROEmail = async (emailData) => {
  const { name, address } = emailData;

  try {
    const response = await axios.post(`${uri}/registeredOffice`, { name, address });
    console.log(response);
    return response.status === 200
  } catch (error) {
    return false;
  }
};

export const handleAnnualSubscriptionEmail = async (emailData) => {
  const { name, address } = emailData;

  try {
    const response = await axios.post(`${uri}/annualSubscription`, { name, address });
    console.log(response);
    return response.status === 200
  } catch (error) {
    return false;
  }
};
