// store.js
import { createStore, combineReducers, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import cartReducer from "./reducers/cartReducer";
import empReducer from "./reducers/auth";
import buyNowReducer from "./reducers/buyNow";

const rootReducer = combineReducers({
  cart: cartReducer,
  employee: empReducer,
  buyNow: buyNowReducer,
  // Add other reducers as needed
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
