import React from "react";

import {
  Heading,
  Input,
  FormControl,
  FormLabel,
  Grid,
  GridItem
} from "@chakra-ui/react";

import MaintenanceReminder from "../../../../utils/MaintenanceReminder";

const AccountInformation = ({ request, setRequest }) => {
  
  const handleChangeAccountAddress = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      accountInfo: {
        ...prevRequest.accountInfo,
        [field]: value,
      },
    }));
  };

  const handleAnnualMgmtChange = (value, priceType) => {
    let bool = value ? true : false;

    setRequest((prevReq) => ({ ...prevReq, annualMgmt: bool }));

    handlePaymentChange(value, priceType);
    request.updatePrice();
  };

  const handlePaymentChange = (value, priceType) => {
    setRequest((prevRequest) => {
      let bool = value === 'Yes' ? true : false;
      let updatedPrices = request.prices;

      updatedPrices[priceType].enabled = bool;

      return {
        ...prevRequest,
        prices: updatedPrices
      };
    });
  };
  
  return (
    <>
      <Heading mb="15px">Personal Information</Heading>
      
      <FormControl>
        <Grid
          templateColumns={{ base: '1fr', md: 'auto 1fr' }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <FormLabel pr="20">
              My first name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.firstName}
              onChange={(e) => handleChangeAccountAddress("firstName", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My last name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.lastName}
              onChange={(e) => handleChangeAccountAddress("lastName", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My phone number is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.phone}
              onChange={(e) => handleChangeAccountAddress("phone", e.target.value)}
            />
          </GridItem>
        </Grid>
      </FormControl> 
    
      {/* <HStack>
        <FormLabel w="max-content">My email is</FormLabel>
        <Input
          placeholder="Email"
          value={request.accountInfo.email}
          onChange={(e) => handleChangeAccountAddress("email", e.target.value)}
          borderBottom="solid 1px black"
          mb={6}
          w="40ch"
        />
      </HStack> */}
      {/* Address */}
      {/* 
      <FormLabel w="max-content">My address is</FormLabel>
      <Table variant="striped" borderRadius="md">
        <Tbody>
          <Tr>
            <Td>
              <Input
                variant="outline"
                borderColor="orange"
                borderRadius="md"
                placeholder="Street Line 1"
                value={request.accountInfo.streetLine1}
                onChange={(e) =>
                  handleChangeAccountAddress("streetLine1", e.target.value)
                }
              />
              {request.accountInfo.streetLine1 && (
                <label
                  style={{
                    fontSize: "12px",
                    marginTop: "-10px",
                    backgroundColor: "white",
                  }}
                  htmlFor="streetLine1"
                >
                  Street Line 1
                </label>
              )}
            </Td>
            <Td>
              <Input
                variant="outline"
                borderColor="orange"
                borderRadius="md"
                placeholder="Street Line 2"
                value={request.accountInfo.streetLine2}
                onChange={(e) =>
                  handleChangeAccountAddress("streetLine2", e.target.value)
                }
              />
              {request.accountInfo.streetLine2 && (
                <label
                  style={{
                    fontSize: "12px",
                    marginTop: "-10px",
                    backgroundColor: "white",
                  }}
                  htmlFor="streetLine2"
                >
                  Street Line 2
                </label>
              )}
            </Td>
            <Td>
              <Input
                variant="outline"
                borderColor="orange"
                borderRadius="md"
                placeholder="City"
                value={request.accountInfo.city}
                onChange={(e) =>
                  handleChangeAccountAddress("city", e.target.value)
                }
              />
              {request.accountInfo.city && (
                <label
                  style={{
                    fontSize: "12px",
                    marginTop: "-10px",
                    backgroundColor: "white",
                  }}
                  htmlFor="city"
                >
                  City
                </label>
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Input
                variant="outline"
                borderColor="orange"
                borderRadius="md"
                placeholder="Province"
                value={request.accountInfo.province}
                onChange={(e) =>
                  handleChangeAccountAddress("province", e.target.value)
                }
              />
              {request.accountInfo.province && (
                <label
                  style={{
                    fontSize: "12px",
                    marginTop: "-10px",
                    backgroundColor: "white",
                  }}
                  htmlFor="province"
                >
                  Province
                </label>
              )}
            </Td>
            <Td>
              <Input
                variant="outline"
                borderColor="orange"
                borderRadius="md"
                placeholder="Country"
                value={request.accountInfo.country}
                onChange={(e) =>
                  handleChangeAccountAddress("country", e.target.value)
                }
              />
              {request.accountInfo.country && (
                <label
                  style={{
                    fontSize: "12px",
                    marginTop: "-10px",
                    backgroundColor: "white",
                  }}
                  htmlFor="country"
                >
                  Country
                </label>
              )}
            </Td>
            <Td>
              <Input
                variant="outline"
                borderColor="orange"
                borderRadius="md"
                placeholder="Postal Code"
                value={request.accountInfo.postalCode}
                onChange={(e) =>
                  handleChangeAccountAddress("postalCode", e.target.value)
                }
              />
              {request.accountInfo.postalCode && (
                <label
                  style={{
                    fontSize: "12px",
                    marginTop: "-10px",
                    backgroundColor: "white",
                  }}
                  htmlFor="postalCode"
                >
                  Postal Code
                </label>
              )}
            </Td>
          </Tr>
        </Tbody>
      </Table> */}

      <br />
      
      <MaintenanceReminder
        setValue={(value) => handleAnnualMgmtChange(value, 'SubscribeAnnualMaintenance')}
        enabled={request.prices.SubscribeAnnualMaintenance.enabled}
      />
    </>
  );
};

export default AccountInformation;
