import axios from "axios";

const uri = `${process.env.REACT_APP_BACKEND_KEY}/reminders`;
const getAllReminders = async () => {
  try {
    const response = await axios.get(`${uri}/fetch`, {});
    return response;
  } catch (error) {
    return error.response;
  }
};

const updateReminderStatus = async (_id) => {
  try {
    const response = await axios.put(`${uri}/crud`, {
      _id,
      status: "Completed",
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export { getAllReminders, updateReminderStatus };
