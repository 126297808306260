import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Input,
  Stack,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  IconButton,
  Center,
  useToast,
  Text,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import EmpBar from "./EmpBar";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../services/admin/users";

const ManageEmployeeAccount = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const currentUser = useSelector((state) => state.employee).state;
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: currentUser.name,
    password: currentUser.password,
  });

  useEffect(() => {
    if (!currentUser || !currentUser.name) navigate("/employee");
  }, [currentUser]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleUpdateAccount = async () => {
    try {
      const response = await updateUser(formData);
      if (response.status === 200) {
        toast({
          title: "User Updated",
          description: "User has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (response.status === 409) {
        toast({
          title: "User Already Exists",
          description: "User with same name already exists.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to update user.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update user.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    // Dispatch action to update user account with formData
    // For example: dispatch(updateAccount(formData));
  };

  const handleDeactivateAccount = () => {
    // Dispatch action to deactivate user account
    // For example: dispatch(deactivateAccount());
  };

  return (
    <>
      <EmpBar />
      <Center p="4">
        <Stack spacing={4} w="500px">

          <FormControl>
            <FormLabel>Username: {currentUser.username} </FormLabel>
            <Text>To update username contact your manager/admin</Text>

          </FormControl>


          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <InputRightElement>
                <IconButton
                  icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                  onClick={handlePasswordVisibility}
                  variant="ghost"
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <Button colorScheme="blue" onClick={handleUpdateAccount}>
            Update Account
          </Button>

          <Button colorScheme="red" onClick={handleDeactivateAccount}>
            Deactivate Account
          </Button>
        </Stack>
      </Center>
    </>
  );
};

export default ManageEmployeeAccount;
