// cartReducer.js
const initialState = {
  cart: [],
};
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      // handle adding to the cart
      return {
        ...state,
        cart: [...state.cart, action.payload],
      };

    case "REMOVE_FROM_CART":
      // handle removing from the cart
      return {
        ...state,
        cart: state.cart.filter((item) => item._id !== action.payload),
      };

    case "UPDATE_QUANTITY":
      const { id, quantity } = action.payload;
      return {...state,
        cart: state.cart.map((item) =>
        item._id === id ? { ...item, quantity } : item
      )}

    //     ...state,
    //     cart: state.cart.map(item =>
    //       item._id === action.payload._id
    //         ? { ...item, quantity: action.payload.quantity }
    //         : item
    //     ),
    //   };

    // other cases...

    default:
      return state;
  }
};

export default cartReducer;
