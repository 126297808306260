import axios from "axios";

const uri = `${process.env.REACT_APP_BACKEND_KEY}/users`;

export const getAllUsers = async () => {
  try {
    const response =  await axios.get(`${uri}/fetch`, {});
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getUser = async (_id) => {
  try {
    const response =  await axios.post(`${uri}/fetch`, {_id});
    return response;
  } catch (error) {
    return error.response;
  }
};
export const createUser = async (user) => {
  try {
    const response =  await axios.post(`${uri}/crud`, {user});
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateUser = async (user) => {
  try {
    console.log(user)
    const response =  await axios.put(`${uri}/crud`, {user});
    return response;
  } catch (error) {
    console.log(error.response.data)
    return error.response;
  }
};
export const deactivateUser = async (_id) => {
  try {
    const response=  await axios.delete(`${uri}/crud`, {data:{_id}});
    return response;
  } catch (error) {
    return error.response;
  }
};


