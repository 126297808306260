import React, { useState } from "react";
import {
  Heading,
  Text,
  Box,
  Flex,
  FormControl,
  FormLabel,
  VStack,
  Input,
  IconButton,
  HStack,
  Divider,
  ListItem,
  List,
  Modal,
  Select,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Switch,
  GridItem,
  Grid,
  Center,
  RadioGroup,
  Radio
} from "@chakra-ui/react";

import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import InfoButton from "../../../../utils/Popover";
import YesNoField from "../../../../utils/YesNoField";
import { snappyCorpPriceList } from "../../../../services/price";
import Province from "../../../../utils/Province";

const PartnersInfo = ({ request, setRequest, companyType }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [modalPartnerType, setModalPartnerType] = useState('individual');

  // const [managers, setManagers] = useState([{ ...request.accountInfo }]);
  const [formData, setFormData] = useState({
    key: Date.now(),
    lastName: "",
    firstName: "",
    corpName: "",
    corpAccessNumber: "",
    occupation: "",
    email: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    postalCode: "",
    country: "Canada",
    province: "AB",
    isLimitedPartner: false,
  });

  const handleManagerAdd = () => {
    setFormData({
      key: Date.now(),
      lastName: "",
      firstName: "",
      corpName: "",
      corpAccessNumber: "",
      occupation: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "AB",
      isLimitedPartner: false,
    });
    setIsEditable(false);
    setShowModal(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditPartner = (partner) => {
    setFormData({ ...partner });
    setIsEditable(true);
    setShowModal(true);
  };

  const handleDeletePartner = (key) => {
    if (window.confirm("Are you sure you want to delete this partner?")) {
      const updatedPartners = request.partnerInfo.partners.filter(
        (partner) => partner.key !== key
      );

      setRequest((prevRequest) => ({
        ...prevRequest,
        partnerInfo: {
          ...prevRequest.partnerInfo,
          partners: updatedPartners
        }
      }));

      setFormData({
        key: Date.now(),
        lastName: "",
        firstName: "",
        corpName: "",
        corpAccessNumber: "",
        occupation: "",
        email: "",
        streetLine1: "",
        streetLine2: "",
        city: "",
        postalCode: "",
        country: "Canada",
        province: "AB",
        isLimitedPartner: false,
      });
    }
  };

  // NOTE: this code is exactly the same as in PartnersType
  const handlePartnershipTypeChange = (e) => {
    let type = e.target.value;
    let defaultPrice = 0;

    if (type === 'General'){
      defaultPrice = snappyCorpPriceList.GeneralPartnership;

      setRequest((prevReq) => ({
        ...prevReq,
        reviewFieldHidden: {
          ...prevReq.reviewFieldHidden,
          partnership: {
            ...prevReq.reviewFieldHidden.partnership,
            businessInfo: {
              ...prevReq.reviewFieldHidden.partnership.businessInfo,
              homeJurisdiction: false,
              homeJurisdiction_RegNo: false,
              homeJurisdiction_BusNo: false,
              streetLine1: false,
              streetLine2: false,
              city: false,
              province: false,
              country: false,
              postalCode: false
            }
          }
        }
      }));

    } else if (type === 'LLP'){
      defaultPrice = snappyCorpPriceList.LimitedLiabilityPartnership;

      setRequest((prevReq) => ({
        ...prevReq,
        reviewFieldHidden: {
          ...prevReq.reviewFieldHidden,
          partnership: {
            ...prevReq.reviewFieldHidden.partnership,
            businessInfo: {
              ...prevReq.reviewFieldHidden.partnership.businessInfo,
              homeJurisdiction: undefined,
              homeJurisdiction_RegNo: undefined,
              homeJurisdiction_BusNo: undefined,
              streetLine1: undefined,
              streetLine2: undefined,
              city: undefined,
              province: undefined,
              country: undefined,
              postalCode: undefined
            }
          }
        }
      }));
    } else if (type === 'LP'){
      defaultPrice = snappyCorpPriceList.LimitedPartnership;
    } else {
      defaultPrice = 0;
    }

    setRequest((prevReq) => ({
      ...prevReq,
      price: defaultPrice,
      prices: {
        ...prevReq.prices,
        default: defaultPrice
      },
      partnerInfo: {
        ...prevReq.partnerInfo,
        partnershipType: type
      }
    }));

  };

  const handleChangePartnerType = (value) => {
    if (value == 'corporation'){
      setFormData((prevData) => ({
        ...prevData,
        firstName: "",
        lastName: "",
        occupation: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        corpName: "",
        corpAccessNumber: "",
      }));
    }
    
    setModalPartnerType(value);
  }

  const handleSubmit = () => {
    // Check if all required fields are filled
    let requiredFields = [
      "email",
      "streetLine1",
      "city",
      "postalCode",
      "province",
      "country",
    ];

    if (modalPartnerType == 'corporation'){
      requiredFields.push('corpName');
      requiredFields.push('corpAccessNumber');
    } else {
      requiredFields.push('firstName');
      requiredFields.push('lastName');
      requiredFields.push('occupation');
    }

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      alert("Please fill all required fields.");
      return;
    }

    setRequest((prevRequest) => {
      const updatedPartners = isEditable
        ? prevRequest.partnerInfo.partners.map((partner) =>
          partner.key === formData.key ? { ...partner, ...formData } : partner
          )
        : [...prevRequest.partnerInfo.partners, formData];

      return {
        ...prevRequest,
        partnerInfo: {
          ...prevRequest.partnerInfo,
          partners: updatedPartners
        }
      };
    });

    setShowModal(false);
    setIsEditable(false);

    setFormData({
      key: Date.now(),
      lastName: "",
      firstName: "",
      corpName: "",
      corpAccessNumber: "",
      occupation: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "AB",
      isLimitedPartner: false,
    });
  };

  const [expiringPartnership, setExpiringPartnership] = useState("");
  
  const handleYesNoChange = (value, type) => {
    request.toggleYesNo(value, type);

    if (value === 'Yes'){
      setExpiringPartnership(true);
      setRequest((prevRequest) => ({
        ...prevRequest,
        partnerInfo: {
          ...prevRequest.partnerInfo,
          partnershipExpiryDate: ""
        },
        reviewFieldReadOnly: {
          ...prevRequest.reviewFieldReadOnly,
          partnership: {
            ...prevRequest.reviewFieldReadOnly.partnership,
            partnerInfo: {
              ...prevRequest.reviewFieldReadOnly.partnership.partnerInfo,
              partnershipExpiryDate: undefined
            }
          }
        }
      }));
    } else {
      setExpiringPartnership(false);
      setRequest((prevRequest) => ({
        ...prevRequest,
        partnerInfo: {
          ...prevRequest.partnerInfo,
          partnershipExpiryDate: "Never",  
        },
        reviewFieldReadOnly: {
          ...prevRequest.reviewFieldReadOnly,
          partnership: {
            ...prevRequest.reviewFieldReadOnly.partnership,
            partnerInfo: {
              ...prevRequest.reviewFieldReadOnly.partnership.partnerInfo,
              partnershipExpiryDate: false
            }
          }
        }
      }));
    }
  };

  // const handleSwitchChange = (name) => (e) => {
  //   const { checked } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: checked,
  //   }));
  // };

  const enabled = request.yesno.PartnershipExpiry.enabled;

  const handleClose = () => {
    setIsEditable(false);
    setShowModal(false);
  }

  return (
    <>
      <Box>
        <Flex justify="space-between" align="center">
          <Heading mb="24px !important">Partners' Information</Heading>
        </Flex>

        <VStack align="start" mb="24px">
          <Heading as="h3">Partnership type</Heading>
          <HStack>
            <Text w="max-content" mb="0 !important">
              Type of partnership is
            </Text>
            <Select
              fontWeight="bold"
              border="solid 1px black"
              // onChange={(e) => {
              //   setRequest((prevReq) => ({
              //     ...prevReq,
              //     partnershipType: e.target.value,
              //   }));
              // }}
              onChange={handlePartnershipTypeChange}
              w="20ch"
              defaultValue={request.partnerInfo.partnershipType}
            >
              <option>General</option>
              <option>LLP</option>
            </Select>
          </HStack>
        </VStack>

        <Box>
          <HStack justify={"space-between"} py="5">
            <Heading as="h4" color="blue.700" mb="0 !important">
              {companyType === "partnership" && request.partnerInfo.partnershipType == "LLP" ? "Current Representative Partners" : "Current Partners" }
            </Heading>
            <Button
              colorScheme="blue"
              leftIcon={<AddIcon />}
              onClick={handleManagerAdd}
              float={"right"}
            >
              Add
            </Button>
          </HStack>
          {companyType === "partnership" && request.partnerInfo.partnershipType == "LLP" && (
          <Text fontStyle="italic" mt="-32px !important">* maximum of 2</Text>
          )}
        </Box>

        {/* Partners Lists */}
        <Box>
          {request.partnerInfo.partners && request.partnerInfo.partners.length > 0 && (
            <Box>
              <List
                borderBottom="solid 1px gray"
                ml={{base:"0", md:"10%"}}
                w={{base:"100%", md:"60%"}}
                color="blue.500"
              >
                {request.partnerInfo.partners.map((partner, index) => (
                  <ListItem key={partner.key}>
                    <HStack justify="space-between">
                      <Text width="60%" fontWeight="bold" mb="0 !important">
                        {partner.firstName} {partner.lastName}
                        {partner.corpName} {partner.corpAccessNumber}
                      </Text>
                      <HStack>
                        <IconButton
                          icon={<EditIcon />}
                          onClick={() => handleEditPartner(partner)}
                        />
                        <IconButton
                            icon={<DeleteIcon />}
                            onClick={() =>
                              handleDeletePartner(partner.key)
                            }
                          />
                      </HStack>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          
          <Divider h="50px" />

          {request.partnerInfo.partnershipType == "General" && (
          <FormControl>
            <Grid
              templateColumns={{ base: '1fr', md: 'auto 1fr' }}
              gap={4}
              alignItems="center"
            >
              <GridItem>
                <FormLabel mb={{ base:"-6px !important", md:"6px !important" }}>
                  Date when partnership commences or came into effect
                </FormLabel>
              </GridItem>
              <GridItem>
                <Input
                  type="date"
                  placeholder="yyyy-mm-dd"
                  w={{ base: "100%", md: "30ch" }}
                  value={request.partnerInfo.partnershipCommencementDate}
                  onChange={(e) => {
                    setRequest((prevRequest) => ({
                      ...prevRequest,
                      partnerInfo: {
                        ...prevRequest.partnerInfo,
                        partnershipCommencementDate: e.target.value,
                      }
                    }));
                  }}
                />
              </GridItem>
            </Grid>
          </FormControl>
          )}
        </Box>

        {request.partnerInfo.partnershipType == "General" && (
        <>
        <br />

        <YesNoField
          label="Is there an expiration date?"
          about="If you want your partnership to automatically dissolve after a specific date."
          setValue={(value) => handleYesNoChange(value, 'PartnershipExpiry') }
          enabled={enabled}
        />

        <Box ml="25px" mt="24px">
          {(expiringPartnership === "Yes" || enabled === true) && (
          <FormControl>
            <Grid
              templateColumns={{ base: '1fr', md: 'auto 1fr' }}
              gap={4}
              alignItems="center"
            >
              <GridItem>
                <FormLabel mb={{ base:"-6px !important", md:"6px !important" }}>
                  Date when partnership expires
                </FormLabel>
              </GridItem>
              <GridItem>
                <Input
                  type="date"
                  placeholder="yyyy-mm-dd"
                  w={{ base: "100%", md: "30ch" }}
                  value={request.partnerInfo.partnershipExpiryDate}
                  onChange={(e) => {
                    setRequest((prevRequest) => ({
                      ...prevRequest,
                      partnerInfo: {
                        ...prevRequest.partnerInfo,
                        partnershipExpiryDate: e.target.value
                      }
                    }));
                  }}
                />
              </GridItem>
            </Grid>
          </FormControl>
          ) }{(expiringPartnership === "No" || enabled === false)&&  (
            <Text color="green.500"><b>Partnership will never expire!</b></Text>
          )}
        </Box>

        <Divider h="50px" />
        </>
        )}

        <Modal 
          isOpen={showModal}
          onClose={handleClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {isEditable ? "Edit Partner" : "Add Partner"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>

              {!isEditable && (
              <Center>
                <RadioGroup
                  onChange={handleChangePartnerType}
                  value={modalPartnerType}
                  mb="24px"
                >
                  <HStack>
                    <Radio value='individual'>Individual</Radio>
                    <Radio value='corporation'>Corporation</Radio>
                  </HStack>
                </RadioGroup>
              </Center>
              )}
              
              <FormControl isRequired>

              {((!isEditable && modalPartnerType === 'individual') || formData.firstName !== "") && (
                <>
                  <FormLabel>First Name:</FormLabel>
                  <Input
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleFormChange}
                    mb="4"
                  />
                  <FormLabel>Last Name:</FormLabel>
                  <Input
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleFormChange}
                    mb="4"
                  />
                </>
              )}

              {((!isEditable && modalPartnerType === 'corporation') || formData.corpName !== "") && (
              <>
                <FormLabel>Corporation Name:</FormLabel>
                <Input
                  name="corpName"
                  value={formData.corpName}
                  onChange={handleFormChange}
                  mb="4"

                />
                <FormLabel>Corporate Access Number:</FormLabel>
                <Input
                  name="corpAccessNumber"
                  value={formData.corpAccessNumber}
                  onChange={handleFormChange}
                  mb="4"
                />
              </>
              )}

              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                mb="4"
              />

              {((!isEditable && modalPartnerType === 'individual') || formData.firstName !== "") && (
                <>
                  <FormLabel>Occupation</FormLabel>
                  <Input
                    name="occupation"
                    value={formData.occupation}
                    onChange={handleFormChange}
                    mb="4"
                  />
                </>
              )}
                
                <Text fontWeight="bold">Residential Address:</Text>
                {formData && (
                  <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                    <GridItem colSpan={[2, 1]}>
                      <FormControl isRequired>
                        <FormLabel>Address Line 1</FormLabel>
                        <Input                      
                          name="streetLine1"
                          value={formData.streetLine1}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={[2, 1]}>
                      <FormControl>
                        <FormLabel>Address Line 2</FormLabel>
                        <Input
                          name="streetLine2"
                          value={formData.streetLine2}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={[2, 1]}>
                      <FormControl isRequired>
                        <FormLabel>City</FormLabel>
                        <Input
                          name="city"
                          value={formData.city}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={[2, 1]}>
                      <FormControl isRequired>
                        <FormLabel>Postal Code</FormLabel>
                        <Input
                          name="postalCode"
                          value={formData.postalCode}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={[2, 1]}>
                      <FormControl isRequired>
                        <FormLabel>Province</FormLabel>
                        <Province
                          width="auto"
                          name="province"
                          value={formData.province}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={[2, 1]}>
                      <FormControl isRequired>
                        <FormLabel>Country</FormLabel>
                        <Input
                          name="country"
                          value={formData.country}
                          onChange={handleFormChange}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                )}
                {/* 
                {request.partnerInfo.partnershipType === "LLP" && (
                  <>
                    <br />
                    <Flex width="100%">
                      <HStack justifyContent="space-between" width="100%">
                        <FormLabel>Add as a Representative Partner:</FormLabel>
                        <InfoButton info={"Maximum 2 allowed for a LLP"} />
                        <Switch
                          name="isLimitedPartner"
                          isChecked={formData.isLimitedPartner}
                          onChange={handleSwitchChange("isLimitedPartner")}
                          borderRadius="full"
                          size="lg"
                          align="right"
                        />
                      </HStack>
                    </Flex>
                  </>
                )} */}

              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme={isEditable ? "blue" : "green"}
                onClick={handleSubmit}
              >
                {isEditable ? "Update" : "Submit"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default PartnersInfo;
