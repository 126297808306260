import React, { useEffect } from "react";

import {
  Box,
  Button,
  Heading,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Grid,
  GridItem,
  VStack,
  Input
} from "@chakra-ui/react";
import Province from "../../../utils/Province";

const Review = ({ request, setRequest, companyType, handleNext }) => {

  const reviewMap = request.review ? (companyType ? request.review[companyType] : request.review) : false;

  const reviewFieldNotRequiredMap = request.reviewFieldNotRequired ? (companyType ? request.reviewFieldNotRequired[companyType] : request.reviewFieldNotRequired) : false;

  const reviewFieldHiddenMap = request.reviewFieldHidden ? (companyType ? request.reviewFieldHidden[companyType] : request.reviewFieldHidden) : false;

  const reviewFieldReadOnlyMap = request.reviewFieldReadOnly ? (companyType ? request.reviewFieldReadOnly[companyType] : request.reviewFieldReadOnly) : false;

  const handleChangeData = (section, field, value) => {
    
    console.log(section, field, value);

    setRequest((prevRequest) => ({
      ...prevRequest,
      [section]: {
        ...prevRequest[section],
        [field]: value,
      },
    }));

    console.log(request);

    // need the timeout, otherwise this happens before React adds the necessary class to the field, which is required in the countFields function
    window.setTimeout(countFields, 200);
  };

  const countFields = () => {
    // let updatedReviewFieldCount = 0;
    let updatedReviewFieldNoDataCount = 0;

    if (request.review){
      // updatedReviewFieldCount = document.querySelectorAll('.requiredData').length;
      updatedReviewFieldNoDataCount = document.querySelectorAll('.requiredData.noData').length;
    }

    if (request.reviewFieldCount != undefined){
      setRequest((prevRequest) => {
        return {
          ...prevRequest,
          // reviewFieldCount: updatedReviewFieldCount,
          reviewFieldNoDataCount: updatedReviewFieldNoDataCount
        };
      });
    }

    // console.log('updatedReviewFieldCount', updatedReviewFieldCount);
    console.log('updatedReviewFieldNoDataCount', updatedReviewFieldNoDataCount);
  };

  useEffect(() => {
    countFields();
  }, [1]);

  return (
    <>
      <Heading as="h2" className="formPageSubHeading" mb={4}>
        Review
      </Heading>

      <Text>
        This is a summary of all the data that you have entered. Please review it thoroughly, make any adjustments, and click Proceed to Review Payment if everything looks good. 
      </Text>

      <Text fontStyle="italic">
        NOTE: you can use this review page to quickly edit some text fields (if needed) prior to proceeding to payment. However, some fields and/or data cannot be edited here and thus need to be edited in their respective sections. 
      </Text>

      <Grid
        className="container reviewGrid"
        templateColumns={{
          base: "1fr",
          md: "auto 1fr",
        }}
        gap={6}
        m="0"
        mt="24px"
        p="0"
        width="100%"
      >
        {reviewMap && (
          reviewMap.map((section, index) => {
            return (
            <React.Fragment key={index}>
                
              <GridItem key={index} pr="10">
                <Text fontWeight="bold" fontSize="16px !important" mb="0 !important">
                  {
                    section
                      .replace("account", "personal")
                      .replace("agent", "agentForService")
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .replace(/^\w/, (c) => c.toUpperCase())
                  }
                </Text>
              </GridItem>
              
              <GridItem>
                {/* scroll is not working...*/}
                <Box overflowX="auto" width="100%">
                  <Table width="100%">
                    <Tbody>
                    { // used for Agent for Service
                      typeof request[section] == 'string' ?
                      (
                        <Tr>
                          <Td p="0" width="auto" valign="top">
                            <Text fontWeight="bold" color="gray" fontSize="14px !important" mb="0 !important">
                              "{request[section]}"
                            </Text>
                          </Td>
                          <Td></Td>
                        </Tr>
                      )
                      :
                      (
                        <Tr>
                          <Td p="0" width="auto" valign="top">
                          {
                          Object.entries(request[section]).map(([key, value], subIndex) => (
                          // don't show the 'results' key
                          (!reviewFieldHiddenMap || (
                          (reviewFieldHiddenMap[section] == undefined || (reviewFieldHiddenMap[section] && reviewFieldHiddenMap[section][key] == undefined)))) && (
                            // if shareholders, directors, etc
                            Array.isArray(value) ? (
                              <VStack width="100%" justifyContent="left" alignItems="left"> 
                                <Text fontWeight="bold" color="gray" fontSize="14px !important" mb="0 !important">
                                  {
                                    key
                                      .replace("managers", "currentDirectors")
                                      .replace(/([A-Z])/g, " $1")
                                      .trim()
                                      .replace(/^\w/, (c) => c.toUpperCase())
                                  }:
                                </Text>
                                
                                {value.map((item, itemIndex) => (
                                  <Box overflowX="auto" width="100%">    
                                    <Table mb="12px" width="100%">
                                      <Tbody>
                                      {Object.entries(item).map(([itemKey, itemValue]) => (
                                        // don't show the 'key' prop
                                        itemKey !== 'key' ? (
                                        <Tr>
                                          <Td p="0" width="200px">
                                            <Text color="gray" fontSize="14px !important" mb="0 !important">
                                              {
                                                itemKey
                                                .replace("street", "address")
                                                .replace(/([A-Z]|[0-9])/g, " $1")
                                                .trim()
                                                .replace(/^\w/, (c) => c.toUpperCase())
                                              }:
                                            </Text>
                                          </Td>
                                          <Td p="0">
                                            {/* <Text fontSize="14px !important" fontWeight="bold" mb="0 !important" color={itemValue == '' ? "#bbbbbb" : "blue.500"} bgColor={itemValue == '' ? "#ffffb1" : "white"}>
                                              {itemValue !== '' ? itemValue : 'no data'}
                                            </Text> */}
                                            <Text fontSize="14px !important" fontWeight="bold" mb="0 !important" color="blue.500" bgColor="white">
                                              {itemValue}
                                            </Text>
                                          </Td>
                                        </Tr>
                                        ) : (
                                        // replace 'key' prop with "person"
                                        <Tr>
                                          <Td p="0">
                                            <Text fontSize="12px !important" my="0 !important">
                                              person #{itemIndex+1}
                                            </Text>
                                          </Td>
                                          <Td></Td>
                                        </Tr>
                                        )
                                      ))}
                                      </Tbody>
                                    </Table>
                                  </Box>
                                ))}
                              </VStack>
                            // otherwise, regular key/value output
                            ) : (
                            <Tr>
                              <Td p="0" width="200px">
                                <Text fontWeight="bold" color="gray" fontSize="14px !important" mb="0 !important">
                                  {
                                    key
                                      .replace("managers", "currentDirectors")
                                      .replace("partnership", "")
                                      .replace("street", "address")
                                      .replace(/([A-Z]|[0-9])/g, " $1")
                                      .trim()
                                      .replace(/^\w/, (c) => c.toUpperCase())
                                  }:
                                </Text>
                              </Td>
                              <Td p="0">
                                {/*
                                <Text className="nodata" fontSize="14px !important" fontWeight="bold" my="0 !important" color={value == '' ? "#bbbbbb" : "blue.500"} bgColor={value == '' ? "#ffffb1" : "white"}>
                                  {value !== '' ? value : 'no data'}
                                </Text>
                                */}
                                {value && value != '' ? (
                                  (reviewFieldReadOnlyMap && reviewFieldReadOnlyMap[section] && reviewFieldReadOnlyMap[section][key] != undefined) || value == 'Snappy Corp' ? (
                                    <Text fontSize="14px !important" fontWeight="bold" mb="0 !important" color="#3182ce">
                                      {value.replace('Snappy Corp', '"Snappy Corp"')}
                                      {value.length >= 30 ? ( <><br /><br /></> ) : ( <></> )}
                                    </Text>
                                  ) : (
                                    key.toLowerCase().includes('province') ? (
                                      <Province
                                        width={'auto'}
                                        value={value}
                                        onChange={(e) =>
                                          handleChangeData(section, key, e.target.value)
                                        }
                                      />
                                    ) : (
                                      <Input
                                      w={{ base: "100%", md: "30ch" }}
                                      type={key.toLowerCase().includes('date') ? "date" : "text"}
                                      value={value}
                                      onChange={(e) => handleChangeData(section, key, e.target.value)}
                                    />
                                    )
                                  )
                                ) : (
                                  (!reviewFieldNotRequiredMap || (
                                  (reviewFieldNotRequiredMap[section] == undefined || (reviewFieldNotRequiredMap[section] && reviewFieldNotRequiredMap[section][key] == undefined)))) && (
                                  <Text fontSize="14px !important" fontWeight="bold" mb="0 !important" color="#bbbbbb" bgColor="#ffffb1" fontStyle="italic" className="requiredData noData">
                                    required data
                                  </Text>
                                  )
                                )}
                              </Td>
                            </Tr>
                            )
                          )
                          ))
                          }
                          </Td>
                        </Tr>
                      )
                    }

                    </Tbody>
                  </Table>
                </Box>
              </GridItem>

            </React.Fragment>
            );
          })
        )}
      </Grid>

      <br /><br />

      <Box textAlign="right" mb="24px">
        <Button
          colorScheme="green"
          size="lg"
          onClick={handleNext}
          _hover={{ bg: "blue.700" }}
          w={{ base: "100%", md: "auto" }}
        >
          Proceed to Review Payment
        </Button>
      </Box>
    </>
  );
};

export default Review;
