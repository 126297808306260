import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Heading,
  Center,
  useToast,
  Button,
  Text,
} from "@chakra-ui/react";
import {
  getAllReminders,
  updateReminderStatus,
} from "../services/admin/reminders";

const Reminders = () => {
  const [reminders, setReminders] = useState([]);
  const toast = useToast();

  const loadData = async () => {
    try {
      const response = await getAllReminders();
      if (response.status === 200) {
        setReminders(response.data.reminders);
      } else {
        // Handle error using toast
        toast({
          title: "Error",
          description: "Failed to load reminders.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // Handle error using toast
      console.log(error);
      toast({
        title: "Error Try again",
        description: "Failed to load reminders. Maybe Server error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleStatusUpdate = async (_id) => {
    try {
      const response = await updateReminderStatus(_id);
      if (response.status === 200) {
        toast({
          title: "Reminder Updated",
          description: "Reminder has been updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to update reminder." + response.data.msg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update reminder.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      loadData();
    }
  };
  return (
    <Box p={8}>
      <Heading mb={4} textAlign="center">
        Submitted Reminders
      </Heading>
      <Center>
        <Box w="90%">
          <Table variant="striped" colorScheme="blue">
            <Thead>
              <Tr>
                <Th>S.No</Th>
                <Th>Phone</Th>
                <Th>Email</Th>
                <Th>Registration</Th>
                <Th>Corporate Vehicle</Th>
                <Th>Annual Return</Th>
                <Th>Driver License</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {reminders.map((reminder, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{reminder.phone}</Td>
                  <Td>{reminder.email}</Td>
                  <Td>
                    {reminder.vehicleRegistration ? (
                      <>
                        <Text>
                          First Name: <b>{reminder.firstName}</b>
                        </Text>
                        <Text>
                          Last Name: <b>{reminder.lastName}</b>
                        </Text>
                      </>
                    ) : null}
                  </Td>
                  <Td>
                    {reminder.companyVehicleRegistration ? (
                      <>
                        <Text fontWeight={"bold"}>
                          {reminder.companyVehicleName}
                        </Text>
                        {reminder.companyVehicleQuarterlyReminders
                          ? "(Quarterly Reminders)"
                          : null}
                      </>
                    ) : null}
                  </Td>
                  <Td>
                    {reminder.corporateAnnualReturns ? (
                      <>
                        <Text fontWeight={"bold"}>
                          {reminder.corporateAnnualReturnName}
                        </Text>
                        {reminder.corporateAnnualReturnFilingMonth}
                      </>
                    ) : null}
                  </Td>
                  <Td>
                    {reminder.driverLicense ? (
                      <>
                        {reminder.driverLicenseMonth}{" "}
                        {reminder.driverLicenseYear}
                      </>
                    ) : null}
                  </Td>
                  <Td>
                    {reminder.status !== "Completed" && (
                      <Button
                        colorScheme="yellow"
                        onClick={() => {
                          handleStatusUpdate(reminder._id);
                        }}
                      >
                        Completed
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Center>
    </Box>
  );
};

export default Reminders;
