// import * as React from "react";
import React, { useEffect, useState } from "react";

import { ChakraProvider, Text } from "@chakra-ui/react";
import theme from "./customTheme";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./home/Home";
import Main from "./main/Main";
import DecideForMe from "./main/decide/incorp/DecideForMe";
import RouteNotFound from "./utils/RouteNotFound";
import ChecklistRoutes from "./main/checklist/ChecklistRoutes";
import store from "./redux/store";
import { Provider } from "react-redux";
// import Submission from "./home/submitDocuments/index";

import EmployeeHome from "./employee/Home";
import ReminderForm from "./home/Reminder";

import Partnership from "./main/new/other/Partnership";
import TradeName from "./main/new/other/TradeName";
import ExistingIndex from "./main/existing/incorporation/ExistingCorporateIndex";

import "./App.css";
import ExistingPartnersIndex from "./main/existing/partnership/existingPartnershipIndex";
import CorporateSearch from "./main/services/corporateSearch/CorporateSearch";
import AnnualReturn from "./main/services/annualReturn/AnnualReturn";
import ThankYou from "./utils/ThankYou";
import CancelOrDissolve from "./home/services/pages/CancelOrDissolve";
import ContinueIn from "./home/services/pages/ContinueIn";
import MoveOut from "./home/services/pages/MoveOut";
import Amalgamate from "./home/services/pages/Amalgamate";
import Temp from "./Temp";
import Services from "./home/services/Services";
import ManageBusiness from "./home/services/ManageBusiness";
import HelpIndex from "./home/services/help/HelpIndex";
import ScrollToTop from "./utils/ScrollToTop";
import UnderConstruction from "./utils/UnderConstruction";

const App = () => {

  window.disclaimerClicked = false;

  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  // Fetch maintenance mode status from the backend
  useEffect(() => {    
    const env = process.env;
    // console.log('front env', env);

    const url = env.NODE_ENV == 'production' ? 
      `${env.REACT_APP_API_URL}/api/maintenance-status` :
      `${env.REACT_APP_API_URL}:7000/api/maintenance-status`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        // console.log('data', data);
        if (data.isUnderMaintenance) {
          setIsUnderMaintenance(true);
        }
      })
      .catch(error => {
        console.error('Error fetching maintenance status:', error);
      });
  }, []);

  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />{
          isUnderMaintenance ? (
          <UnderConstruction />
          ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/main/*" element={<Main />} />
            {/* <Route path="/services/*" element={<ServicesHome />} /> */}
            <Route path="/decide/*" element={<DecideForMe />} />
            <Route path="/checklist/*" element={<ChecklistRoutes />} />

            {/* <Route path="/client/guest/*" element={<Submission />} /> */}
            <Route path="/employee/*" element={<EmployeeHome />} />
            <Route path="/reminders/" element={<ReminderForm />} />
            <Route path="/guest/new/partnership" element={<Partnership />} />
            <Route path="/guest/new/tradename" element={<TradeName />} />
            <Route path="/existingCorporate/*" element={<ExistingIndex />} />
            <Route path="/existingPartnership/*" element={<ExistingPartnersIndex />} />

            <Route path="/corpSearch" element={<CorporateSearch />} />
            <Route path="/annualReturn" element={<AnnualReturn />} />
          
            <Route path="/help/*" element={<HelpIndex />} />

            {/* just pages */}
            <Route path="/moveOut" element={<MoveOut />} />
            <Route path="/continueInAB" element={<ContinueIn />} />
            <Route path="/cancel-dissolve" element={<CancelOrDissolve />} />
            <Route path="/amalgamate" element={<Amalgamate />} />

            <Route path="/thankYou" element={<ThankYou />} />
            <Route path="/t" element={<Temp />} />
            <Route path="/services" element={<Services />} />
            <Route path="/manage" element={<ManageBusiness />} />
            <Route path="/*" element={<RouteNotFound />} />
          </Routes>
          )}
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  );
};

export default App;
