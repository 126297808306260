import React from "react";
import { Box, Text } from "@chakra-ui/react";

const PriceTag = ({ basePrice, updatedPrice, offerExpiryDate }) => {
  return (
    <Box textAlign="right">
      <Text as="span" fontSize="xl" color="red.500" textDecoration="line-through" fontWeight="bold">
        ${basePrice} CAD
      </Text>
      <Text as="span" fontSize="2xl" color="green.500" fontWeight="bold" ml={2}>
        ${updatedPrice} CAD
      </Text>
      <Text fontSize="sm" color="gray.500">
        Offer ending on: September 30, 2024
      </Text>
    </Box>
  );
};

export default PriceTag;
