import React, { useState } from "react";
import {
  Box,
  Text,
  FormControl,
  HStack,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  useDisclosure,
  Grid,
  GridItem,
  Link,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const YesNoField = ({
  label,
  about,
  text,
  setValue,
  enabled,
  price,
  link,
  linkText,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [yesClicked, setYesClicked] = useState(false);
  const [noClicked, setNoClicked] = useState(false);

  const handleYesClick = () => {
    setValue("Yes");
    setYesClicked(true);
    setNoClicked(false);
  };

  const handleNoClick = () => {
    setValue("No");
    setYesClicked(false);
    setNoClicked(true);
  };

  return (
    <Box
      w="100%"
      p={4}
      border="1px solid rgba(0,0,0,0.15)"
      borderRadius={8}
      boxShadow="3px 3px 8px rgba(0,0,0,0.25)"
      className="yesno"
    >
      <FormControl>
        <Grid
          className="gridParent"
          templateColumns={{ base: "1fr", md: "auto auto 1fr" }}
          gap={4}
          alignItems="center"
          justifyContent="space-between" // Ensure items are spaced apart
        >
          <GridItem alignItems="center" className="gridItem" width="100%">
            <Text color="#666" fontWeight="bold" fontSize="18px">
              {label}
            </Text>

            {(text || link) && <Text fontSize="14px !important">{text}</Text>}
            {link && (
              <Link
                color="#03a9f4" // Start with blue color
                transition="color 0.5s ease-out"
                _hover={{ textDecoration: "none" }}
                href={link}
                isExternal={true}
              >
                {linkText ? linkText : "Learn More!"}
              </Link>
            )}
          </GridItem>

          <GridItem alignItems="center" className="gridItem" colStart={3} justifySelf="end">
            <Grid
              justifyContent="flex-end"
              width="100%"
              className="gridParent"
              templateColumns={{ base: "1fr", lg: "auto auto 1fr" }}
              gap={2}
              alignItems="center"
            >
              <HStack justifyContent="end" width="100%">
                {about && about !== "" && (
                  <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                    <PopoverTrigger className="popover-trigger">
                      <InfoOutlineIcon
                        cursor="pointer"
                        className="popover-trigger-icon"
                      />
                    </PopoverTrigger>
                    <PopoverContent className="popover-content">
                      <PopoverArrow className="popover-arrow" />
                      <PopoverCloseButton />
                      <PopoverBody>{about}</PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
                <GridItem>
                  {price && (
                    <Text className="price">
                      {price ? ` +$${price} CAD` : ""}
                    </Text>
                  )}
                </GridItem>
              </HStack>
              <HStack justifyContent="end" width="100%">
                <Button
                  colorScheme={yesClicked || enabled === true ? "teal" : "gray"}
                  variant={yesClicked || enabled === true ? "solid" : "outline"}
                  borderColor={"black"}
                  _hover={{ bg: "gray.400", color: "black" }}
                  onClick={handleYesClick}
                >
                  Yes
                </Button>
                <Button
                  colorScheme={noClicked || enabled === false ? "teal" : "gray"}
                  variant={noClicked || enabled === false ? "solid" : "outline"}
                  borderColor={"black"}
                  _hover={{ bg: "gray.400", color: "black" }}
                  onClick={handleNoClick}
                >
                  No
                </Button>
              </HStack>
            </Grid>
          </GridItem>
        </Grid>

      </FormControl>
    </Box>
  );
};

export default YesNoField;
