import React from "react";
import {
  Flex,
  Heading,
  VStack,
  Button,
  Divider,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../utils/NavBar.js";
import Disclaimer from "../../utils/Disclaimer.js";
import ModalDisclaimer from "../../utils/ModalDisclaimer.js";

const Numbered = () => {
  
  const navigate = useNavigate();

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" mt="50px">
        <VStack align="start" className="container" spacing={4}>  
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/main/business-type");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading as="h1">Register a Partnership</Heading>

          <Heading as="h2">Things to be aware of before you register a partnership</Heading>

          <VStack spacing={4}>
            <List>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Both the partners need to consent to dissolve the partnership.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />A partnership can exist between two or more legal entities (could be individuals, corporations or partnerships)
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Both the partners will be liable for any obligations of the partnership. (Please note this would not be applicable for a Limited Partnership, and therefore please contact a lawyer for more information.)</ListItem>
            </List>

            {/* <Heading>Some more questions</Heading> */}
          </VStack>

          <Button
            colorScheme="green"
            onClick={() => {
              navigate("/guest/new/partnership");
            }}
          >
            Continue to register Partnership
          </Button>  
        </VStack>
      </Flex>

      <Disclaimer />

      <ModalDisclaimer />
    </>
  );
};

export default Numbered;
