import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ManageDashboard from "./ManageDashboard";
import { useSelector } from "react-redux";
import ServiceList from "./services/ServiceList";
import CreateService from "./services/CreateService";
import EditService from "./services/EditService";
import ViewService from "./services/ViewService";
import FormsList from "./forms/Forms";
import UsersList from "./users/UsersList";
// import CreateUser from "./users/CreateUser";
// import EditUser from "./users/EditUser";
// import ViewUser from "./users/ViewUser";
const AdminHome = () => {
  const employee = useSelector((state) => state.employee).state;
  const navigate = useNavigate();
  useEffect(() => {
    if (!employee || !employee.isAdmin) navigate("/employee");
  }, [employee]);

  return (
    <Routes>
      <Route path="/" element={<ManageDashboard />} />
      <Route path="/dashboard" element={<ManageDashboard />} />

      {/* Services */}
      <Route path="/services" element={<ServiceList />} />
      <Route path="/services/create" element={<CreateService />} />
      <Route path="/services/service/edit/:id" element={<EditService />} />
      <Route path="/services/service/view/:id" element={<ViewService />} />

      {/* Forms  */}
      <Route path="/forms" element={<FormsList />} />

      {/* Users */}
      <Route path="/users" element={<UsersList />} />
      {/* <Route path="/users/create" element={<CreateUser />} />
      <Route path="/users/view/:id" element={<ViewUser />} />
      <Route path="/users/edit/:id" element={<EditUser />} /> */}
    </Routes>
  );
};

export default AdminHome;
