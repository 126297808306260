import React from "react";
import CompanyComponent from "../new/register/CompanyComponent.js";
import Disclaimer from "../../utils/Disclaimer.js";
import NavBar from "../../utils/NavBar.js";

const Numbered = () => {
  return (
    <>
      <NavBar />
      <CompanyComponent bType={"numbered"} />
      <Disclaimer />
    </>
  );
};

export default Numbered;
