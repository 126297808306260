export const authenticate = (username, password) => {
  try {
    const response = {
      status: 200,
      user: {
        _id: "dff",
        username: "bob_jones",
        name: "Bob Jones",
        isAdmin: false,
        isActive: false,
      },
    };
    return response;
  } catch (error) {
    return error.response;
  }
};

export const login = (username, password) => {
  //check if username or password is present in the user
  try {
    const response = {
      status: 200,
      user: {
        _id: "df",
        username: "paramvst",
        password: "dd",
        name: "Param",
        isAdmin: true,
        isActive: true,
      },
    };
    return response;
  } catch (error) {
    return error.response;
  }
};
