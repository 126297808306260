import React from "react";
import {
  Flex,
  HStack,
  Heading,
  VStack,
  Button,
  Divider,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";
import ModalDisclaimer from "../../../utils/ModalDisclaimer.js";

const ExtraProvincial = ({ request, setRequest }) => {
  const navigate = useNavigate();

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing={4}>
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/main");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading mb="24px">
            Would you like to register an Extra-Provincial Registration in Alberta?
          </Heading>

          <HStack spacing={4}>
            <Button className="btn-black" onClick={() => { navigate("/checklist/extraprovincial"); }}>
              Yes
            </Button>
            <Button className="btn-black" onClick={() => { navigate("/main/location/registered-office"); }}>
              No
            </Button>
          </HStack>
        </VStack>
      </Flex>

      <Disclaimer />

      <ModalDisclaimer />
    </>
  );
};

export default ExtraProvincial;
