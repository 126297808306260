import React from "react";
import {
  Heading,
  VStack,
  Text,
  Textarea,
  FormLabel,
  FormControl,
  Input,
  Grid,
  GridItem
} from "@chakra-ui/react";
import YesNoField from "../../../../utils/YesNoField.js";

const AddOns = ({ request, setRequest }) => {

  const handlePriceChange = (value, priceType) => {
    request.togglePrice(value, priceType);

    if (value === 'Yes'){
      if (priceType == 'ChangeShareStructure'){
        setRequest((prevReq) => ({
          ...prevReq,
          reviewFieldHidden: {
            ...prevReq.reviewFieldHidden,
            addOns: {
              ...prevReq.reviewFieldHidden.addOns,
              newShareStructure: undefined
            }
          }
        }));
      } else if (priceType == 'ChangeCorporationName'){
        setRequest((prevReq) => ({
          ...prevReq,
          reviewFieldHidden: {
            ...prevReq.reviewFieldHidden,
            addOns: {
              ...prevReq.reviewFieldHidden.addOns,
              newCorporationName: undefined
            }
          }
        }));
      }
    }

    if (value === 'No'){
      if (priceType == 'ChangeShareStructure'){
        setRequest((prevReq) => ({
          ...prevReq,
          addOns: {
            ...prevReq.addOns,
            newShareStructure: ""
          },
          reviewFieldHidden: {
            ...prevReq.reviewFieldHidden,
            addOns: {
              ...prevReq.reviewFieldHidden.addOns,
              newShareStructure: false
            }
          }
        }));
      } else if (priceType == 'ChangeCorporationName'){
        setRequest((prevReq) => ({
          ...prevReq,
          addOns: {
            ...prevReq.addOns,
            newCorporationName: ""
          },
          reviewFieldHidden: {
            ...prevReq.reviewFieldHidden,
            addOns: {
              ...prevReq.reviewFieldHidden.addOns,
              newCorporationName: false
            }
          }
        }));
      }
    }
  };

  const handleAnnualMgmtChange = (value, priceType) => {
    let bool = value === 'Yes' ? true : false;
    setRequest((prevReq) => ({ ...prevReq, annualMgmt: bool }));
    request.togglePrice(value, priceType);
  };

  const changeShareStuctureEnabled = request.prices.ChangeShareStructure.enabled;
  const changeCorporationNameEnabled = request.prices.ChangeCorporationName.enabled;

  return (
    <>
      <VStack align="left">
        <Heading>Add Ons</Heading>
        <VStack spacing={4} width="100%">
          {/* <Button colorScheme="blue" onClick={onOpen}>
                Yes
              </Button>
              <Button colorScheme="blue" onClick={handleBuyNow}>
                No
              </Button> */}
          <YesNoField
            label={"Change Share Structure?"}
            
            setValue={(value) => handlePriceChange(value, 'ChangeShareStructure')}
            enabled={changeShareStuctureEnabled}
            price={request.prices.ChangeShareStructure.value}
          />

          {changeShareStuctureEnabled && (
          <>
            <FormLabel color="blue.500" width="100%" mb="0 !important">
              Enter your share structure in here:
            </FormLabel>
            
            <Textarea
              h="300px"
              value={request.addOns.newShareStructure}
              onChange={(e) =>
                setRequest((prevReq) => ({
                  ...prevReq,
                  addOns: {
                    ...prevReq.addOns,
                    newShareStructure: e.target.value,
                  }
                }))
              }
            />
          </>
          )}

          <YesNoField
            label={"Change Corporation Name?"}
            setValue={(value) => handlePriceChange(value, 'ChangeCorporationName')}
            enabled={request.prices.ChangeCorporationName.enabled}
            price={request.prices.ChangeCorporationName.value}
          />

          {changeCorporationNameEnabled && (
          <>
             <FormControl>
              <Grid
                templateColumns={{ base: '1fr', md: 'auto 1fr' }}
                gap={3}
                alignItems="center"
              >
                <GridItem>
                  <FormLabel color="blue.500">
                    Enter your new corporation name here:
                  </FormLabel>
                </GridItem>
                <GridItem>
                  <Input
                    w={{ base: "100%", md: "100%" }}
                    value={request.addOns.newCorporationName}
                    onChange={(e) =>
                      setRequest((prevReq) => ({
                        ...prevReq,
                        addOns: {
                          ...prevReq.addOns,
                          newCorporationName: e.target.value,
                        }
                      }))
                    }
                  />
                </GridItem>
              </Grid>
            </FormControl>
          </>
          )}

          <YesNoField
            label={"Would you like to subscribe for annual maintenance?"}
            about={"Contact us for more information. Subscribe annually and save more."}
            setValue={(value) => handleAnnualMgmtChange(value, 'SubscribeAnnualMaintenance')}
            enabled={request.prices.SubscribeAnnualMaintenance.enabled}
            price={request.prices.SubscribeAnnualMaintenance.value}
            link="/help/annualSubscription"
          />
        </VStack>
        <Text align={"left"} ml="15" mt="12px !important" color="green.500" fontWeight="bold">
          Unlimited changes in shareholder, directors, and agent for service will be filled with no extra fees.
        </Text>
      </VStack>
    </>
  );
};

export default AddOns;
