import React, { useState } from "react";
import {
  Flex,
  Text,
  Textarea,
  HStack,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  useToast,
} from "@chakra-ui/react";

import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";
import { handleSendInquiryEmail } from "../../../services/email.js";

const RegisteredOfficeInAB = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showContactForm, setShowContactForm] = useState(false);

  const toast = useToast();
  const [emailData, setEmailData] = useState({
    address: "",
    message: "",
    name: "",
  });

  const handleSendEmail = async () => {
    (await handleSendInquiryEmail(emailData))
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    onClose();
  };

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing={4}>
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/main/location/extra-provincial");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading mb="24px">
            Are you looking for a registered office in Alberta?
          </Heading>

          <HStack spacing={4}>
            <Button className="btn-black"
              onClick={() => {
                setShowContactForm(true);
                onOpen();
              }}
            >
              Yes
            </Button>
            <Button className="btn-black" onClick={onOpen}>
              No
            </Button>
          </HStack>
            
          <Modal isOpen={isOpen} onClose={()=>{onClose();setShowContactForm(false)}}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {!showContactForm
                  ? "Important Information"
                  : "Contact Information"}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Heading as="h3" color="green.500">
                  {!showContactForm
                    ? "Contact us directly with your inquiry!"
                    : "We will contact you as soon as possible!"}
                </Heading>
                <Text>
                  Please provide your details here.
                </Text>
                <FormControl>
                  <FormLabel>Your Name:</FormLabel>
                  <Input
                    placeholder="Your Name"
                    mb={4}
                    value={emailData.name}
                    onChange={(e) =>
                      setEmailData({ ...emailData, name: e.target.value })
                    }
                  />
                  <FormLabel>Your Email Address:</FormLabel>
                  <Input
                    placeholder="Email Address"
                    mb={4}
                    value={emailData.address}
                    onChange={(e) =>
                      setEmailData({ ...emailData, address: e.target.value })
                    }
                  />
                  <FormLabel>
                    {!showContactForm
                      ? "Your Inquiry:"
                      : "Additional Message / Comments:"}
                  </FormLabel>
                  <Textarea
                    placeholder="Message"
                    rows={4}
                    value={emailData.message}
                    onChange={(e) =>
                      setEmailData({ ...emailData, message: e.target.value })
                    }
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <HStack spacing="2">
                  <Button
                    variant="ghost"
                    color="black"
                    onClick={()=>{onClose();setShowContactForm(false)}}
                  >
                    Close
                  </Button>
                  <Button colorScheme="green" onClick={handleSendEmail}>
                    Submit {!showContactForm ? "Inquiry" : "Request"}
                  </Button>
                  <Button
                    colorScheme="yellow"
                    onClick={() => {
                      window.location.href = "tel:+1(403) 262 9999";
                    }}
                  >
                    Call Us
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </VStack>
      </Flex>

      <Disclaimer />
    </>
  );
};

export default RegisteredOfficeInAB;
