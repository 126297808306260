import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Text,
  Input,
  IconButton,
  HStack,
  Divider,
  ListItem,
  List,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Switch,
  Grid,
  GridItem,
  Center,
  RadioGroup,
  Radio
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useState } from "react";
import New from "./partners/New";
import Delete from "./partners/Delete";
import Province from "../../../../utils/Province";

const PartnersInfo = ({ request, setRequest }) => {
  const [isEditable, setIsEditable] = useState(false);
  // const [officers, setOfficers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    key: Date.now(),
    firstName: "",
    lastName: "",
    email: "",
    corpName: "",
    corpAccessNumber: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    postalCode: "",
    country: "Canada",
    province: "AB",
    revokeDate: ""
  });

  const [modalPartnerType, setModalPartnerType] = useState('individual');

  const handlePartnerAdd = () => {
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      email: "",
      corpName: "",
      corpAccessNumber: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "AB",
      revokeDate: ""
    });
    setShowModal(true);
  };

  const handleEditOfficerPartner = (partner) => {
    setFormData({ ...partner });
    setIsEditable(true);
    setShowModal(true);
  };

  const handleDeleteCurrentOfficerPartner = (key) => {
    if (window.confirm("Are you sure you want to delete this current partner?")) {
      const updatedCurrentPartners = request.partners.currentPartners.filter(
        (partner) => partner.key !== key
      );

      setRequest((prevRequest) => ({
        ...prevRequest,
        partners: {
          ...prevRequest.partners,
          currentPartners: updatedCurrentPartners,
          results: [
            ...updatedCurrentPartners,
            ...prevRequest.partners.newPartners
          ].filter(
            (partner) =>
              !prevRequest.partners.deletedPartners.some(
                deleted => deleted.key === partner.key
              )
          ),
        }
      }));
    
      setFormData({
        key: Date.now(),
        firstName: "",
        lastName: "",
        email: "",
        corpName: "",
        corpAccessNumber: "",
        streetLine1: "",
        streetLine2: "",
        city: "",
        postalCode: "",
        country: "Canada",
        province: "AB",
        revokeDate: ""
      });
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [changeName, setChangeName] = useState(false);

  const handleSubmit = () => {
    // Check if all required fields are filled
    let requiredFields = [
      "streetLine1",
      "city",
      "postalCode",
      "province",
      "country"
    ];

    if (modalPartnerType === 'corporation'){
      requiredFields.push("corpName");
      requiredFields.push("corpAccessNumber");
    } else {
      requiredFields.push("firstName");
      requiredFields.push("lastName");
    }
    
    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      alert("Please fill all required fields.");
      return;
    }

    // If it's editable, update the existing director, otherwise append new formData.
    setRequest((prevRequest) => {
      // Constructing updated directors inside setRequest to capture the current state correctly
      const updatedCurrentPartners = isEditable
        ? prevRequest.partners.currentPartners.map((partner) =>
            partner.key === formData.key
            ? { ...partner, ...formData }
            : partner
          )
        : [...prevRequest.partners.currentPartners, formData];

      const updatedPartners = {
        ...prevRequest.partners,
        currentPartners: updatedCurrentPartners,
        results: [
          ...updatedCurrentPartners,
          ...prevRequest.partners.newPartners,
        ].filter(
          (partner) =>
            !prevRequest.partners.deletedPartners.some(
              (deleted) => deleted.key === partner.key
            )
        ),
      };

      return {
        ...prevRequest,
        partners: updatedPartners
      };
    });

    setShowModal(false);
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      email: "",
      corpName: "",
      corpAccessNumber: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "AB",
      revokeDate: ""
    });
    setIsEditable(false);
  };

  const handleClose = () => {
    setIsEditable(false);
    setShowModal(false);
  }

  return (
    <>
      <Heading mb="15px">Partner(s) Information</Heading>

      {/* Officers and Partners Lists */}
      <Box>
        <HStack justify={"space-between"}>
          <Heading as="h3" color="blue.700">
            Current Partners
          </Heading>
          <Button
            colorScheme="blue"
            variant="solid"
            leftIcon={<AddIcon />}
            onClick={handlePartnerAdd}
          >
            Add
          </Button>
        </HStack>
        {request.partners.currentPartners &&
          request.partners.currentPartners.length > 0 && (
            <Box my={4}>
              <List
                borderBottom="solid 1px gray"
                ml={{base:"0", md:"10%"}}
                w={{base:"100%", md:"60%"}}
                color="blue.500"
              >
                {request.partners.currentPartners.map((partner, index) => (
                  <ListItem key={partner.key}>
                    <HStack justify="space-between">
                      <Text fontWeight="bold" mb="0 !important">
                        {partner.firstName} {partner.lastName}
                        {partner.corpName} {partner.corpAccessNumber}
                      </Text>
                      <HStack>
                        <IconButton
                          icon={<EditIcon />}
                          onClick={() =>
                            handleEditOfficerPartner(partner, "partner")
                          }
                        />
                        <IconButton
                          icon={<DeleteIcon />}
                          onClick={() =>
                            handleDeleteCurrentOfficerPartner(partner.key)
                          }
                        />
                      </HStack>
                    </HStack>
                  </ListItem>
                ))}
              </List>

              <Divider h="50px" />

              <Heading as="h3">Changes</Heading>
              {/* New Partners */}
              <New request={request} setRequest={setRequest} />
              {/* Deleted Partners */}
              <Delete request={request} setRequest={setRequest} />
            </Box>
          )}

        {/* Modified Partners */}
      </Box>

      <Modal
        isOpen={showModal}
        onClose={handleClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditable
              ? "Edit Partner"
              : request.partners.currentPartners.length === 0
              ? "Current Partner"
              : "Add Partner"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            {!isEditable && (
            <Center>
              <RadioGroup
                onChange={setModalPartnerType}
                value={modalPartnerType}
                mb="24px"
              >
                <HStack>
                  <Radio value='individual'>Individual</Radio>
                  <Radio value='corporation'>Corporation</Radio>
                </HStack>
              </RadioGroup>
            </Center>
            )}

            <FormControl isRequired>

              {((!isEditable && modalPartnerType === 'individual') || formData.firstName !== "") && (
              <>
                <FormLabel>First Name:</FormLabel>
                <Input
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleFormChange}
                  mb="4"
                />
                <FormLabel>Last Name:</FormLabel>
                <Input
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleFormChange}
                  mb="4"
                />
              </>
              )}

              {((!isEditable && modalPartnerType === 'corporation') || formData.corpName !== "") && (
              <>
                <FormLabel>Corporation Name:</FormLabel>
                <Input
                  name="corpName"
                  value={formData.corpName}
                  onChange={handleFormChange}
                  mb="4"
                />
                
                <FormLabel>Corporate Access Number:</FormLabel>
                <Input
                  name="corpAccessNumber"
                  value={formData.corpAccessNumber}
                  onChange={handleFormChange}
                  mb="4"
                />
              </>
              )}

              <FormControl>
                <FormLabel>Email:</FormLabel>
                <Input
                  name="email"
                  value={formData.email}
                  onChange={handleFormChange}
                  mb="4"
                />
              </FormControl>
              
              <Text fontWeight="bold">Residential Address (updated or current):</Text>
              {formData && (
                <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Address Line 1</FormLabel>
                      <Input                      
                        name="streetLine1"
                        value={formData.streetLine1}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl>
                      <FormLabel>Address Line 2</FormLabel>
                      <Input
                        name="streetLine2"
                        value={formData.streetLine2}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>City</FormLabel>
                      <Input
                        name="city"
                        value={formData.city}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Postal Code</FormLabel>
                      <Input
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Province</FormLabel>
                      <Province
                        width="auto"
                        name="province"
                        value={formData.province}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Country</FormLabel>
                      <Input
                        name="country"
                        value={formData.country}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              )}

              {((!isEditable && modalPartnerType === 'individual') || formData.firstName !== "") && (
              <>
                <HStack>
                  <Text mt="20px !important">
                    Has this partner changed the name??
                  </Text>
                  <Switch
                    value={changeName}
                    onChange={() => {
                      setChangeName(!changeName);
                      setFormData((prevData) => ({
                        ...prevData,
                        changeName: !changeName,
                      }));
                    }}
                  />
                </HStack>

                {changeName && (
                  <>
                    <Text color="green.500" fontWeight="bold">
                      Use the new name above and attach a letter stating:
                    </Text>

                    <Text fontStyle="italic">
                      "Partner's name is being changed from (__current name __) to
                      (__new name __) because of (___reason___ like marriage__)."
                    </Text>
                  </>
                )}
              </>
              )}

            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={isEditable ? "blue" : "green"}
              onClick={handleSubmit}
            >
              {isEditable ? "Update" : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PartnersInfo;
