import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Text,
  Input,
  IconButton,
  HStack,
  Divider,
  ListItem,
  List,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Grid,
  GridItem,
  Select
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useState } from "react";
import YesNoField from "../../../../utils/YesNoField";

export const OfficersInfo = ({ request, setRequest, companyType }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [officerPresent, setOfficerPresent] = useState("");

  const [formData, setFormData] = useState({
    key: Date.now(),
    firstName: "",
    lastName: "",
    position: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    postalCode: "",
    country: "Canada",
    province: "Alberta",
  });

  const handleYesNoChange = (value, type) => {
    let bool = value === 'Yes' ? undefined : false;

    request.toggleYesNo(value, type);

    if (value === 'Yes'){
      setOfficerPresent(true);
    } else {
      setOfficerPresent(false);
    }

    setRequest((prevReq) => ({
      ...prevReq,
      reviewFieldHidden: {
        ...prevReq.reviewFieldHidden,
        [companyType]: {
          ...prevReq.reviewFieldHidden[companyType],
          minuteBookInfo: {
            ...prevReq.reviewFieldHidden[companyType].minuteBookInfo,
            officers: bool
          }
        }
      }
    }));
  };

  const enabled = request.yesno.Officers.enabled;

  const handleOfficerAdd = () => {
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      position: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta",
    });
    setShowModal(true);
  };

  const handleEditOfficerManager = (manager) => {
    setFormData({ ...manager });
    setIsEditable(true);
    setShowModal(true);
  };

  const handleDeleteOfficerManager = (key) => {
    if (window.confirm("Are you sure you want to delete this current officer?")) {
      const updatedManagers = request.minuteBookInfo.officers.filter(
        (manager) => manager.key !== key
      );

      setRequest((prevRequest) => ({
        ...prevRequest,
        minuteBookInfo: {
          ...prevRequest.minuteBookInfo,
          officers: {
            ...prevRequest.minuteBookInfo.officers,
            managers: updatedManagers
          }
        }
      }));
    
      setFormData({
        key: Date.now(),
        firstName: "",
        lastName: "",
        position: "",
        streetLine1: "",
        streetLine2: "",
        city: "",
        postalCode: "",
        country: "Canada",
        province: "Alberta"
      });
    }
  };


  const handleFormChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   request.officers && request.officers.length === 0 &&
  //   setRequest((prevRequest) => ({
  //     ...prevRequest,
  //     officers: [
  //       { ...request.accountInfo, key: 1, position: "President" },
  //       { ...request.accountInfo, key: 2, position: "Secretary" },
  //       { ...request.accountInfo, key: 3, position: "Treasurer" },
  //     ],
  //   }));
  // }, []);

  const handleSubmit = () => {
    // Check if all required fields are filled
    const requiredFields = [
      "firstName",
      "lastName",
      "position",
      "streetLine1",
      "city",
      "postalCode",
      "province",
      "country",
    ];

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      alert("Please fill all required fields.");
      return;
    }

    setRequest((prevRequest) => {
      // Constructing updated managers inside setRequest to capture the current state correctly
      const updatedManagers = isEditable
        ? prevRequest.minuteBookInfo.officers.map((manager) =>
          manager.key === formData.key ? { ...manager, ...formData } : manager
          )
        : [...prevRequest.minuteBookInfo.officers, formData];

      return {
        ...prevRequest,
        minuteBookInfo: {
          ...prevRequest.minuteBookInfo,
          officers: updatedManagers
        }
      };
    });

    setShowModal(false);
    setIsEditable(false);

    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      position: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta",
    });
  };

  return (
    <>
      <Heading as="h4" mt="24px !important">Officers</Heading>

      <YesNoField
        label={"Would you like to have officers in your company?"}
        about={"If you have your own share structure, usually lawyers or your previous experience might help you choose this option."}
        text="Officers are appointed to handle different roles in the corporation. Their roles and responsibilities will be decided by the board of directors."
        setValue={(value) => handleYesNoChange(value, 'Officers')}
        enabled={enabled}
      />

        {(officerPresent || enabled) && (
          <Box>
            <HStack justify={"space-between"} py="5">
              <Heading as="h4" color="blue.700" mb="0 !important">
                Current Officers
              </Heading>
              
                <Button
                  colorScheme="blue"
                  variant="solid"
                  leftIcon={<AddIcon />}
                  onClick={handleOfficerAdd}
                >
                  Add
                </Button>
              
            </HStack>
            <Text>
              When appointing officers, there must be three positions:{" "}
              <b>President</b>, <b>Secretary</b> and <b>Treasurer</b>. However,
              all three positions can be held by the same person.
            </Text>
            <FormLabel
              fontSize="18px !important"
              py="20px !important"
            >
              Please identify the officers of your corporation:
            </FormLabel>

            {request.minuteBookInfo.officers &&
              request.minuteBookInfo.officers.length > 0 && (
                <List
                  borderBottom="solid 1px gray"
                  ml={{base:"0", md:"10%"}}
                  w={{base:"100%", md:"60%"}}
                  color="blue.500"
                >
                  {request.minuteBookInfo.officers.map((manager, index) => (
                    <ListItem key={manager.key}>
                      <HStack justify="space-between">
                        <Text fontWeight="bold" mb="0 !important">
                          {manager.firstName} {manager.lastName} ({manager.position})
                        </Text>
                        <HStack>
                          <IconButton
                            icon={<EditIcon />}
                            onClick={() => handleEditOfficerManager(manager)}
                          />
                          <IconButton
                            icon={<DeleteIcon />}
                            onClick={() =>
                              handleDeleteOfficerManager(manager.key)
                            }
                          />
                        </HStack>
                      </HStack>
                    </ListItem>
                  ))}
                </List>
            )}

          </Box>
        )}


      <Divider h="50px" />

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditable ? "Edit Officer" : "Add Officer"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>First Name:</FormLabel>
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleFormChange}
                mb="4"
              />
              <FormLabel>Last Name:</FormLabel>
              <Input
                placeholder="Last Name/ Corporate Name (full)"
                name="lastName"
                value={formData.lastName}
                onChange={handleFormChange}
                mb="4"
              />
              <FormLabel>Position:</FormLabel>
              <Select
                name="position"
                value={formData.position}
                onChange={handleFormChange}
                mb="4"
              >
                <option value="">Select...</option>
                <option value="President">President</option>
                <option value="Secretary">Secretary</option>
                <option value="Treasurer">Treasurer</option>
              </Select>
              <Text fontWeight="bold">Residential Address:</Text>
              {formData && (
                <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Address Line 1</FormLabel>
                      <Input                      
                        name="streetLine1"
                        value={formData.streetLine1}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl>
                      <FormLabel>Address Line 2</FormLabel>
                      <Input
                        name="streetLine2"
                        value={formData.streetLine2}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>City</FormLabel>
                      <Input
                        name="city"
                        value={formData.city}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Postal Code</FormLabel>
                      <Input
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Province</FormLabel>
                      <Input
                        name="province"
                        value={formData.province}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Country</FormLabel>
                      <Input
                        name="country"
                        value={formData.country}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={isEditable ? "blue" : "green"}
              onClick={handleSubmit}
            >
              {isEditable ? "Update" : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
