import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Input,
  VStack,
  Table,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";

const Shares = ({ request, setRequest }) => {
  const [currentShareholders, setCurrentShareholders] = useState(
    request.shareholders.currentShareholders
  );
  const [resultShareholders, setResultShareholders] = useState(
    request.shareholders.results
  );
  const [currentTotal, setCurrentTotal] = useState(0);
  const [resultTotal, setResultTotal] = useState(0);

  useEffect(() => {
    updateTotals();
  }, [currentShareholders, resultShareholders]);

  const updateTotals = () => {
    const currentSum =
      currentShareholders &&
      currentShareholders.reduce(
        (sum, shareholder) =>
          sum + (shareholder.share > 0 ? parseFloat(shareholder.share) : 0),
        0
      );
    setCurrentTotal(currentSum);

    const resultSum =
      resultShareholders &&
      resultShareholders.reduce(
        (sum, shareholder) =>
          sum + (shareholder.share > 0 ? parseFloat(shareholder.share) : 0),
        0
      );
    setResultTotal(resultSum);
  };

  const handleShareChange = (index, value, type) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) return;

    if (type === "current") {
      const updatedCurrentShareholders = [...currentShareholders];
      updatedCurrentShareholders[index] = {
        ...updatedCurrentShareholders[index],
        share: parsedValue,
      };
      setCurrentShareholders(updatedCurrentShareholders);
      setRequest((prevRequest) => ({
        ...prevRequest,
        shareholders: {
          ...prevRequest.shareholders,
          currentShareholders: updatedCurrentShareholders,
        },
      }));
    } else if (type === "result") {
      const updatedResultShareholders = [...resultShareholders];
      updatedResultShareholders[index] = {
        ...updatedResultShareholders[index],
        share: parsedValue,
      };
      setResultShareholders(updatedResultShareholders);
      setRequest((prevRequest) => ({
        ...prevRequest,
        shareholders: {
          ...prevRequest.shareholders,
          results: updatedResultShareholders,
        },
      }));
    }
  };

  return (
    <Box>
      <Heading>Shares</Heading>
      {currentShareholders
        && currentShareholders.length >= 1 ? (
          <VStack spacing={4}>
            <Heading as="h4">Current Shareholders</Heading>
            <Table variant="striped" size="sm">
              <Tbody>
                {currentShareholders &&
                  currentShareholders.map((shareholder, index) => (
                    <Tr key={index}>
                      <Td>
                        {shareholder.firstName} {shareholder.lastName}
                        {shareholder.corpName} {shareholder.corpAccessNumber}
                        {shareholder.isDirector ? "(D)" : ''}
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          value={shareholder.share}
                          onChange={(e) =>
                            handleShareChange(index, e.target.value, "current")
                          }
                          borderColor={
                            shareholder.share > 0
                              ? currentTotal < 99.999
                                ? "red"
                                : "gray"
                              : "red"
                          }
                        />
                      </Td>
                      <Td>%</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {currentTotal < 99.999 && (
              <Text color="red">Total shares must add up to 100% (99.999 if 3 shareholders)</Text>
            )}

            <Heading as="h4">Resulting Shareholders</Heading>
            <Table variant="striped" size="sm">
              <Tbody>
                {resultShareholders &&
                  resultShareholders.map((shareholder, index) => (
                    <Tr key={index}>
                      <Td>
                        {shareholder.firstName} {shareholder.lastName}
                        {shareholder.corpName} {shareholder.corpAccessNumber}
                        {shareholder.isDirector ? "(D)" : ''}
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          value={shareholder.share}
                          onChange={(e) =>
                            handleShareChange(index, e.target.value, "result")
                          }
                          borderColor={
                            shareholder.share > 0
                              ? resultTotal < 99.999
                                ? "red"
                                : "gray"
                              : "red"
                          }
                        />
                      </Td>
                      <Td>%</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {resultTotal < 99.999 && (
              <Text color="red">Total shares must add up to 100% (99.999 if 3 shareholders)</Text>
            )}
          </VStack>
        ): <Heading as="h4" color="red">Fill Shareholders section first</Heading>}
    </Box>
  );
};

export default Shares;
