import React, { useState, useEffect } from "react";
import {Box,
  Table,
  Text,
  VStack,
  Th,
  Thead,
  Tr,
  Td,
  Tbody,
  Select,
  Button,
  useToast,
} from "@chakra-ui/react";
import { getAllForms } from "../services/admin/forms.js";

const ServiceForms = ({ modify, service, setService }) => {
  const toast = useToast();
  const [forms, setForms] = useState([]);
  const [availableForms, setAvailableForms] = useState([]);
  useEffect(() => {
    const loadForms = async () => {
      try {
        const response = await getAllForms();
        if (response.status === 200) {
          setForms(response.data.forms);
          setAvailableForms(response.data.forms);
        } else {
          // Handle error using toast
          toast({
            title: "Error",
            description: "Failed to load forms.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        // Handle error using toast
        console.log(error);
        toast({
          title: "Error Try again",
          description: "Failed to load forms. Maybe Server error",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
    loadForms();
  }, []);

  const handleAddForm = (selectedForm) => {
    if (
      !service.formsRequired.find((form) => form.name === selectedForm.name) && selectedForm !== "Select"
    ) {
      setService((prevService) => ({
        ...prevService,
        formsRequired: [...prevService.formsRequired, selectedForm],
      }));
      setAvailableForms(
        availableForms.filter(
          (form) => !(selectedForm.name === form.name)
        )
      );
    }
  };

  const handleDeleteForm = (form) => {
    setService((prevService) => ({
      ...prevService,
      formsRequired: prevService.formsRequired.filter((f) => f !== form),
    }));
    setAvailableForms((prevAvailableForms) => [
      ...prevAvailableForms,
      form,
    ]);
  };

  return (
    <VStack spacing={4} align="start">
      <Text fontSize="xl" fontWeight="bold">
        Forms
      </Text>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Form Name</Th>
            <Th>View Form</Th>
            <Th>View Guide</Th>
            {modify && <Th>Delete</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {service.formsRequired?.map((form, index) => (
            <Tr key={index}>
              <Td>{form.name}</Td>
              <Td>
                <a href={form.formLink}>{form.formLink}</a>
              </Td>
              <Td>
                <a href={form.guideLink}>{form.guideLink}</a>
              </Td>
              {modify && (
                <Td>
                  <Button
                    colorScheme="red"
                    variant="solid"
                    onClick={() => handleDeleteForm(form)}
                  >
                    Delete
                  </Button>
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {modify && (
        <Box>
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            Select a form to Add
          </Text>
          <Select
            onChange={(e) => {
              const selectedForm = forms.find(
                (form) => form.name === e.target.value
              );
              handleAddForm(selectedForm);
            }}
            defaultValue={"Select"}
          >
            <option value="default">Select a form</option>
            {availableForms.map((form, index) => (
              <option key={index} value={form.name}>
                {form.name}
              </option>
            ))}
          </Select>
        </Box>
      )}
    </VStack>
  );
};

export default ServiceForms;
