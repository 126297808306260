export const snappyCorpPriceList = {
  ChangeDirectors: 25,
  ChangeShareholders: 25,
  ChangePartners: 25,
  ChangeAddress: 25,
  ChangeAgent: 25,
  ChangeName: 200,

  SnappyAnnualSubscription: 250,
  SnappyAgent: 250,

  Named: 500,
  Numbered: 450,
  SoleProprietorship: 50,
  GeneralPartnership: 65,
  LimitedLiabilityPartnership: 150,
  LimitedPartnership: 150,
  ExtraProvincial: 500,
  ExtraProvincialNWPTA: 200,

  ChangeShareStructure: 200,
  AnnualReturns: 80,
  MinuteBook: 100,
  ChangeIndividuals: 25,

  UnanimousShareholderAgreement: 50,
  PartnershipAgreement: 250,      

  CorporateSearch: 20, // Alberta is default

  FederalDiscount: -50,
  NwptaAgentForServiceDiscount: -50,
};
