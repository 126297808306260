import React, { useState } from "react";
import NavBar from "../../../../utils/NavBar";
import {
  Flex,
  HStack,
  Heading,
  VStack,
  Button,
  Divider,
  List,
  ListItem,
  ListIcon,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Textarea,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { handleSendInquiryEmail } from "../../../../services/email.js";
import Disclaimer from "../../../../utils/Disclaimer";

const ChangeName = () => {
  const navigate = useNavigate();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [emailData, setEmailData] = useState({
    address: "",
    message: "",
    name: "",
  });

  const handleSendEmail = async () => {
    (await handleSendInquiryEmail(emailData))
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    onClose();
  };

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" mt="50">
        <VStack align="start" className="container" spacing="4">
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/existingCorporate");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading as="h1">Checklist for Changing Corporate Name</Heading>

          <Text>
            Your operating name is the name you use in your day-to-day
            activities and to advertise your business. You only need to provide
            your operating name if it is different from your legal name.
          </Text>

          <VStack spacing={4}>
            <List>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                First step would be to get an Alberta NUANS an Alberta
                Reservation Report (NUANS report) for the corporation's new
                name, to see if the name is available to be used.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                After NUANS search report we can file the change by filling up
                Articles of Amendment form.
              </ListItem>
            </List>

            {/* <Heading>Some more questions</Heading> */}
          </VStack>
          <Button
            colorScheme="green"
            onClick={onOpen}
            width={{base:"100%", md:"auto"}}
          >
            Contact Us to Start the Process!
          </Button>
        </VStack>
      </Flex>

      <Disclaimer />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" color="green.500">
              We will contact you as soon as possible!
            </Heading>
            <Text>Please provide your details here.</Text>
            <FormControl>
              <FormLabel>Your Name:</FormLabel>
              <Input
                placeholder="Your Name"
                mb={4}
                value={emailData.name}
                onChange={(e) =>
                  setEmailData({ ...emailData, name: e.target.value })
                }
              />
              <FormLabel>Your Email Address:</FormLabel>
              <Input
                placeholder="Email Address"
                mb={4}
                value={emailData.address}
                onChange={(e) =>
                  setEmailData({ ...emailData, address: e.target.value })
                }
              />
              <FormLabel>Your Inquiry:</FormLabel>
              <Textarea
                placeholder="Message"
                rows={4}
                value={emailData.message}
                onChange={(e) =>
                  setEmailData({ ...emailData, message: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button
                variant="ghost"
                color="black"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button colorScheme="green" onClick={handleSendEmail}>
                Submit
              </Button>
              <Button
                colorScheme="yellow"
                variant="solid"
                onClick={() => {
                  window.location.href = "tel:+1(403) 262 9999";
                }}
              >
                Call Us
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChangeName;
