import React, { useState } from "react";
import {
  HStack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Grid,
  Center,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FaPhone, FaEnvelope, FaDirections } from "react-icons/fa";
import { handleSendInquiryEmail } from "../services/email";

const AboutUs = () => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailData, setEmailData] = useState({
    address: "",
    message: "",
    name: "",
  });

  const handleEmailModalOpen = () => {
    setIsEmailModalOpen(true);
  };

  const handleEmailModalClose = () => {
    setIsEmailModalOpen(false);
  };
  const toast = useToast();
  const handleSendEmail = async () => {
    (await handleSendInquiryEmail(emailData))
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    handleEmailModalClose();
  };

  const handlePhoneClick = () => {
    // Implement phone call functionality here
    window.location.href = "tel:+1(234) 567 8950";
  };

  const handleDirectionsClick = () => {
    // Open Google Maps in a new window with default address
    const defaultAddress = "1138+10+Ave+SW,+Calgary,+Alberta+T2R+0B6"; // Your default address here
    window.open(`https://www.google.com/maps/place/${defaultAddress}`);
  };

  return (
    <Flex
      direction={["column", "column", "row"]}
      align="center"
      justifyContent="center"
      bgColor="black"
      py="15"
    >
      <Grid
        className="container"
        templateColumns={{
          base: "1fr",
          sm: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(2, 1fr)",
        }}
        p="10px"
        alignItems="center"
      >
        {/* Right Column (Company Information) */}
        <Box color="white" mb="12px" ml="12px">
          <Heading fontSize="32px !important" color="white !important" mb={10}>
            About Us
          </Heading>
          <Heading fontSize="20px !important" color="white !important" mb={4}>
            Snappy Corporations Corp.
          </Heading>
          <Text fontSize={"12px"} mb="0 !important" fontStyle="italic">
            (located in New Urban Registry)
          </Text>
          <Text fontSize={["sm", "md", "lg"]} mb="0 !important">
            1138 10 Ave SW
            <br />
            Calgary, Alberta T2R 0B6
          </Text>
          <Flex align="center" mt="12px">
            <Icon as={FaPhone} onClick={handlePhoneClick} mr="12px" />
            <Text fontSize={["sm", "md", "lg"]} mb="0 !important">
              {process.env.REACT_APP_PHONE}
            </Text>
          </Flex>
          <Flex align="center">
            <Icon
              as={FaEnvelope}
              onClick={handleEmailModalOpen}
              mr="12px"
              mt="-12px"
            />
            <Text fontSize={["sm", "md", "lg"]}>
              {process.env.REACT_APP_EMAIL}
            </Text>
          </Flex>
        </Box>
        {/* Left Column (Google Maps Location and Directions Button) */}
        <Center>
          <VStack>
            {/* Google Maps Embed */}
            <iframe
              title="Google Maps Location"
              width="100%"
              height="300"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src={`https://maps.google.com/maps?q=1138+10+Ave+SW,+Calgary,+Alberta+T2R+0B6&output=embed`}
            ></iframe>
            {/* Directions Button */}
            <Button
              leftIcon={<Icon as={FaDirections} />}
              mt={4}
              bgColor="white"
              color="black"
              size="lg"
              onClick={handleDirectionsClick}
            >
              Directions
            </Button>
          </VStack>
        </Center>
      </Grid>

      <Modal isOpen={isEmailModalOpen} onClose={handleEmailModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Your Name:</FormLabel>
              <Input
                placeholder="Your Name"
                mb={4}
                value={emailData.name}
                onChange={(e) =>
                  setEmailData({ ...emailData, name: e.target.value })
                }
              />
              <FormLabel> Your Email Address: </FormLabel>
              <Input
                placeholder="Email Address"
                mb={4}
                value={emailData.address}
                onChange={(e) =>
                  setEmailData({ ...emailData, address: e.target.value })
                }
              />
              <FormLabel>Your Message / Questions to us:</FormLabel>
              <Textarea
                placeholder="Message"
                rows={4}
                value={emailData.message}
                onChange={(e) =>
                  setEmailData({ ...emailData, message: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button
                variant="ghost"
                color="black"
                onClick={handleEmailModalClose}
              >
                Cancel
              </Button>
              <Button colorScheme="green" onClick={handleSendEmail}>
                Send Email
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default AboutUs;
