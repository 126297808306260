import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  IconButton,
  HStack,
  ListItem,
  List,
  Button,
  FormLabel,
  Select,
  Input,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const Delete = ({ request, setRequest }) => {
  const [list, setList] = useState(request.directors.deletedDirectors.length >= 1 ? request.directors.deletedDirectorsList : request.directors.currentDirectors);
  const [selectedDirector, setSelectedDirector] = useState("Select");

  useEffect(() => {
    let data = [];

    if (request.directors.deletedDirectors.length >= 1){
      data = request.directors.deletedDirectorsList;
    } else {
      data = request.directors.currentDirectors;
    }

    setList(data);
  }, [request.directors.deletedDirectors, request.directors.currentDirectors]);

  const handleRemoveDirector = () => {
    let deletedDirector = null;

    if (selectedDirector && selectedDirector !== "Select") {
      if (selectedDirector.includes(", ")) {
        const [lastName, firstName] = selectedDirector.split(", ");
        deletedDirector = request.directors.currentDirectors.find(
          (director) =>
            director.firstName === firstName && director.lastName === lastName
        );
      }

      if (deletedDirector) {
        let updatedDeletedDirectors = [
          ...request.directors.deletedDirectors,
          {...deletedDirector, revokeDate:new Date().toISOString().split("T")[0] }
        ];

        let updatedList = list.filter(director => director.key !== deletedDirector.key);

        setRequest((prevRequest) => ({
          ...prevRequest,
          directors: {
            ...prevRequest.directors,
            deletedDirectors: updatedDeletedDirectors,
            deletedDirectorsList: updatedList,
            results: [
              ...prevRequest.directors.results
            ].filter(
              (director) =>
                !updatedDeletedDirectors.some(
                  deleted => deleted.key === director.key
                )
            )
          },
        }));

        setList(updatedList);
      }

      setSelectedDirector(Select);
    }
  };

  const handleRestoreDirector = (key) => {
    const restoredDirector = request.directors.deletedDirectors.find(director => director.key === key);

    setRequest((prevRequest) => ({
      ...prevRequest,
      directors: {
        ...prevRequest.directors,
        deletedDirectors: prevRequest.directors.deletedDirectors.filter(
          director => director.key !== key
        ),
        results: [
          ...prevRequest.directors.results,
          restoredDirector
        ]
      },
    }));

    setList((prevList) => [
      ...prevList,
      restoredDirector
    ]);


    setSelectedDirector(Select);
  };

  const handleRevokeDateChange = (key, value) => {
    setRequest((prevRequest) => {
      const updatedDeletedDirectors = prevRequest.directors.deletedDirectors.map((director) => {
        if (director.key == key){
          director.revokeDate = value;
        }

        return director;
      }
    );

      return {
        ...prevRequest,
        directors: {
          ...prevRequest.directors,
          deletedDirectors: updatedDeletedDirectors,
        },
      };
    });
  };

  return (
    <>
      {console.log(request.directors)}
      <Heading as="h4" color="red.800">
        Removed Directors
      </Heading>

      <Grid
        templateColumns={{ base: "1fr auto", md: "auto auto 1fr" }}
        alignItems="center"
        width="100%"
        gap="2"
      >
        <GridItem colSpan={{base:3, md:1}}>
          <FormLabel>
            Director to be removed:
          </FormLabel>
        </GridItem>
        <Grid>
          <Select
            w={{ base: "100%", md: "30ch" }}
            value={selectedDirector}
            defaultValue="Select"
            onChange={(e) => setSelectedDirector(e.target.value)}
          >
            <option key="0">Select</option>
            {list.map((director, index) => (
              <option key={director.key}>{`${director.lastName}, ${director.firstName}`}</option>
            ))}
          </Select>
        </Grid>
        <GridItem>
          <Button
            colorScheme="red"
            p="2"
            rightIcon={<CloseIcon />}
            onClick={handleRemoveDirector}
            disabled={!selectedDirector}
            aria-label="Remove Director"
          >
            Remove
          </Button>
        </GridItem>
      </Grid>

      <FormLabel mt="12px !important">
        Directors that are being <i>deleted</i> are as follows:
      </FormLabel>

      <Box my={4}>
        <List
          borderBottom="solid 1px gray"
          ml={{base:"0", md:"10%"}}
          w={{base:"100%", md:"60%"}}
          color="red.500"
        >
          {request.directors.deletedDirectors &&
            request.directors.deletedDirectors.map((director, index) => (
              <ListItem key={director.key}>
                <HStack justify="space-between">
                  <Text width="60%" fontWeight="bold" mb="0 !important">
                    {director.firstName} {director.lastName}
                  </Text>
                  <Input
                    type="date"
                    width="150px"
                    value={director.revokeDate}
                    onChange={(e)=> handleRevokeDateChange(director.key, e.target.value)} />
                  <IconButton
                    icon={<CloseIcon />}
                    onClick={() => handleRestoreDirector(director.key)}
                    aria-label="Restore Director"
                  />
                </HStack>
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
};

export default Delete;
