import React from "react";
import {
  Box, 
  Flex, 
  Center, 
  VStack, 
  HStack, 
  Image, 
  Grid, 
  Button,
  Text, 
  Select
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

const Banner = (props) => {

  const navigate = useNavigate();

  const handleChangeGotoPage = (value) => {
    navigate(value);
  };

  return (
    <Flex className="home-banner" justifyContent="center" alignItems="center">
      <VStack className="container" alignItems="left">
        <HStack gap="4">
          <Link to={"/"}>
            <Box textAlign="left" my="-24px">
              <Image className="logo" title="Snappy Corporations Logo" src="/images/logo.png" width={{base:"90px", lg:"250px"}}  />
            </Box>
          </Link>
          <Box className="bannerGotoButtons">
            <Center>
              <VStack w="100%">
                <Flex justifyContent="center">
                  <Text color="white !important" fontSize="24px !important">
                    Already know what you need? Please choose from the following:
                  </Text>
                </Flex>
                <Grid
                  templateColumns={{
                    base: "1fr",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(5, 1fr)",
                  }}
                  gap={2}
                >
                  <Button
                    colorScheme="red"
                    color={"white"}
                    size="lg"
                    p="25px"
                    fontSize="14px"
                    onClick={() => {
                      navigate("/main/corp-type");
                    }}
                  >
                    Corporation
                  </Button>
                  <Button
                    colorScheme="yellow"
                    bgColor={"yellow.600"}
                    color={"white"}
                    size="lg"
                    p="25px"
                    fontSize="14px"
                    onClick={() => {
                      navigate("/main/new/trade-name");
                    }}
                  >
                    Trade Name
                  </Button>
                  <Button
                    colorScheme="green"
                    color={"white"}
                    size="lg"
                    p="25px"
                    fontSize="14px"
                    onClick={() => {
                      navigate("/main/new/partnership");
                    }}
                  >
                    Partnership
                  </Button>
                  <Button
                    colorScheme="blue"
                    color={"white"}
                    size="lg"
                    p="25px"
                    fontSize="14px"
                    onClick={() => {
                      navigate("/main/location/extra-provincial");
                    }}
                  >
                    Extra-Provincial
                  </Button>
                  <Button
                    colorScheme="purple"
                    color={"white"}
                    size="lg"
                    p="25px"
                    fontSize="14px"
                    onClick={() => {
                      navigate("/main/new/nonprofit-societies");
                    }}
                  >
                    Non-Profit / Societies
                  </Button>
                </Grid>
              </VStack>
            </Center>
          </Box>
          <Box className="bannerGotoSelect" width="100%">
            <Flex justifyContent="right">
              <Select
                placeholder='Go to a Service'
                w={{ base: "auto" }}
                value=""
                onChange={(e) => handleChangeGotoPage(e.target.value)}
              >
                <option value="/main/corp-type">Corporation</option>
                <option value="/main/new/trade-name">Trade Name</option>
                <option value="/main/new/partnership">Partnership</option>
                <option value="/main/location/extra-provincial">Extra-Provincial</option>
                <option value="/main/new/nonprofit-societies">Non-Profit/Societies</option>
              </Select>
            </Flex>
          </Box>
        </HStack>
      </VStack>
    </Flex>
  );
};

export default Banner;
