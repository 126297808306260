import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  VStack,
  Text,
  List,
  ListItem,
  ListIcon,
  Divider,
  Button,
  Image,
  Spacer,
  Center,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Input,
  FormControl,
  FormLabel,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  CheckCircleIcon,
} from "@chakra-ui/icons";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";
import PriceTag from "../../../utils/PriceTag.js";
import { useNavigate } from "react-router-dom";
import { handleSendInquiryEmail } from "../../../services/email.js";
import { snappyCorpPriceList } from "../../../services/price.js";

const Help = () => {
  const toast = useToast();
  const [emailData, setEmailData] = useState({
    address: "",
    message: "",
    name: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSendEmail = async () => {
    (await handleSendInquiryEmail(emailData))
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    onClose();
  };
  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing="4">
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading as="h1">Our Featured Services</Heading>

          <Box position="relative" width="100%">
            <Grid
              templateColumns={{ base: '1fr', lg: 'auto 1fr' }}
              gap={3}
              alignItems="center"
            >
              <GridItem>
                <Heading as="h2" color="blue.500">
                  Introducing Snappy Subscriptions
                </Heading>
              </GridItem>
              <GridItem>
                <PriceTag
                  basePrice="450"
                  updatedPrice={snappyCorpPriceList.SnappyAnnualSubscription}
                  offerExpiryDate="30th September 2024"
                />
              </GridItem>
            </Grid>

            <Text mt={2}>
              We came up with a new subscription service. If you buy our Snappy
              Subscription annual management package, you get to file following services with us UNLIMITED times for one year:
            </Text>
            <br />
            <Grid
              templateColumns={{ base: '1fr', md: '1fr 1fr' }}
              gap="24px"
            >
              <GridItem order={{ base: 2, md: 1 }}>
                <VStack align="start">
                  <Heading as="h3" mt={4} color="green.500">
                    What is Included:
                  </Heading>
                  <List>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Shareholders (${snappyCorpPriceList.ChangeShareholders} per change)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Directors (${snappyCorpPriceList.ChangeDirectors} per change)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Agent for Service (${snappyCorpPriceList.ChangeAgent} per change)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Address (${snappyCorpPriceList.ChangeAddress} per change)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      File annual return (${snappyCorpPriceList.AnnualReturns} per year)
                    </ListItem>
                  </List>
                </VStack>
              </GridItem>
              <GridItem order={{ base: 1, md: 2 }}>
                <Flex
                  justifyContent={{base:"center", md:"right"}}
                >
                  <Image
                    src="/images/AI-photos/annual-subscription.png"
                    alt="Annual Subscription"
                    boxShadow="md"
                    width="350px !important"
                  />
                </Flex>
              </GridItem>
            </Grid>

            <Center mt="50px">
              <Button
                colorScheme="purple"
                onClick={() => {
                  navigate("annualSubscription");
                }}
              >
                Learn more!
              </Button>
            </Center>
          </Box>

          <Divider />

          <Box position="relative" width="100%">
            <Grid
              templateColumns={{ base: '1fr', lg: 'auto 1fr' }}
              alignItems="center"
            >
              <GridItem>
                <Heading as="h2" color="blue.500">
                  Agent for Service
                </Heading>
              </GridItem>
              <GridItem>
                <PriceTag
                  basePrice="340"
                  updatedPrice={snappyCorpPriceList.SnappyAgent}
                  offerExpiryDate="30th June 2024"
                />
              </GridItem>
            </Grid>
            
            <Text mt={2}>
              All corporations in Alberta must appoint an Agent for Service. An
              Agent for Service is an individual located in Alberta who can
              accept notices and documents in person or by mail on behalf of the
              corporation. The Agent For Service does not need to be a lawyer,
              they can be someone directly involved with the corporation (like a
              shareholder, director or officer), or it can be an individual
              completely independent from the corporation. The only requirement
              is that the individual must have an address in Alberta that can
              accept notices and documents in person or by mail on behalf of the
              corporation.
            </Text>
            <br />
            <Grid
              templateColumns={{ base: '1fr', md: '1fr 1fr' }}
              gap="24px"
            >
              <GridItem order={{ base: 2, md: 1 }}>
                <VStack align="start">
                  <Heading as="h3" mt={4} color="green.500">
                    What We Will Do:
                  </Heading>
                  <List>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Accept documents on your behalf.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      The public can come here for information about your
                      corporation.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Ensure all legal notices are handled efficiently.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Provide timely notifications about important documents.
                    </ListItem>
                  </List>
                </VStack>
              </GridItem>
              <GridItem order={{ base: 1, md: 2 }}>
                <Flex
                  justifyContent={{base:"center", md:"right"}}
                >
                  <Image
                    src="/images/AI-photos/agent-for-service.png"
                    alt="Agent For Service"
                    boxShadow="md"
                    width="350px !important"
                  />
                </Flex>
              </GridItem>
            </Grid>

            <Center mt="50px">
              <Button colorScheme="purple" onClick={onOpen}>
                Inquire / Request now!
              </Button>
            </Center>
          </Box>

          <Divider />

          <Box position="relative" width="100%">
            <Grid
              templateColumns={{ base: '1fr', lg: 'auto 1fr' }}
              gap={3}
              alignItems="center"
            >
              <GridItem>
                <Heading as="h2" color="blue.500">
                  First Time Meeting Minutes
                </Heading>
              </GridItem>
              <GridItem>
                <PriceTag
                  basePrice="190"
                  updatedPrice={snappyCorpPriceList.MinuteBook}
                  offerExpiryDate="30th June 2024"
                />
              </GridItem>
            </Grid>

            <Text mt={2}>
              Meeting minutes are a written record of the discussions,
              decisions, and actions taken during a meeting of either the
              directors or officers of the corporation. They are essential for
              documenting the company's activities and ensuring compliance with
              legal requirements. We will get your records ready in a file and
              you can have everything related to your company in one place,
              which helps you manage it more easily.
            </Text>
            <Heading as="h3" mt={4} color="green.500">
              Why You Need It:
            </Heading>
            <List>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Ensure compliance with legal requirements.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Provide a clear record of decisions and actions taken.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Facilitate better communication and transparency within the
                corporation.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Assist in resolving disputes by providing a documented history.
              </ListItem>
            </List>
            <Center>
              <Button colorScheme="purple" onClick={onOpen}>
                Inquire / Request now!
              </Button>
            </Center>
          </Box>

          <Divider />

          <Box>
            <Heading as="h2" color="blue.500">
              Upcoming Services
            </Heading>
            <Text mt={2}>
              We are constantly working to bring you more valuable services.
              Stay tuned for:
            </Text>
            <List>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Online Minute Book
              </ListItem>
            </List>
          </Box>
        </VStack>
      </Flex>

      <Center>
        <HStack justifyContent="space-between" spacing="4">
          <Button
            className="btn-black"
            onClick={() => {
              navigate("terms");
            }}
          >
            Terms and Conditions
          </Button>
          <Button
            className="btn-black"
            onClick={() => {
              navigate("disclaimer");
            }}
          >
            Disclaimer
          </Button>
        </HStack>
      </Center>

      <Disclaimer />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" color="green.500">
              We will contact you as soon as possible!
            </Heading>
            <Text>Please provide your details here.</Text>
            <FormControl>
              <FormLabel>Your Name:</FormLabel>
              <Input
                placeholder="Your Name"
                mb={4}
                value={emailData.name}
                onChange={(e) =>
                  setEmailData({ ...emailData, name: e.target.value })
                }
              />
              <FormLabel>Your Email Address:</FormLabel>
              <Input
                placeholder="Email Address"
                mb={4}
                value={emailData.address}
                onChange={(e) =>
                  setEmailData({ ...emailData, address: e.target.value })
                }
              />
              <FormLabel>Your Inquiry:</FormLabel>
              <Textarea
                placeholder="Message"
                rows={4}
                value={emailData.message}
                onChange={(e) =>
                  setEmailData({ ...emailData, message: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button
                variant="ghost"
                color="black"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button colorScheme="green" onClick={handleSendEmail}>
                Submit
              </Button>
              <Button
                colorScheme="yellow"
                variant="solid"
                onClick={() => {
                  window.location.href = "tel:+1(403) 262 9999";
                }}
              >
                Call Us
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Help;
