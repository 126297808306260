import React from "react";

import {
  Box,
  FormControl,
  Heading,
  Input,
  HStack,
  Select,
  FormLabel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
const IncServInformation = ({ request, setRequest }) => {
  const handleChangeBusinessInfo = (field, value) => {
    let price = request.prices.default;

    if (field === "province") {
      if (value === "AB") price = 20;
      else if (value === "BC") price = 35.52;
      else if (value === "MB") price = 30;
      else if (value === "NB") price = 25;
      else if (value === "NL") price = 15.75;
      else if (value === "NT") price = 29;
      else if (value === "NS") price = 28.42;
      else if (value === "NU") price = 20;
      else if (value === "PE") price = 20;
      else if (value === "QC") price = 20;
      else if (value === "Federal") price = 20;
      else if (value === "SK") price = 35.05;
      else if (value === "YK") price = 40;
      else if (value === "ON") {
        if (request.businessInfo.type === "Corporation") {
          price = 92.0;
        } else if (request.businessInfo.type === "Trade Name / Partnership") {
          price = 49.43;
        }
      }
    } else if (field === "type") {
      if (request.businessInfo.province === "Ontario") {
        if (value === "Corporation") {
          price = 92.0;
        } else if (value === "Trade Name / Partnership") {
          price = 49.43;
        }
      }
    }

    setRequest((prevRequest) => ({
      ...prevRequest,
      businessInfo: {
        ...prevRequest.businessInfo,
        [field]: value,
      },
      prices: {
        ...prevRequest.prices,
        default: price
      }
    }));

    request.updatePrice();
  };
  
  const provinces = [
    {name: "Alberta", value: "AB"},
    {name: "British Columbia", value: "BC"},
    {name: "Manitoba", value: "MB"},
    {name: "New Brunswick", value: "NB"},
    {name: "Newfoundland and Labrador", value: "NL"},
    {name: "Northwest Territories", value: "NT"},
    {name: "Nova Scotia", value: "NS"},
    {name: "Nunavut", value: "NU"},
    {name: "Ontario", value: "ON"},
    {name: "Prince Edward Island", value: "PE"},
    {name: "Quebec", value: "QC"},
    {name: "Saskatchewan", value: "SK"},
    {name: "Yukon", value: "YK"},
    {name: "Federal (Canada)", value: "Federal"},
  ];

  return (
    <>
      <Heading mb="15px">Business Information</Heading>

      <FormControl>
        <Grid
          templateColumns={{ base: "1fr", md: "auto 1fr" }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <FormLabel>Business name</FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.businessName}
              onChange={(e) =>
                handleChangeBusinessInfo("businessName", e.target.value)
              }
            />
          </GridItem>

          <GridItem>
            <FormLabel>Province</FormLabel>
          </GridItem>
          <GridItem>
            <HStack>
              <Select
                w={{ base: "100%", md: "30ch" }}
                value={request.businessInfo.province}
                onChange={(e) =>
                  handleChangeBusinessInfo("province", e.target.value)
                }
              >
                {provinces.map((province, index) => (
                  <option key={index} value={province.value}>
                    {province.name}
                  </option>
                ))}
              </Select>
              <FormLabel>
                <span className="price">{`$${request.prices.default.toFixed(
                  2
                )} CAD`}</span>
              </FormLabel>
            </HStack>
          </GridItem>

          <GridItem>
            <FormLabel>Business type</FormLabel>
          </GridItem>
          <GridItem>
            <Select
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.type}
              onChange={(e) => handleChangeBusinessInfo("type", e.target.value)}
            >
              <option>Trade Name / Partnership</option>
              <option>Corporation</option>
            </Select>
          </GridItem>

          <GridItem>
            <FormLabel>Corporate Access Number is (optional)</FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.corporateAccessNumber}
              onChange={(e) =>
                handleChangeBusinessInfo(
                  "corporateAccessNumber",
                  e.target.value
                )
              }
            />
          </GridItem>

          <GridItem>
            <FormLabel>Historical date (optional)</FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              type="date"
              placeholder="yyyy-mm-dd"
              value={request.businessInfo.historicalDate}
              onChange={(e) =>
                handleChangeBusinessInfo("historicalDate", e.target.value)
              }
            />
          </GridItem>
        </Grid>
      </FormControl>

    </>
  );
};
export default IncServInformation;
