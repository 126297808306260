import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Text,
  Input,
  IconButton,
  HStack,
  Divider,
  ListItem,
  List,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Switch,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import Delete from "./directors/Delete";
import YesNoField from "../../../../utils/YesNoField";
import Province from "../../../../utils/Province";

const DirectorsAndOfficersInfo = ({ request, setRequest, native }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isNewDirector, setIsNewDirector] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    key: Date.now(),
    firstName: "",
    lastName: "",
    appointmentDate: new Date().toISOString().split("T")[0],
    phone: "",
    email: "",
    streetLine1: "",
    streetLine2: "",
    city: "",
    postalCode: "",
    country: "Canada",
    province: "AB",
    revokeDate: "",
    changeName: false,
    newName: "",
  });

  const [yesChangeDirectors, setYesChangeDirectors] = useState(native);
  const [noChangeDirectors, setNoChangeDirectors] = useState(false);

  const handleYesNoChange = (value, type) => {
    request.togglePrice(value, type);

    if (value === "Yes") {
      setYesChangeDirectors(true);
      setNoChangeDirectors(false);
    } else {
      setYesChangeDirectors(false);
      setNoChangeDirectors(true);
    }
  };

  const enabled = request.prices.ChangeDirectors.enabled;

  const handleDirectorAdd = (isNew) => {
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      appointmentDate: isNew ? new Date().toISOString().split("T")[0] : "",
      phone: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "AB",
      revokeDate: "",
      changeName: false,
      newName: "",
    });
    setIsNewDirector(isNew);
    setIsEditable(false);
    setShowModal(true);
  };

  const handleEditOfficerDirector = (director, type) => {
    setFormData({ ...director });
    setIsEditable(true);
    setIsNewDirector(type === "new");
    setShowModal(true);
  };

  const handleDeleteCurrentOfficerDirector = (key) => {
    if (window.confirm("Are you sure you want to delete this current director?")) {
      const updatedCurrentDirectors = request.directors.currentDirectors.filter(
        (director) => director.key !== key
      );

      setRequest((prevRequest) => ({
        ...prevRequest,
        directors: {
          ...prevRequest.directors,
          currentDirectors: updatedCurrentDirectors,
          results: [
            ...updatedCurrentDirectors,
            ...prevRequest.directors.newDirectors
          ].filter(
            (director) =>
              !prevRequest.directors.deletedDirectors.some(
                deleted => deleted.key === director.key
              )
          ),
        }
      }));
    
      setFormData({
        key: Date.now(),
        firstName: "",
        lastName: "",
        appointmentDate: isNewDirector ? new Date().toISOString().split("T")[0] : "",
        phone: "",
        email: "",
        streetLine1: "",
        streetLine2: "",
        city: "",
        postalCode: "",
        country: "Canada",
        province: "AB",
        revokeDate: "",
        changeName: false,
        newName: "",
      });
    }
  };

  const handleDeleteNewOfficerDirector = (key) => {
    if (window.confirm("Are you sure you want to delete this new director?")) {
      const updatedNewDirectors = request.directors.newDirectors.filter(
        (director) => director.key !== key
      );

      setRequest((prevRequest) => ({
        ...prevRequest,
        directors: {
          ...prevRequest.directors,
          newDirectors: updatedNewDirectors,
          results: [
            ...prevRequest.directors.currentDirectors,
            ...updatedNewDirectors,
          ].filter(
            (director) =>
              !prevRequest.directors.deletedDirectors.some(deleted => deleted.key === director.key)
          ),
        }
      }));
    
      setFormData({
        key: Date.now(),
        firstName: "",
        lastName: "",
        appointmentDate: isNewDirector ? new Date().toISOString().split("T")[0] : "",
        phone: "",
        email: "",
        streetLine1: "",
        streetLine2: "",
        city: "",
        postalCode: "",
        country: "Canada",
        province: "AB",        
        changeName: false,
        newName: "",
      });
    }
  };

  // PROBABLY DONT NEED THIS ANYMORE! 
  // useEffect(() => {
  //   const updatedResults = [
  //     ...request.directors.currentDirectors,
  //     ...request.directors.newDirectors,
  //   ].filter(
  //     (director) =>
  //       !request.directors.deletedDirectors.some(
  //         (deleted) => deleted.key === director.key
  //       )
  //   );

  //   setRequest((prevRequest) => ({
  //     ...prevRequest,
  //     directors: {
  //       ...prevRequest.directors,
  //       results: updatedResults,
  //     },
  //   }));
  // }, [
  //   request.directors.currentDirectors,
  //   request.directors.newDirectors,
  //   request.directors.deletedDirectors,
  // ]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // Check if all required fields are filled
    const requiredFields = [
      "firstName",
      "lastName",
      "phone",
      "email",
      "streetLine1",
      "city",
      "postalCode",
      "province",
      "country",
    ];

    if (isNewDirector) {
      requiredFields.push("appointmentDate");
    }

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      alert("Please fill all required fields.");
      return;
    }

    if (formData.changeName && !formData.newName) {
      alert("Please provide the new name.");
      return;
    }

    setRequest((prevRequest) => {
      const updatedCurrentDirectors = isNewDirector
        ? prevRequest.directors.currentDirectors
        : isEditable
        ? prevRequest.directors.currentDirectors.map((director) =>
            director.key === formData.key
              ? { ...director, ...formData }
              : director
          )
        : [...prevRequest.directors.currentDirectors, formData];

      const updatedNewDirectors = isNewDirector
        ? isEditable
          ? prevRequest.directors.newDirectors.map((director) =>
              director.key === formData.key
                ? { ...director, ...formData }
                : director
            )
          : [...prevRequest.directors.newDirectors, formData]
        : prevRequest.directors.newDirectors;

      return {
        ...prevRequest,
        directors: {
          ...prevRequest.directors,
          currentDirectors: updatedCurrentDirectors,
          newDirectors: updatedNewDirectors,
        },
        results: [
          ...updatedCurrentDirectors, 
          ...updatedNewDirectors
        ].filter(
          (director) =>
            !prevRequest.directors.deletedDirectors.some(
              (deleted) => deleted.key === director.key
            )
        ),
      };
    });

    setShowModal(false);
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "AB",
      appointmentDate: "",
      changeName: false,
      newName: "",
    });
    setIsEditable(false);
  };

  const handleClose = () => {
    setIsEditable(false);
    setShowModal(false);
  };

  return (
    <>
      <Heading mb="15px">Director(s) Information</Heading>
    
      {!native && (

        <YesNoField
          label={"Are there any changes in directors?"}
          setValue={(value) => handleYesNoChange(value, "ChangeDirectors")}
          price={request.prices.ChangeDirectors.value}
          enabled={enabled}
        />
      )}

      {noChangeDirectors && <Text mt="12px !important">All set!</Text>}

      {(yesChangeDirectors || enabled === true) && (
        <>
          <Divider h="20px" />

          <Box>
            <HStack justify={"space-between"} mb={4}>
              <Heading as="h3" color="blue.700">
                Current Directors:
              </Heading>
              <Button
                colorScheme="blue"
                variant="solid"
                leftIcon={<AddIcon />}
                onClick={() => handleDirectorAdd(false)}
              >
                Add
              </Button>
            </HStack>
            {request.directors.currentDirectors &&
              request.directors.currentDirectors.length > 0 && (
                <Box my={4}>
                  <List
                    borderBottom="solid 1px gray"
                    ml={{base:"0", md:"10%"}}
                    w={{base:"100%", md:"60%"}}
                    color="blue.500"
                  >
                    {request.directors.currentDirectors.map(
                      (director, index) => (
                        <ListItem key={director.key} mb={3}>
                          <HStack justify="space-between">
                            <Text fontWeight="bold" mb="0 !important">
                              {director.firstName} {director.lastName}
                            </Text>
                            <HStack>
                              <IconButton
                                icon={<EditIcon />}
                                onClick={() =>
                                  handleEditOfficerDirector(director, "current")
                                }
                              />
                              <IconButton
                                icon={<DeleteIcon />}
                                onClick={() =>
                                  handleDeleteCurrentOfficerDirector(director.key)
                                }
                              />
                            </HStack>
                          </HStack>
                        </ListItem>
                      )
                    )}
                  </List>

                  <Divider h="50px" />

                  <Heading as="h3">Changes</Heading>

                  <HStack justify={"space-between"} mb={4}>
                    <Heading as="h4" color="green.700">
                      New Directors
                    </Heading>
                    <Button
                      colorScheme="green"
                      variant="solid"
                      leftIcon={<AddIcon />}
                      onClick={() => handleDirectorAdd(true)}
                    >
                      Add
                    </Button>
                  </HStack>
                  <List
                    borderBottom="solid 1px gray"
                    ml={{base:"0", md:"10%"}}
                    w={{base:"100%", md:"60%"}}
                    color="green.500"
                  >
                    {request.directors.newDirectors.map((director, index) => (
                      <ListItem key={director.key} mb={3}>
                        <HStack justify="space-between">
                          <Text fontWeight="bold" mb="0 !important">
                            {director.firstName} {director.lastName}
                          </Text>
                          <HStack>
                            <IconButton
                              icon={<EditIcon />}
                              onClick={() =>
                                handleEditOfficerDirector(director, "new")
                              }
                            />
                            <IconButton
                              icon={<DeleteIcon />}
                              onClick={() =>
                                handleDeleteNewOfficerDirector(director.key)
                              }
                            />
                          </HStack>
                        </HStack>
                      </ListItem>
                    ))}
                  </List>

                  <br />

                  <Delete request={request} setRequest={setRequest} />
                </Box>
              )}
          </Box>
        </>
      )}

      <Modal isOpen={showModal} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditable
              ? "Edit Director"
              : isNewDirector
              ? "Add New Director"
              : "Add Current Director"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>First Name:</FormLabel>
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleFormChange}
                mb="4"
              />
              <FormLabel>Last Name:</FormLabel>
              <Input
                name="lastName"
                value={formData.lastName}
                onChange={handleFormChange}
                mb="4"
              />
              {isNewDirector && (
                <>
                  <FormLabel>Appointment Date:</FormLabel>
                  <Input
                    type="date"
                    placeholder="yyyy-mm-dd"
                    name="appointmentDate"
                    value={formData.appointmentDate}
                    borderBottom="solid 1px black"
                    onChange={handleFormChange}
                    mb="4"
                  />
                </>
              )}
              <FormLabel>Phone:</FormLabel>
              <Input
                name="phone"
                value={formData.phone}                
                onChange={handleFormChange}
                mb="4"
              />
              <FormLabel>Email:</FormLabel>
              <Input
                name="email"
                value={formData.email}                
                onChange={handleFormChange}
                mb="4"
              />
            </FormControl>

            <Text fontWeight="bold">Residential Address (updated or current)</Text>
            {formData && (
              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>Address Line 1</FormLabel>
                    <Input                      
                      name="streetLine1"
                      value={formData.streetLine1}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl>
                    <FormLabel>Address Line 2</FormLabel>
                    <Input
                      name="streetLine2"
                      value={formData.streetLine2}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>City</FormLabel>
                    <Input
                      name="city"
                      value={formData.city}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>Postal Code</FormLabel>
                    <Input
                      name="postalCode"
                      value={formData.postalCode}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>Province</FormLabel>
                    <Province
                      width="auto"
                      name="province"
                      value={formData.province}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>Country</FormLabel>
                    <Input
                      name="country"
                      value={formData.country}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            )}
            {!isNewDirector && (
              <HStack>
                <Text mt="20px !important">
                  Has this director changed the name?
                </Text>
                <Switch
                  ml="12px"
                  isChecked={formData.changeName}
                  onChange={() => {
                    setFormData((prevData) => ({
                      ...prevData,
                      changeName: !formData.changeName,
                      newName: "",
                    }));
                  }}
                />
              </HStack>
            )}
            {formData.changeName && (
              <>
                <FormLabel>New Name:</FormLabel>
                <Input
                  placeholder="New Name"
                  name="newName"
                  value={formData.newName}
                  borderBottom="solid 1px black"
                  onChange={handleFormChange}
                  mb="4"
                  isRequired
                />
                <Text color="green.500" fontWeight="bold">
                  Use the new name above and attach a letter stating:
                </Text>
                <Text fontStyle="italic">
                  "Director's name is being changed from (__current name__)
                  to (__new name__) because of (___reason___like marriage__)."
                </Text>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={isEditable ? "blue" : "green"}
              onClick={handleSubmit}
            >
              {isEditable ? "Update" : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  );
};

export default DirectorsAndOfficersInfo;
