import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  components: {
    Switch: {
      variants: {
        redThumb: {
          thumb: {
            bg: 'orange.00',
          },
        },
      },
    },
  },
  colors: {
    brand: {
      50: '#f7fafc',
      500: '#718096',
      900: '#171923',
    },
  },
  styles: {
    global: {
      '.chakra-switch': {
        border: '1px solid',
        borderColor: 'green.900', // Dark green color
        borderRadius: "25px"
      },
    },
  },
});

export default theme;
