import React, { useState } from "react";

import {
  Flex,
  Heading,
  Text,
  Input,
  HStack,
  Divider,
  FormLabel,
  Textarea,
  Grid,
  GridItem,
  FormControl
} from "@chakra-ui/react";
import InfoButton from "../../../../utils/Popover";
import YesNoField from "../../../../utils/YesNoField";

const Articles = ({ request, setRequest, companyType }) => {

  const handleChangeArticlesInfo = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      articlesInfo: {
        ...prevRequest.articlesInfo,
        [field]: value,
      },
    }));
  };

  const [NURArticles, setNURArticles] = useState("");

  const handleYesNoNURChange = (value, type) => {
    request.toggleYesNo(value, type);

    if (value ==='Yes'){
      setRequest((prevReq) => ({
        ...prevReq,
        articlesInfo: {
          ...prevReq.articlesInfo,
          isSnappyCorp: true,
          articleRequirements: "Snappy Corp"
        },
        reviewFieldHidden: {
          ...prevReq.reviewFieldHidden,
          [companyType]: {
            ...prevReq.reviewFieldHidden[companyType],
            articlesInfo: {
              ...prevReq.reviewFieldHidden[companyType].articlesInfo,
              articleRequirements: undefined,
              minDirector: false,
              maxDirector: false,
              classOfShares: false,
              restrictShareTransfer: false,
              restrictBusinessArea: false,
              otherRulesProv: false
            }
          }
        }
      }));
      setNURArticles(true);
    } else {
      setRequest((prevReq) => ({
        ...prevReq,
        articlesInfo: {
          ...prevReq.articlesInfo,
          isSnappyCorp: false,
          articleRequirements: ""
        },
        reviewFieldHidden: {
          ...prevReq.reviewFieldHidden,
          [companyType]: {
            ...prevReq.reviewFieldHidden[companyType],
            articlesInfo: {
              ...prevReq.reviewFieldHidden[companyType].articlesInfo,
              articleRequirements: false,
              minDirector: undefined,
              maxDirector: undefined,
              classOfShares: undefined,
              restrictShareTransfer: undefined,
              restrictBusinessArea: undefined,
              otherRulesProv: undefined
            }
          }
        }
      }));
      setNURArticles(false);
    }
  };

  const ownEnabled = request.yesno.Articles_Own.enabled;
  const templateEnabled = request.yesno.Articles_Template.enabled;

  return (
    <>
      
      <Heading>Articles of Incorporation</Heading>

      <Text>
        The Articles of Incorporation are the rules of the corporation and form its foundation. The Articles of Incorporation may specify the name of the corporation, classes of shares, maximum number of shares, any restriction to share transfers, a minimum/maximum number of directors and any types of business from which the corporation is restricted.
      </Text>
      <Text>
        Articles of Incorporation are <b>legally required</b> as part of the
        incorporation process in Alberta.
      </Text>

      <Divider className="subdivider" />

        <YesNoField
          label={"Would you like to use generic articles of incorporation?"}
          text={
            "It will specify a minimum and maximum number of directors, class, share structure and maximum number of shares. By employing such articles, you agree to accept all liability associated therewith."
          }
          setValue={(value) => handleYesNoNURChange(value, "Articles_Template")}
          enabled={templateEnabled}
        />
     

      {((NURArticles === true) ||
        (ownEnabled === false && templateEnabled === true)) && (
        <>
          <FormLabel mt="12px !important" color="blue.500">
            Please enter any requirements for the agreement: (if any)
          </FormLabel>
          <Flex>
            <Textarea
              h="300px"
              border={"solid 2px orange"}
              autoFocus
              value={request.articlesInfo.articleRequirements}
              onChange={(e) =>
                setRequest((prevReq) => ({
                  ...prevReq,
                  articlesInfo: {
                    ...prevReq.articlesInfo,
                    articleRequirements: e.target.value,
                  }
                }))
              }
            />
          </Flex>

          <Text color="green.500" fontWeight="bold" mt="24px !important">
             Articles will be attached.
          </Text>

          <Text>Thanks!</Text>
        </>
      )}
      {(( NURArticles === false) ||
        (ownEnabled === false && templateEnabled === false)) && (
        <>
          <br />
          <FormControl>
            <Grid
              templateColumns={{ base: '1fr', md: 'auto 1fr' }}
              gap={4}
              alignItems="center"
            >
              <GridItem>
                <FormLabel mb={{ base:"-6px !important", md:"6px !important" }}>
                  Minimum number of directors
                </FormLabel>
              </GridItem>
              <GridItem>
                <Input
                  w={{ base: "6ch", md: "6ch" }}
                  type="number"
                  value={request.articlesInfo.minDirector}
                  onChange={(e) =>
                    handleChangeArticlesInfo("minDirector", e.target.value)
                  }
                />
              </GridItem>

              <GridItem>
                <FormLabel mb={{ base:"-6px !important", md:"6px !important" }}>
                  Maximum number of directors
                </FormLabel>
              </GridItem>
              <GridItem>
                <Input
                  w={{ base: "6ch", md: "6ch" }}
                  type="number"
                  value={request.articlesInfo.maxDirector}
                  onChange={(e) =>
                    handleChangeArticlesInfo("maxDirector", e.target.value)
                  }
                />
              </GridItem>
            </Grid>
          </FormControl>
          <br />

          <HStack>
            <FormLabel>
              The class of shares and any maximum number of shares that the
              corporation is authorized to issue.
            </FormLabel>
            <InfoButton info='This section is also known as Schedule "A"' />
          </HStack>

          <Textarea
            mb={6}
            value={request.articlesInfo.classOfShares}
            onChange={(e) =>
              handleChangeArticlesInfo("classOfShares", e.target.value)
            }
            h="130px"
            w={{ base: "100%", md: "100%" }}
          />

          <HStack>
            <FormLabel>
              Restrictions on share transfers (if any)
            </FormLabel>
            <InfoButton info="Usually incorporators would say shares can only be transfered with other shareholders agreeing to it." />
          </HStack>
          <Textarea
            mb={6}
            value={request.articlesInfo.restrictShareTransfer}
            onChange={(e) =>
              handleChangeArticlesInfo("restrictShareTransfer", e.target.value)
            }
            h="130px"
            w={{ base: "100%", md: "100%" }}
          />

          <HStack>
            <FormLabel>
              If the corporation is restricted from carrying on a certain
              business, or restricted to carrying on a certain business, specify
              the restriction(s):
            </FormLabel>
          </HStack>
          <Textarea
            mb={6}
            value={request.articlesInfo.restrictBusinessArea}
            onChange={(e) =>
              handleChangeArticlesInfo("restrictBusinessArea", e.target.value)
            }
            h="130px"
            w={{ base: "100%", md: "100%" }}
          />
          <HStack>
            <FormLabel>
              Other rules or provisions (if any):
            </FormLabel>
            <InfoButton info={'This section is also known as Schedule "B"'} />
          </HStack>
          <Textarea
            mb={6}
            value={request.articlesInfo.otherRulesProv}
            onChange={(e) =>
              handleChangeArticlesInfo("otherRulesProv", e.target.value)
            }
            h="130px"
            w={{ base: "100%", md: "100%" }}
          />
        </>
      )}
      <Divider />
    </>
  );
};
export default Articles;
