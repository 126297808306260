import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  VStack,
  Button,
  HStack,
  Center,
  Divider,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import NavBar from "../../../../utils/NavBar";
import Payment from "../../../new/register/Payment";
import DirectorsAndOfficersInfo from "./Directors";
import AgentForService from "./AgentForService";
import AccountInformation from "./AccountInformation";
import AddOns from "./AddOns";
import IncServInformation from "./BusinessInfo";
import ShareHolders from "./Shareholders";
import Disclaimer from "../../../../utils/Disclaimer";
import { snappyCorpPriceList } from "../../../../services/price";

const ExistingCorpServices = ({ bType }) => {
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const [request, setRequest] = useState({
    serviceType: "ChangeCorporateIndividuals",
    price: snappyCorpPriceList.ChangeIndividuals,
    accountInfo: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
    businessInfo: {
      type: "",
      businessName: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta",
      fiscalYear: "December",
    },
    directors: {
      currentDirectors: [],
      newDirectors: [],
      deletedDirectors: [],
      modifiedDirectors: [],
      results: [],
    },
    shareholders: {
      currentShareholders: [],
      newShareholders: [],
      deletedShareholders: [],
      modifiedShareholders: [],
      results: [],
    },
    agent: "",
  });

  //   // Define sections for each company type
  const sectionsMap = [
    "accountInformation",
    "businessInformation",
    "directors",
    "shareholders",
    "agentForService",
    "addOns",
    "payment",
  ];

  const handlePrevious = () => {
    setSelectedSectionIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNext = () => {
    setSelectedSectionIndex((prevIndex) =>
      Math.min(prevIndex + 1, sectionsMap.length - 1)
    );
  };

  const renderMainComponent = () => {
    const sections = sectionsMap;
    const selectedSection = sections[selectedSectionIndex];
    switch (selectedSection) {
      case "accountInformation":
        return <AccountInformation request={request} setRequest={setRequest} />;
      case "businessInformation":
        return <IncServInformation request={request} setRequest={setRequest} />;
      case "agentForService":
        return <AgentForService request={request} setRequest={setRequest} />;
      case "addOns":
        return <AddOns request={request} setRequest={setRequest} />;
      case "payment":
        return <Payment request={request} />;
      case "directors":
        return (
          <DirectorsAndOfficersInfo request={request} setRequest={setRequest} />
        );
      case "shareholders":
        return <ShareHolders request={request} setRequest={setRequest} />;
      default:
        return null;
    }
  };

  return (
    <>
      <NavBar />

      <Center mt="40px">
        <VStack alignItems="flex-start" className="container">
          <HStack w="90%" justifyContent="min-content">
            <Heading>Changes in Corporation</Heading>
          </HStack>

          <Divider className="divider" />

          <Flex w="100%">
            {/* Sidebar */}
            <Box
              className="sidebar"
              w="25%"
            >
              <VStack align="start">
                {sectionsMap?.map((section, index) => (
                  <Button
                    key={index}
                    onClick={() => setSelectedSectionIndex(index)}
                    variant={selectedSectionIndex === index ? "solid" : "ghost"}
                    bg={selectedSectionIndex === index ? "green.700" : ""}
                    w="100%"
                    color={selectedSectionIndex === index ? "white" : ""}
                    m={0}
                    _hover={{
                      bg:
                        selectedSectionIndex === index
                          ? "green.700"
                          : "green.400",
                      color: "white", // Set text color for better contrast
                    }}
                  >
                    {section
                      .replace("account", "personal")
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .replace(/^\w/, (c) => c.toUpperCase())}{" "}
                  </Button>
                ))}
              </VStack>
            </Box>

            {/* Main bar */}
            <Box w="75%" p={4} ml={2}>
              {renderMainComponent()}
              {/* Previous and Next Button */}

              <Flex pt="24px">
                {selectedSectionIndex !== 0 && (
                  <Button
                    className="btn-black"
                    leftIcon={<ArrowLeftIcon />}
                    onClick={handlePrevious}
                    aria-label="Previous"
                    mr={2}
                    disabled={selectedSectionIndex === 0 || !sectionsMap}
                  >
                    Previous
                  </Button>
                )}
                {selectedSectionIndex < sectionsMap.length - 1 && (
                  <Button
                    className="btn-black"
                    rightIcon={<ArrowRightIcon />}
                    onClick={handleNext}
                    aria-label="Next"
                    disabled={
                      selectedSectionIndex === sectionsMap?.length - 1 ||
                      !sectionsMap
                    }
                  >
                    Next
                  </Button>
                )}
              </Flex>
            </Box>
          </Flex>
        </VStack>
      </Center>

      <Disclaimer />
    </>
  );
};

export default ExistingCorpServices;
