import React from "react";
import {
  Grid,
  GridItem,
  Center,
  Flex,
  Heading,
  Button,
  Box,
  HStack,
  Image,
  ListItem,
  ListIcon,
  List,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@chakra-ui/icons";

import StartPageCard from "./utils/StartPCard";
import BannerLine from "./utils/BannerLine.js";

const Temp = () => {
  const navigate = useNavigate();
  const mainServicesData1 = [
    {
      heading: "Amend Corporation",
      img: "/images/buildings.png",
      list: [
        "Change Directors",
        "Change Shareholders",
        "Change Agent for Service",
        "Change Corporate Addresses (mailing address, records address)",
        "Change Name, share structure or articles of incorporation",
      ],
    },
    {
      heading: "Amend Partnership",
      img: "/images/buildings.png",
      list: ["Change or Modify Partners", "Change Address"],
    },
  ];
  const mainServicesData2 = [
    {
      heading: "Annual Returns",
      img: "/images/buildings.png",
      list: [
        "File Annual Returns",
        "Update Shareholders (no extra cost)",
        "Update Agent for Service",
      ],
    },
    {
      heading: "Corporate Search",
      img: "/images/buildings.png",
      list: [
        "Search information about Alberta corporations",
        "Articles of Incorporation, certificate of company's status",
      ],
    },
    {
      heading: "Extend your business in Alberta",
      img: "/images/buildings.png",
      list: ["Ite", "Extra-Provincial RegistrationF"],
    }]
    const otherServicesData = [
    {
      heading: "Amalgamate Corporations",
      img: "/images/buildings.png",
      list: [
        "Amalgamate two or more corporations",
        "Previous corporations will cease to exist as a new corporation is created.",
      ],
    },
    {
      heading: "Move your business to Alberta",
      img: "/images/buildings.png",
      list: [
        "Register your business in Alberta",
        "Moving in to Alberta, move your business as well",
      ],
    },
    {
      heading: "Cancel / Dissolve Corporation",
      img: "/images/buildings.png",
      list: [
        "Dissolve Alberta Corporation",
        "Cancel Extra Provincial Registration",
      ],
    },
  ];

  return (
    <div className="home-main">
      <Flex spacing="100px" justifyContent="center" paddingBottom="80px">
        <HStack className="container">
          <Box w="50%" paddingRight="50px">
            <Center>
              <Image src="/images/buildings.png" />
            </Center>
          </Box>
          <Box w="50%">
            <Heading as="h2" fontSize="3xl">
              Start a new business
            </Heading>
            <List mb={7}>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Corporation
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Trade Name / Sole Proprietorship
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Partnership (General / Limited)
              </ListItem>
            </List>
            <Button
              className="btn-black"
              onClick={() => {
                navigate("/main");
              }}
            >
              Let's get started !
            </Button>
          </Box>
        </HStack>
      </Flex>

      <BannerLine
        line="Want to file your annual returns?"
        linkText="Click here !"
        link="/annualReturn"
      />




      <Heading align="center">Ammendments</Heading>
      <Center>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(2, 1fr)",
          }}
          gap={6}
          p={6}
        >
          {mainServicesData1.map((card, index) => (
            <GridItem key={index} colSpan={{ base: 1, md: 1, lg: 1 }}>
              <StartPageCard {...card} />
            </GridItem>
          ))}
        </Grid>
      </Center>








      {/* popular services */}
      <Heading align="center" mt="10">Popular Services</Heading>
      <Center>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={6}
          p={6}
        >
          {mainServicesData2.map((card, index) => (
            <GridItem key={index} colSpan={{ base: 1, md: 1, lg: 1 }}>
              <StartPageCard {...card} />
            </GridItem>
          ))}
        </Grid>
      </Center>


      {/* other services */}
      <Heading align="center" mt="10">Other Common Services</Heading>
      <Center>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={6}
          p={6}
        >
          {otherServicesData.map((card, index) => (
            <GridItem key={index} colSpan={{ base: 1, md: 1, lg: 1 }}>
              <StartPageCard {...card} />
            </GridItem>
          ))}
        </Grid>
      </Center>
    </div>
  );
};

export default Temp;
