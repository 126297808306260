import React, { useState } from "react";
import {
  Flex,
  Heading,
  HStack,
  VStack,
  Text,
  List,
  ListItem,
  ListIcon,
  Divider,
  Button,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckCircleIcon } from "@chakra-ui/icons";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";
import { useNavigate } from "react-router-dom";
import { handleSendInquiryEmail } from "../../../services/email.js";
import ModalDisclaimer from "../../../utils/ModalDisclaimer.js";
const Amalgamate = () => {
  const toast = useToast();
  const [emailData, setEmailData] = useState({
    address: "",
    message: "",
    name: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSendEmail =async () => {
    (await handleSendInquiryEmail(emailData))
      
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    onClose();
  };
  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing={4}>
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/services");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading as="h1">Amalgamate Corporations</Heading>

          <Heading as="h2">
            Things to know before amalgamating corporations:
          </Heading>

          <Text>
            When an amalgamation takes place, two or more Alberta corporations join together and continue as one new corporation. Most amalgamations take place under Section 181 of the Business Corporations Act (BCA) but amalgamations may also be effected by way of a court-approved arrangement, under Section 193 of the BCA. However, an arrangement cannot be effected under Section 193 unless it is impractical to adopt it under any other section of the BCA.
          </Text>
          <Text>
            Once the amalgamation has been registered on CORES, the property of each of the corporations taking part in an amalgamation (amalgamation predecessors) becomes the property of the newly-formed successor, or amalgamated corporation. The successor corporation continues to be liable for the obligations of each of its predecessors. This is especially true when one of the amalgamation predecessors was an unlimited liability corporation (ULC).
          </Text>
          <Text>
            The corporations that take part in an amalgamation may be:
            <br /><br />
            <ul style={{marginBottom:0}}>
              <li>Two or more unrelated Alberta corporations.</li>
              <li>An Alberta holding corporation and one or more of its wholly-owned Alberta subsidiaries (vertical short-form amalgamation).</li>
              <li>Two or more wholly-owned Alberta subsidiaries of the same holding corporation (horizontal short-form amalgamation).</li>
              <li>An Alberta corporation and an extra-provincial corporation where one is a wholly-owned subsidiary of the other (cross-border amalgamation).</li>
            </ul>
          </Text>
          <Text>
            An amalgamation can occur between Alberta limited corporations and/or Alberta unlimited liability corporations (ULCs). The resulting successor corporation can either be a limited corporation or a ULC. When one of the amalgamating corporations is a ULC, certain ULC obligations, as described in Section 15.6 of the BCA, remain in effect following the amalgamation, whether or not the successor corporation is also a ULC.
          </Text>

          <List>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              You will be permitted to conduct business under a single name (either a new name can be chosen or you can keep the same name as one of the amalgamating companies).
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Both the corporations will be working together under one name moving forward as a single corporation.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              Once the corporations are amalgamated, you only have to take care of one corporation instead of many.
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="green.500" />
              The liability for all obligations and liabilities of the business shall be shifted to the new corporation. Please speak to a lawyer for more details and to clarify any questions you may have.
            </ListItem>
          </List>

          <Button colorScheme="green" onClick={onOpen}>
            Contact us directly
          </Button>

          <Divider height="2vh" />
         
        </VStack>
      </Flex>

      <Disclaimer />

      <ModalDisclaimer />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" color="green.500">
              We will contact you as soon as possible!
            </Heading>
            <Text>Please provide your details here.</Text>
            <FormControl>
              <FormLabel>Your Name:</FormLabel>
              <Input
                placeholder="Your Name"
                mb={4}
                value={emailData.name}
                onChange={(e) =>
                  setEmailData({ ...emailData, name: e.target.value })
                }
              />
              <FormLabel>Your Email Address:</FormLabel>
              <Input
                placeholder="Email Address"
                mb={4}
                value={emailData.address}
                onChange={(e) =>
                  setEmailData({ ...emailData, address: e.target.value })
                }
              />
              <FormLabel>Your Inquiry:</FormLabel>
              <Textarea
                placeholder="Message"
                rows={4}
                value={emailData.message}
                onChange={(e) =>
                  setEmailData({ ...emailData, message: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button
                variant="ghost"
                color="black"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button colorScheme="green" onClick={handleSendEmail}>
                Submit
              </Button>
              <Button
                colorScheme="yellow"
                variant="solid"
                onClick={() => {
                  window.location.href = "tel:+1(403) 262 9999";
                }}
              >
                Call Us
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Amalgamate;
