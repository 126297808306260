import axios from "axios";

const uri = `${process.env.REACT_APP_BACKEND_KEY}/reminders`;

const createReminder = async (reminder) => {
  try {
    const response = await axios.post(`${uri}/crud`, { reminder });

    return response;
  } catch (error) {
    return error.response;
  }
};

export { createReminder };
