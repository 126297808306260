import { Box, Button, Text, Spacer, HStack, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { ColorModeSwitcher } from "../utils/ColorModeSwitcher.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaHome, FaSignOutAlt } from "react-icons/fa";
import { MdAccountBox } from "react-icons/md";
import { logoutEmployee } from "../redux/actions/auth.js";

const EmpBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const employee = useSelector((state) => state.employee).state;
  const handleLogout = () => {
    // Dispatch the logout action here
    dispatch(logoutEmployee());
    // Redirect to the login page or any desired route
    navigate("/clerk");
  };
  useEffect(() => {
    if (!employee || !employee.name) navigate("/employee");
  }, [employee]);

  return (
    <>
      {employee && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="4"
        >
          {/* Left side */}
          <Box mr="4">
            {employee && (
              <HStack margin="20px">
                <Text fontSize="150%">Hey,</Text>
                <Heading fontSize="200%" color={"blue.500"}>
                  {employee.name}
                </Heading>
              </HStack>
            )}
          </Box>
          <Spacer />
          {/* Right Side */}
          <HStack>
            {/* Dashboard | home link */}
            {employee.isAdmin && (
              <Button
                p="4"
                m="1"
                colorScheme="yellow"
                variant={"solid"}
                rightIcon={<MdAccountBox />}
                onClick={() => {
                  navigate("/employee/admin/dashboard");
                }}
              >
                Admin Dashboard
              </Button>
            )}
            {/* Requests */}
            <Button
              rightIcon={<FaHome />}
              onClick={() => {
                navigate("/employee/dashboard");
              }}
              p="4"
              m="1"
              colorScheme="teal"
              variant={"solid"}
            >
              Manage Requests
            </Button>
            {/* Manage my account */}
            <Button
              rightIcon={<MdAccountBox />}
              onClick={() => {
                navigate("/employee/manage-my-account");
              }}
              p="4"
              m="1"
              colorScheme="black"
              variant={"outline"}
            >
              Manage your Account
            </Button>
            {/* Color Mode */}
              <ColorModeSwitcher />
            {/* Logout button */}
            <Button
              rightIcon={<FaSignOutAlt />}
              size="md"
              p="4"
              m="1"
              colorScheme="orange"
              variant={"solid"}
              onClick={() => {
                handleLogout();
              }}
            >
              Logout
            </Button>
          </HStack>
        </Box>
      )}
    </>
  );
};

export default EmpBar;
