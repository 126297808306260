import React, { useState } from "react";
import {
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import MainDisclaimer from "../home/services/help/Disclaimer";

const ModalDisclaimer = ({ pageTitle }) => {

  const [modalOpen, setModalOpen] = useState(true);  

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleAcknowledge = () => {
    window.disclaimerClicked = true;
    closeModal();
  };

  return  (window.disclaimerClicked === false && (
    <Modal isOpen={modalOpen} onClose={closeModal} closeOnOverlayClick={false} >
      <ModalOverlay />
    
      <ModalContent padding="24px">
        <ModalHeader>
          <Heading align="center">Disclaimer</Heading>
        </ModalHeader>
        <ModalBody>
          <MainDisclaimer />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" onClick={handleAcknowledge}>
            I acknowledge
          </Button>
        </ModalFooter>
      </ModalContent>
  
    </Modal>)
  );
};

export default ModalDisclaimer;
