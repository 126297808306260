import React from "react";
import { Heading, Box, Text, Divider, Center} from "@chakra-ui/react";
import AboutUs from "../../AboutUs";
import NavBar from "../../../utils/NavBar";
import { Link } from "react-router-dom";

const MainDisclaimer = ({fullPage}) => {
  return (
    <>
      { fullPage && (<NavBar />) }

      { !fullPage && (
      <Text fontSize="14px" fontWeight="bold" color="blue.500" mb="24px">
        By clicking the “Acknowledge” button below, you hereby acknowledge and agree that:
      </Text>
      )}
      
      <Center m={0} mt={fullPage ? "40px" : "0"} className="maindisclaimer">
        <Box
          className="container"
          height={ !fullPage ? "250px" : "auto" }
          width={ !fullPage ? "100%" : "" }
          borderWidth={ !fullPage ? "1px" : "auto" }
          borderRadius={ !fullPage ? "md" : "auto" }
          boxShadow={ !fullPage ? "md" : "auto" }
          overflowX={ !fullPage ? "hidden" : "hidden" }
          overflowY={ !fullPage ? "auto" : "hidden" }
          padding={ !fullPage ? "12px" : "0" }
          mx={ !fullPage ? "-12px" : "0" }
        >

          { fullPage && (
            <>
            <Heading as="h1">
              Disclaimer
            </Heading>

            <Divider className="divider" />
            </>
          )}

          <Text fontSize={ !fullPage ? "14px !important" : "inherit" } mb={ fullPage ? "48px !important" : "12px !important" }>
            Snappy Corporations Corp. (“Snappy”) are not legal professionals and this website and the tools contained herein are not intended, nor should they be in any way interpreted, as financial, investment, tax, legal, account or other advice of any kind.
            <br /><br />
            Any services provided by Snappy and any information or content they contain or that results from the use of the services, are provided solely for informational purposes and without limiting the foregoing, any content or output generated through the use of the services is provided solely for informational purposes and is not intended to provide specific financial, investment, tax, legal, account or other advice to you, and should not be acted or relied upon in that regard without seeking the advice of a professional;
            <br /><br />
            You are advised to seek legal or other professional advice by contacting a lawyer, accountant or other relevant professional; and your use of or access to Snappy Corporations' website and/or services does not create a lawyer-client or other relationship of trust;
            <br /><br />    
            You are aware of and accept all financial, investment, tax, legal, accounting or and all other risks of any kind associated with the registration and operation of your business.
            <br /><br />
            By continuing through our website, you are acknowledging and confirming that: (i) you are the age of majority in the place where you live and (ii) you have read and agree to Snappy’s Terms and Conditions which can be found at the following link: <Link to={"/help/terms"}>Terms and Conditions</Link>. These Terms and Conditions take effect on that date that you acknowledge and confirm on this screen. You cannot use this website or access any services provided by Snappy unless you acknowledge that you have read and agreed to Snappy’s Terms and Conditions.
          </Text>
        </Box>
      </Center>

      { fullPage && (<AboutUs />) }
    </>
  );
};

export default MainDisclaimer;
