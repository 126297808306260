import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  VStack,
  Text,
  List,
  ListItem,
  ListIcon,
  Divider,
  Center,
  Image,
  Button,
  useToast,
  useDisclosure,
  Modal,
  FormLabel,
  Input,
  ModalFooter,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Textarea,
  ModalContent,
  ModalHeader,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import PriceTag from "../../../utils/PriceTag.js";
import Disclaimer from "../../../utils/Disclaimer.js";
import NavBar from "../../../utils/NavBar.js";
import { snappyCorpPriceList } from "../../../services/price.js";
import { handleAnnualSubscriptionEmail } from "../../../services/email.js";

const AnnualSubscription = () => {
  const toast = useToast();

  const [emailData, setEmailData] = useState({
    address: "",
    comments: "",
    phone: "",
    corporationName: "",
    name: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSendEmail = async () => {
    (await handleAnnualSubscriptionEmail(emailData))
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    onClose();
  };
  // const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing="4">
          <Box position="relative" width="100%">
            <Grid
              templateColumns={{ base: '1fr', lg: 'auto 1fr' }}
              gap={3}
              alignItems="center"
            >
              <GridItem>
                <Heading as="h1">Snappy Subscriptions</Heading>
              </GridItem>
              <GridItem>              
                <PriceTag
                  basePrice="350"
                  updatedPrice={snappyCorpPriceList.SnappyAnnualSubscription}
                  offerExpiryDate="30th June 2024"
                />
              </GridItem>
            </Grid>

            <Text>
              Unlock the full potential of your business with our comprehensive
              Annual Subscription Service. Enjoy a hassle-free experience with
              all the essential changes and filings covered in one package.
            </Text>

            <br />
            
            <Grid
              templateColumns={{ base: '1fr', md: '1fr 1fr' }}
              gap="24px"
            >
              <GridItem order={{ base: 2, md: 1 }}>
                <VStack align="start">
                  <Heading as="h3" color="green.500 !important">
                    What’s Included:
                  </Heading>
                  <List>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Shareholders ($
                      {snappyCorpPriceList.ChangeShareholders} per change)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Directors (${snappyCorpPriceList.ChangeDirectors} per
                      change)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Agent for Service (${
                        snappyCorpPriceList.ChangeAgent
                      }{" "}
                      per change)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Address (${snappyCorpPriceList.ChangeAddress} per
                      change)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      File annual return (${snappyCorpPriceList.AnnualReturns} per
                      year)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Records Address (${
                        snappyCorpPriceList.ChangeAddress
                      }{" "}
                      per change)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Mailing Address (${
                        snappyCorpPriceList.ChangeAddress
                      }{" "}
                      per change)
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Change Registered Office Address ($
                      {snappyCorpPriceList.ChangeAddress} per change)
                    </ListItem>
                  </List>
                  <Text mb="48px !important">
                    All these services are included in our Annual Subscription for
                    just{" "}
                    <span className="price">
                      ${snappyCorpPriceList.SnappyAnnualSubscription}
                    </span>
                    .
                  </Text>
                </VStack>
              </GridItem>
              <GridItem order={{ base: 1, md: 2 }}>
                <Flex
                  justifyContent={{base:"center", md:"right"}}
                >
                  <Image
                    src="/images/AI-photos/annual-subscription.png"
                    alt="Annual Subscription"
                    width="350px !important"
                  />
                </Flex>
              </GridItem>
            </Grid>
            
          </Box>
           
          <Divider />

          <Box width="100%">
            <Heading as="h3" color="blue.500 !important" mt={4}>
              Benefits of the Annual Subscription
            </Heading>
            <Text mt={2}>
              Our Annual Subscription Service offers several advantages:
            </Text>
            <List>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Cost-effective: save money with our bundled services.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Convenience: one subscription covers all your needs.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Peace of Mind: ensure compliance with all legal requirements.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                Priority Support: get faster responses and priority handling of
                your requests.
              </ListItem>
            </List>
          </Box>

          <Divider />

          <Box width="100%">
            <Heading as="h2" color="gray">
              Snappy Disclaimer
            </Heading>
            <Text color="gray">
              Please note that our Annual Subscription Service does not include
              legal advice. Any changes and filings are conducted based on the
              information provided by you. Please ensure all information is
              accurate and complete before submission.
            </Text>
          </Box>

          <Center width="100%" mt={6}>
            <Button className="btn-black" size="lg" onClick={onOpen}>
              Subscribe Now
            </Button>
          </Center>
        </VStack>
      </Flex>

      <Disclaimer />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" color="green.500">
              We will contact you as soon as possible!
            </Heading>
            <Text>Please provide your details here.</Text>
            <FormControl>
              <FormLabel>Your Name:</FormLabel>
              <Input
                placeholder="Your Name"
                mb={4}
                value={emailData.name}
                onChange={(e) =>
                  setEmailData({ ...emailData, name: e.target.value })
                }
              />
              <FormLabel>Your Email Address:</FormLabel>
              <Input
                placeholder="Email Address"
                mb={4}
                value={emailData.address}
                onChange={(e) =>
                  setEmailData({ ...emailData, address: e.target.value })
                }
              />
              <FormLabel>Your Phone number:</FormLabel>
              <Input
                placeholder="403-xxx-9999"
                mb={4}
                value={emailData.phone}
                onChange={(e) =>
                  setEmailData({ ...emailData, phone: e.target.value })
                }
              />
              <FormLabel>Your Corporation Name:</FormLabel>
              <Input
                placeholder="Corporation Name"
                mb={4}
                value={emailData.corporationName}
                onChange={(e) =>
                  setEmailData({
                    ...emailData,
                    corporationName: e.target.value,
                  })
                }
              />
              <FormLabel>Other comments / message:</FormLabel>
              <Textarea
                placeholder="Message"
                rows={4}
                value={emailData.comments}
                onChange={(e) =>
                  setEmailData({ ...emailData, comments: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button
                variant="ghost"
                color="black"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button colorScheme="green" onClick={handleSendEmail}>
                Submit
              </Button>
              <Button
                colorScheme="yellow"
                variant="solid"
                onClick={() => {
                  window.location.href = "tel:+1(403) 262 9999";
                }}
              >
                Call Us
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnnualSubscription;
