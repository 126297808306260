import React from "react";
import { Box, Flex, Heading, Text, Button, VStack } from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import EmpBar from "./EmpBar";

const Dashboard = () => {
  // Sample data for the line chart

  const chartData = [
      { day: "2024-03-19", requests: 27, completed: 20 },
      { day: "2024-03-20", requests: 12, completed: 10 },
      { day: "2024-03-21", requests: 19, completed: 15 },
      { day: "2024-03-22", requests: 28, completed: 22 },
      { day: "2024-03-23", requests: 9, completed: 8 },
      { day: "2024-03-24", requests: 17, completed: 13 },
      { day: "2024-03-25", requests: 23, completed: 19 },
      { day: "2024-03-26", requests: 16, completed: 12 },
      { day: "2024-03-27", requests: 30, completed: 26 },
      { day: "2024-03-28", requests: 14, completed: 11 },
      { day: "2024-03-29", requests: 21, completed: 18 },
      { day: "2024-03-30", requests: 8, completed: 6 },
      { day: "2024-03-31", requests: 11, completed: 9 },
      { day: "2024-04-01", requests: 25, completed: 22 },
      { day: "2024-04-02", requests: 17, completed: 14 },
      { day: "2024-04-03", requests: 13, completed: 10 }
  ];
  

  return (
    <>
      <EmpBar />
      <Box p={8}>
        {/* Line Chart */}
        <Box mb={8}>
          <Heading mb={4}>Requests Over the Last 15 Days</Heading>
          <LineChart width={800} height={300} data={chartData}>
            <XAxis dataKey="day" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="requests"
              stroke="#3182CE"
              name="Requests"
            />
            <Line
              type="monotone"
              dataKey="completed"
              stroke="#E53E3E"
              name="Completed"
            />
          </LineChart>
        </Box>

        {/* Request Sections */}
        <Flex mb={8}>
          <VStack align="start" spacing={4}>
            <Box>
              <Text fontWeight="bold">Requests This Month:</Text>
              <Text fontSize="xl">77</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Requests Pending:</Text>
              <Text fontSize="xl">8</Text>
            </Box>
          </VStack>

          <VStack align="start" ml={8} spacing={4}>
            <Box>
              <Text fontWeight="bold">Requests Haven't Started from past 2 days:</Text>
              <Text fontSize="xl">2</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">New Requests:</Text>
              <Text fontSize="xl">7</Text>
            </Box>
          </VStack>
        </Flex>

        {/* View Requests Button */}
        <Button colorScheme="teal">View Requests</Button>
      </Box>
    </>
  );
};

export default Dashboard;
