import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  IconButton,
  HStack,
  ListItem,
  List,
  Button,
  FormLabel,
  Select,
  GridItem,
  Grid,
  Input
} from "@chakra-ui/react";
import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";

const Delete = ({ request, setRequest }) => {
  const [list, setList] = useState(request.shareholders.deletedShareholders.length >= 1 ? request.shareholders.deletedShareholdersList : request.shareholders.currentShareholders);
  const [selectedShareholder, setSelectedShareholder] = useState("Select");

  useEffect(() => {
    let data = [];

    if (request.shareholders.deletedShareholders.length >= 1){
      data = request.shareholders.deletedShareholdersList;
    } else {
      data = request.shareholders.currentShareholders;
    }

    setList(data);
  }, [request.shareholders.deletedShareholders, request.shareholders.currentShareholders]);

  const handleRemoveShareholder = () => {
    let deletedShareholder = null;
    
    if (selectedShareholder && selectedShareholder !== "Select") {
      
      if (/\d/.test(selectedShareholder)) {
        const [corpName, corpAccessNumber] = selectedShareholder.split(", ");
        deletedShareholder = request.shareholders.currentShareholders.find(
          (shareholder) =>
            shareholder.corpName === corpName && shareholder.corpAccessNumber === corpAccessNumber
        );
      } else {
        const [lastName, firstName] = selectedShareholder.split(", ");
        deletedShareholder = request.shareholders.currentShareholders.find(
          (shareholder) =>
            shareholder.firstName === firstName && shareholder.lastName === lastName
        );
      }

      if (deletedShareholder) {
        let updatedDeletedShareholders = [
          ...request.shareholders.deletedShareholders,
          {...deletedShareholder, revokeDate:new Date().toISOString().split("T")[0] }
        ];

        let updatedList = list.filter(shareholder => shareholder.key !== deletedShareholder.key);

        setRequest((prevRequest) => ({
          ...prevRequest,
          shareholders: {
            ...prevRequest.shareholders,
            deletedShareholders: updatedDeletedShareholders,
            deletedShareholdersList: updatedList,
            results: [
              ...prevRequest.shareholders.results
            ].filter(
              (shareholder) =>
                !updatedDeletedShareholders.some(
                  deleted => deleted.key === shareholder.key
                )
            )
          },
        }));

        setList(updatedList);
      }

      setSelectedShareholder(Select);
    }
  };

  const handleRestoreShareholder = (key) => {
    const restoredShareholder = request.shareholders.deletedShareholders.find(shareholder => shareholder.key === key);

    setRequest((prevRequest) => ({
      ...prevRequest,
      shareholders: {
        ...prevRequest.shareholders,
        deletedShareholders: prevRequest.shareholders.deletedShareholders.filter(
          shareholder => shareholder.key !== key
        ),
        results: [
          ...prevRequest.shareholders.results,
          restoredShareholder
        ]
      },
    }));

    setList((prevList) => [
      ...prevList,
      restoredShareholder
    ]);

    setSelectedShareholder(Select);
  };

  const handleRevokeDateChange = (key, value) => {
    setRequest((prevRequest) => {
     const updatedDeletedShareholders = prevRequest.shareholders.deletedShareholders.map((shareholder) => {
         if (shareholder.key == key){
          shareholder.revokeDate = value;
         }

         return shareholder;
       }
     );

     return {
       ...prevRequest,
       shareholders: {
         ...prevRequest.shareholders,
         deletedShareholders: updatedDeletedShareholders
       },
     };
   });
 };


  return (
    <>
      <Heading as="h4" color="red.800">
        Removed Shareholders
      </Heading>

      <Grid
        templateColumns={{ base: "1fr auto", md: "auto auto 1fr" }}
        alignItems="center"
        width="100%"
        gap="2"
      >
        <GridItem colSpan={{base:3, md:1}}>
          <FormLabel>
            Shareholder to be removed:
          </FormLabel>
        </GridItem>
        <GridItem>
          <Select
            w={{ base: "100%", md: "30ch" }}
            value={selectedShareholder}
            defaultValue="Select"
            onChange={(e) => setSelectedShareholder(e.target.value)}
          >
            <option key="0">Select</option>
            {list.map((shareholder, index) => (
              <option key={shareholder.key}>{`${shareholder.lastName}${shareholder.corpName}, ${shareholder.firstName}${shareholder.corpAccessNumber}`}</option>
            ))}
          </Select>
        </GridItem>
        <GridItem>
          <Button
            colorScheme="red"
            p="2"
            rightIcon={<CloseIcon />}
            onClick={handleRemoveShareholder}
            disabled={!selectedShareholder}
            aria-label="Remove Shareholder"
          >
            Remove
          </Button>
        </GridItem>
      </Grid>

      <FormLabel mt="12px !important">
        Shareholders that are being <i>deleted</i> are as follows:
      </FormLabel>

      <Box my={4}>
        <List
          borderBottom="solid 1px gray"
          ml={{base:"0", md:"10%"}}
          w={{base:"100%", md:"60%"}}
          color="red.500"
        >
          {request.shareholders.deletedShareholders &&
            request.shareholders.deletedShareholders.map((shareholder, index) => (
              <ListItem key={shareholder.key}>
                <HStack justify="space-between">
                  <Text width="60%" fontWeight="bold" mb="0 !important">
                    {shareholder.firstName} {shareholder.lastName}
                    {shareholder.corpName} {shareholder.corpAccessNumber}
                  </Text>
                  <Input
                    type="date"
                    width="150px"
                    value={shareholder.revokeDate}
                    onChange={(e) => handleRevokeDateChange(shareholder.key, e.target.value)}
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => handleRestoreShareholder(shareholder.key)}
                    aria-label="Restore Shareholder"
                  />
                </HStack>
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  );
};

export default Delete;
