import React, { useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Heading,
  VStack,
  Button,
  Divider,
  List,
  ListItem,
  ListIcon,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Input,
  FormControl,
  FormLabel,
  GridItem,
  Grid
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";
import { handleSendInquiryEmail } from "../../../services/email.js";
import ModalDisclaimer from "../../../utils/ModalDisclaimer.js";

const ContinueIn = () => {
  const toast = useToast();
  const [emailData, setEmailData] = useState({
    address: "",
    message: "",
    name: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSendEmail =async () => {
    (await handleSendInquiryEmail(emailData))
      
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    onClose();
  };

  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <Flex justifyContent="center" mt="50px">
        <Box className="container">
          <VStack align="start" spacing={5}>
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>

            <Divider className="divider" />

            <Heading as="h1">
              Conducting Business in Alberta from Extra-Provincial Registration{" "}
            </Heading>

            <Heading as="h2">
              Decide on one of the following options for conducting your
              business in Alberta:
            </Heading>

            <Grid
              templateColumns={{ base: '1fr', md: '1fr 1fr'}}
              gap={10}
              alignItems="center"
              justifyContent="space-between"              
              width="100%"
            >
              <GridItem>
                <Heading as="h2" color="blue.500 !important">
                  Transfer / Continue
                </Heading>
                <Text>
                  - Close your corporation in your home jurisdiction.
                  <br />
                  - Transfer your business in Alberta
                  <br />
                  - Will be able to operate in Alberta.
                </Text>
                <Button
                  width="100%"
                  colorScheme="blue"
                  color={"white"}
                  size="lg"
                  fontSize="18px"
                  onClick={onOpen}
                >
                  Contact Us to Start The Process
                </Button>
              </GridItem>
              <GridItem>
                <Heading as="h2" color="green.500 !important">
                  Extra-Provincial Registration
                </Heading>
                <Text>
                  - Continue operating in home jurisdiction.
                  <br />
                  - Register an extra-provincial corporation in Alberta.
                  <br />
                  - Operate in multiple provinces.
                </Text>
                <Button
                  width="100%"
                  colorScheme="green"
                  color={"white"}
                  size="lg"
                  fontSize="18px"
                  onClick={() => {
                    navigate("/main/location/extra-provincial");
                  }}
                >
                  Register Extra-Provincial
                </Button>
              </GridItem>
            </Grid>

            <Divider height="2vh" />

            <Heading as="h2">Recommendations / Insights</Heading>
            <Heading as="h3" color={"green.500"}>
              Potential Benefits of Extra-Provincial Registration:
            </Heading>
            <List>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Operate your business in more than one province.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Have more territory to operate your business.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" mr="6" />
                We can be your Agent for Service in Alberta, helping you manage
                your Alberta corporation while expanding.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                Benefit from a larger market and increased brand recognition.
              </ListItem>
            </List>
          </VStack>
        </Box>
      </Flex>

      <Disclaimer />

      <ModalDisclaimer />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" color="green.500">
              We will contact you as soon as possible!
            </Heading>
            <Text>Please provide your details here.</Text>
            <FormControl>
              <FormLabel>Your Name:</FormLabel>
              <Input
                placeholder="Your Name"
                mb={4}
                value={emailData.name}
                onChange={(e) =>
                  setEmailData({ ...emailData, name: e.target.value })
                }
              />
              <FormLabel>Your Email Address:</FormLabel>
              <Input
                placeholder="Email Address"
                mb={4}
                value={emailData.address}
                onChange={(e) =>
                  setEmailData({ ...emailData, address: e.target.value })
                }
              />
              <FormLabel>Your Inquiry:</FormLabel>
              <Textarea
                placeholder="Message"
                rows={4}
                value={emailData.message}
                onChange={(e) =>
                  setEmailData({ ...emailData, message: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button
                variant="ghost"
                color="black"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button colorScheme="green" onClick={handleSendEmail}>
                Submit
              </Button>
              <Button
                colorScheme="yellow"
                variant="solid"
                onClick={() => {
                  window.location.href = "tel:+1(403) 262 9999";
                }}
              >
                Call Us
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContinueIn;
