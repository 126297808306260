import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  useToast,
  Heading,
  Divider,
  Table,
  Tbody,
  Tr,
  Td,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { getService, deleteService } from "../../../services/admin/services";
import { ArrowBackIcon } from "@chakra-ui/icons";
import EmpBar from "../../EmpBar";
import ServiceForms from "../../../utils/ServiceForms";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

const ViewService = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getService(id);
        if (response.status === 200) {
          console.log(response);
          setServiceData(response.data.service);
        } else {
          toast({
            title: "Could not get Service",
            description: "Couldnt get the service you were looking for",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error loading service: ", error);
      }
    };
    fetchData();
  }, [id]);

  const handleEdit = () => {
    navigate(`/employee/admin/services/service/edit/${id}`);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteService(id);
      if (response.status === 200) {
        toast({
          title: "Service Deleted",
          description: "Service has been deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/employee/admin/services");
      } else
        toast({
          title: "Something went wrong",
          description: "Could not delete service.. Please check server",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    } catch (error) {
      // Handle error here, e.g., show a toast message
      console.error("Error deleting service: ", error);
    }
  };

  return (
    <>
      {console.log(serviceData)}
      <EmpBar />
      {window.history.length >= 1 && (
        <Button
          bg="black"
          color="white"
          leftIcon={<ArrowBackIcon />}
          _hover={{ bg: "black.500", color: "yellow" }}
          _active={{ bg: "gray.200", color: "black" }}
          variant="solid"
          ml="50px"
          onClick={() => {
            navigate("/employee/admin/services");
          }}
        >
          Go Back
        </Button>
      )}
      <Box margin="50px">
        <HStack justify={"space-between"}>
          <Heading>Service Details</Heading>
          <Button
            onClick={handleEdit}
            colorScheme="orange"
            size={"lg"}
            w="250px"
          >
            Edit
          </Button>
        </HStack>
        <Divider h="5px" />
        {serviceData && (
          <Box mt={4}>
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">Name</Td>
                  <Td>{serviceData.name}</Td>
                </Tr>

                <Tr>
                  <Td fontWeight="bold">Description</Td>
                  <Td>{serviceData.description}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Cost (Range)</Td>
                  <Td>{serviceData.cost}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">
                    Documents Required (comma-separated)
                  </Td>
                  <Td>{serviceData.documentsRequired}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Service for New Incorporation</Td>
                  <Td>
                    {serviceData.newIncService ? (
                      <Icon as={CheckIcon} />
                    ) : (
                      <Icon as={CloseIcon} />
                    )}
                  </Td>
                </Tr>
                <Tr>
                <Td fontWeight="bold">Service Type</Td><Td>{serviceData.serviceType}</Td></Tr>
              </Tbody>
            </Table>

            <HStack m={10}>
              <Button
                onClick={handleEdit}
                colorScheme="orange"
                size={"lg"}
                w="250px"
              >
                Edit
              </Button>
              <Button
                onClick={handleDelete}
                colorScheme="red"
                size={"lg"}
                w="250px"
              >
                Delete
              </Button>
            </HStack>
          </Box>
        )}

        {serviceData && serviceData.formsRequired && (
          <ServiceForms
            modify={false}
            service={serviceData}
            setService={setServiceData}
          />
        )}
      </Box>
    </>
  );
};

export default ViewService;
