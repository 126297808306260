// LoginPage.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useHistory for navigation
import {
  ChakraProvider,
  Box,
  VStack,
  Input,
  Button,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../services/auth";
import { loginEmployee } from "../redux/actions/auth";

const LoginPage = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  const toast = useToast();
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee).state;
  useEffect(() => {
    if (employee && Object.keys(employee).length > 0) {
      if (employee.isAdmin) {
        navigate("/employee/admin/dashboard");
      } else {
        navigate("/employee/dashboard");
      }
    }
  }, [employee]);
  const handleLogin = () => {
    try {
      const resp = login(credentials.username, credentials.password);
      if (resp.status === 200) {
        dispatch(loginEmployee(resp.user));
        toast({
          title: `Welcome ${resp.user.name}`,
          type: "success",
          duration: 3000,
          isClosable: true,
        });
        if (resp.user.isAdmin) navigate("/employee/admin/dashboard");
        else navigate("/employee/dashboard");
      } else {
        // Handle login failure
        toast({
          title: `Invalid Credentials`,
          type: "success",
          duration: 3000,
          isClosable: true,
        });
        console.log("Invalid credentials");
      }
    } catch (error) {
      toast({
        title: "Something went wrong",
        type: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    if (
      credentials.username === "dummy" &&
      credentials.password === "password"
    ) {
      // Redirect to Dashboard or another page upon successful login
      navigate("/dashboard");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <ChakraProvider>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        bg="gray.200"
      >
        <VStack spacing={8}>
          <Heading size="lg">Login</Heading>
          <Box
            p={8}
            width={{ base: "90%", md: "400px" }}
            bg="white"
            borderRadius="lg"
            boxShadow="lg"
          >
            <VStack spacing={4}>
              <Input
                placeholder="Username"
                value={credentials.username}
                onChange={(e) =>
                  setCredentials({ ...credentials, username: e.target.value })
                }
                onKeyPress={handleKeyPress}
              />
              <Input
                type="password"
                placeholder="Password"
                value={credentials.password}
                onChange={(e) =>
                  setCredentials({ ...credentials, password: e.target.value })
                }
                onKeyPress={handleKeyPress}
              />
              <Button colorScheme="green" onClick={handleLogin}>
                Submit
              </Button>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </ChakraProvider>
  );
};

export default LoginPage;
