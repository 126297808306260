import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useToast,
  Heading,
  Divider,
  VStack,
} from "@chakra-ui/react";
import {
  DeleteIcon,
  ViewIcon,
  EditIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowBackIcon,
} from "@chakra-ui/icons";
import { deleteService, getAllServices } from "../../../services/admin/services";
import EmpBar from "../../EmpBar";

const ServiceList = () => {
  const [services, setServices] = useState([]);
  const [sortBy, setSortBy] = useState("sub_category");
  const [sortOrder, setSortOrder] = useState("asc");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedService, setSelectedService] = useState(null);
  const toast = useToast();

  const loadData = async () => {
    const serv_response = await getAllServices();
    if (serv_response.status === 200) {
      setServices(serv_response.data.services);
    } else {
      toast({
        title: "Error while loading data",
        description: `Could not be deleted. Try again !, check server`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    if (selectedService) {
      const response = await deleteService(selectedService._id);
      if (response.status === 200) {
        onClose();
        loadData();
        toast({
          title: "Service Deleted",
          description: `${selectedService.sub_category} has been deleted.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        onClose();
        toast({
          title: "OOPS! Something is not right",
          description: `Could not be deleted. Try again !, check server`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleSort = (column) => {
    if (services && services.length > 0) {
      if (column === sortBy) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortBy(column);
        setSortOrder("asc");
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const sortedServices = services.sort((a, b) => {
    const modifier = sortOrder === "asc" ? 1 : -1;
    if (a[sortBy] < b[sortBy]) return -1 * modifier;
    if (a[sortBy] > b[sortBy]) return 1 * modifier;
    return 0;
  });

  return (
    <>
      <EmpBar />
      {window.history.length >= 1 && (
        <Button
          bg="black"
          color="white"
          leftIcon={<ArrowBackIcon />}
          _hover={{ bg: "black.500", color: "yellow" }}
          _active={{ bg: "gray.200", color: "black" }}
          variant="solid"
          ml="50px"
          onClick={() => {
            window.history.back();
          }}
        >
          Go Back
        </Button>
      )}
      <Box>
        <HStack margin="30px" justify={"space-between"}>
          <Heading>Services List</Heading>
          {/* Add */}
          <VStack>
            <Button
              as={Link}
              to="/employee/admin/services/create"
              colorScheme="teal"
              size={"lg"}
              p={4}
              minWidth={"20vw"}
              m={3}
            >
              Add New Service
            </Button>
          </VStack>
        </HStack>
        {/* Filter by category */}
        <Divider h={3} />
        <Table variant="simple" size="lg" margin="30px">
          <Thead>
            <Tr>
              <Th onClick={() => handleSort("id")} cursor="pointer">
                Serial Number
                {sortBy === "id" && (
                  <span>
                    {sortOrder === "asc" ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </span>
                )}
              </Th>
              <Th onClick={() => handleSort("name")} cursor="pointer">
                Name{" "}
                {sortBy === "name" && (
                  <span>
                    {sortOrder === "asc" ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </span>
                )}
              </Th>
              <Th onClick={() => handleSort("description")} cursor="pointer">
                Description{" "}
                {sortBy === "description" && (
                  <span>
                    {sortOrder === "asc" ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </span>
                )}
              </Th>
              <Th onClick={() => handleSort("cost")} cursor="pointer">
                cost{" "}
                {sortBy === "cost" && (
                  <span>
                    {sortOrder === "asc" ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </span>
                )}
              </Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedServices.length > 0 &&
              sortedServices.map((service, index) => (
                <Tr key={service._id}>
                  <Td>{index + 1}</Td>
                  <Td>{service.name}</Td>
                  <Td>{service.description}</Td>
                  <Td>{service.cost}</Td>
                  <Td>
                    <HStack spacing={4}>
                      <Button
                        as={Link}
                        to={`/employee/admin/services/service/view/${service._id}`}
                        colorScheme="green"
                      >
                        <ViewIcon />
                      </Button>
                      <Button
                        as={Link}
                        to={`/employee/admin/services/service/edit/${service._id}`}
                        colorScheme="yellow"
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          setSelectedService(service);
                          onOpen();
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </HStack>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>

        {/* Confirmation Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Delete</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete the service{" "}
              <strong>{selectedService?.sub_category}</strong> ?
            </ModalBody>
            <ModalFooter>
              <HStack spacing="2">
                <Button variant="ghost" color="black" onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={handleDelete}>
                  Delete
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default ServiceList;
