import React from "react";

import {
  Heading,
  Input,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import MaintenanceReminder from "../../../utils/MaintenanceReminder";

const AccountInformation = ({ request, setRequest }) => {

  const handleChangeAccountAddress = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      accountInfo: {
        ...prevRequest.accountInfo,
        [field]: value,
      },
    }));
  };

  const handleAnnualMgmtChange = (value, priceType) => {
    let bool = value ? true : false;
    setRequest((prevReq) => ({ ...prevReq, annualMgmt: bool }));
    request.togglePrice(value, priceType);
  };
  
  return (
    <>
      <Heading mb="15px">Personal Information</Heading>

      <FormControl>
        <Grid
          templateColumns={{ base: '1fr', md: 'auto 1fr' }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <FormLabel pr="10">
              My first name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.firstName}
              onChange={(e) => handleChangeAccountAddress("firstName", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="10">
              My last name is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.lastName}
              onChange={(e) => handleChangeAccountAddress("lastName", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="10">
              My phone number is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.phone}
              onChange={(e) => handleChangeAccountAddress("phone", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="10">
              My email is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.accountInfo.email}
              onChange={(e) => handleChangeAccountAddress("email", e.target.value)}
            />
          </GridItem>
        </Grid>
      </FormControl>
     

      <br />

      <MaintenanceReminder
        setValue={(value) =>
          handleAnnualMgmtChange(value, "SubscribeAnnualMaintenance")
        }
        enabled={request.prices.SubscribeAnnualMaintenance.enabled}
      />
    </>
  );
};

export default AccountInformation;
