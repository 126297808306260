import React from "react";
import {
  Box,
  Image,
  Heading,
  Button,
  List,
  ListItem,
  ListIcon
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons"; // Assuming you are using Chakra UI icons
import { useNavigate, Link } from "react-router-dom";

const StartPageCard = ({ img, heading, list, link }) => {
  const navigate = useNavigate();
  return (
    <Box
      maxW="sm"
      border="1px solid rgba(0,0,0,0.15)"
      borderRadius="25"
      overflow="hidden"
      boxShadow="3px 3px 8px rgba(0,0,0,0.25)"
    >
      <Link to={link}>
        <Image
          className="card"
          src={img}
          alt={heading}
          mt="5"
          borderRadius="25"
          width="90% !important"
          margin="16px auto 0 auto"
        />
      </Link>

      <Box p="6">
        <Box d="flex" alignItems="baseline">
          <Heading as="h2" size="md">
            {heading}
          </Heading>
        </Box>

        <Box mt="4">
          <List>
            {list.slice(0, 4).map((item, index) => (
              <ListItem key={index}>
                <ListIcon as={CheckCircleIcon} color="blue.500" />
                {item}
              </ListItem>
            ))}
          </List>
        </Box>

        <Button
          mt="4"
          className="btn-black"
          onClick={() => {
            navigate(`${link}`);
          }}
        >
          Let's get started !
        </Button>
      </Box>
    </Box>
  );
};

export default StartPageCard;
