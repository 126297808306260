import {
  Box,
  Button,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import EmpBar from "../EmpBar";

const ManageDashboard = () => {
  const navigate = useNavigate();

  return (
    <>
      <EmpBar />
      <Box paddingX="4" paddingY="2">
        <VStack padding="20vh">
          <Button
            onClick={() => {
              navigate("/employee/admin/services");
            }}
            width="20ch"
            size="lg"
            colorScheme="orange"
          >
            Manage Services
          </Button>

          <Button
            onClick={() => {
              navigate("/employee/admin/forms");
            }}
            width="20ch"
            size="lg"
            colorScheme="orange"
          >
            Manage Forms
          </Button>

          <Button
            onClick={() => {
              navigate("/employee/statistics");
            }}
            width="20ch"
            size="lg"
            colorScheme="yellow"
          >
            Statistics
          </Button>
          <Button
            onClick={() => {
              navigate("/employee/admin/users");
            }}
            width="20ch"
            size="lg"
            colorScheme="red"
          >
            Manage Users
          </Button>
        </VStack>
      </Box>
    </>
  );
};

export default ManageDashboard;
