import React, { useState } from "react";
import NavBar from "../../../../utils/NavBar";
import {
  Flex,
  Heading,
  Link,
  VStack,
  Button,
  Divider,
  List,
  ListItem,
  ListIcon,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Textarea,
  ModalCloseButton,
  FormControl,
  FormLabel,Input, HStack,
  useDisclosure
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import Disclaimer from "../../../../utils/Disclaimer";
import { handleSendInquiryEmail } from "../../../../services/email";
const ChangeShareStructure = () => {  const toast = useToast();
  const [emailData, setEmailData] = useState({
    address: "",
    message: "",
    name: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSendEmail = async () => {
    (await handleSendInquiryEmail(emailData))
      ? toast({
          title: "Email Sent Successfully",
          description: `Thanks!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      : toast({
          title: "Error",
          description: `Could not send the email.. Try again later.. Our email is services@snappycorporations.com.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    onClose();
  };
  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <Flex justifyContent="center" mt="50px">
        <VStack align="start" className="container" spacing="4">
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/existingCorporate");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading as="h1">Checklist for Changing Your Share Structure</Heading>

          <Text mb="24px">
            A corporation's share structure is established in the Articles of
            Incorporation. A share of a company represents a proportionate
            interest in the value of the corporation. That is, the value that
            would remain after all liabilities to creditors are paid.
          </Text>

          <Text>
            {" "}
            There are three rights required within the structure of shares of an
            Alberta company:
          </Text>
          <Text>
            1. the right to vote at a meeting of shareholders;
            <br />
            2. the right to receive dividends, if declared; and
            <br />
            3. the right to receive the remaining assets or property of the
            corporation upon dissolution.
          </Text>
          <Link
            href="https://ised-isde.canada.ca/site/corporations-canada/en/business-corporations/share-structure-and-shareholders"
            isExternal
            color="green.500"
            fontWeight="bold"
            padding="4"
            borderRadius="md"
            bg="green.100"
            _active={{ bg: "green.200" }}
            _hover={{ textDecoration: "underline", color: "green.600" }}
            width={{base:"100%", md:"auto"}}
          >
            Know more about share structure
          </Link>

          <VStack spacing={4}>
            <List>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                You might need a lawyer's advice to define the classes of
                share structure
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color="green.500" />
                To use generic share structure or add some provisions and
                restrictions in it, we recommend that you contact us directly
              </ListItem>
            </List>

            {/* <Heading>Some more questions</Heading> */}
          </VStack>
          <Button
            colorScheme="green"
            onClick={onOpen}
            width={{base:"100%", md:"auto"}}
          >
            Contact Us For More Information!
          </Button>
        </VStack>
      </Flex>

      <Disclaimer />

      
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" color="green.500">
              We will contact you as soon as possible!
            </Heading>
            <Text>Please provide your details here.</Text>
            <FormControl>
              <FormLabel>Your Name:</FormLabel>
              <Input
                placeholder="Your Name"
                mb={4}
                value={emailData.name}
                onChange={(e) =>
                  setEmailData({ ...emailData, name: e.target.value })
                }
              />
              <FormLabel>Your Email Address:</FormLabel>
              <Input
                placeholder="Email Address"
                mb={4}
                value={emailData.address}
                onChange={(e) =>
                  setEmailData({ ...emailData, address: e.target.value })
                }
              />
              <FormLabel>Your Inquiry:</FormLabel>
              <Textarea
                placeholder="Message"
                rows={4}
                value={emailData.message}
                onChange={(e) =>
                  setEmailData({ ...emailData, message: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button
                variant="ghost"
                color="black"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button colorScheme="green" onClick={handleSendEmail}>
                Submit
              </Button>
              <Button
                colorScheme="yellow"
                variant="solid"
                onClick={() => {
                  window.location.href = "tel:+1(403) 262 9999";
                }}
              >
                Call Us
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChangeShareStructure;
