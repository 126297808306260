import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Switch,
  Select,
  Checkbox,
  VStack,
  Flex,
  Heading,
  Button,
  HStack,
  useToast,
  Image,
  Text,
  Divider,
  GridItem,
  Grid,
  Center,
  Box
} from "@chakra-ui/react";
import NavBar from "../utils/NavBar";
import { createReminder } from "../services/reminders";
import { useNavigate } from "react-router-dom";
import Disclaimer from "../utils/Disclaimer";

const ReminderForm = () => {
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    vehicleRegistration: false,
    driverLicense: false,
    corporateAnnualReturns: false,
    companyVehicleRegistration: false,
    companyVehicleName: "",
    companyVehicleQuarterlyReminders: false,
    corporateAnnualReturnName: "",
    corporateAnnualReturnFilingMonth: "",
  });

  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [thanksPage, setThanksPage] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone"){
      if (value.match(/^\d{3}-\d{3}-\d{4}$/) || value === "") {
        setPhoneValid(true);
      } else {
        setPhoneValid(false);
      }
    }

    if (name === "email"){
      if (value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSwitchChange = (name) => (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const {
      phone,
      email,
      vehicleRegistration,
      driverLicense,
      corporateAnnualReturns,
      companyVehicleRegistration,
    } = formData;

    setIsSubmitDisabled(
      !(phone || email) || (email && !emailValid) ||
        (!vehicleRegistration &&
          !driverLicense &&
          !corporateAnnualReturns &&
          !companyVehicleRegistration)
    );
  }, [formData]);

  const handleSubmit = async () => {
    try {
      const response = await createReminder(formData);

      if (response.status === 200) {
        setThanksPage(true);
        setTimeout(() => {
          if (thanksPage) returnHome();
        }, 15000);
        toast({
          title: "Reminder Added",
          description: "Reminder has been created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to update reminder." + response.data.msg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update reminder.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const imageURL =
    "https://img.freepik.com/free-vector/thank-you-placard-concept-illustration_114360-13436.jpg?size=626&ext=jpg";

  const returnHome = () => {
    setThanksPage(false);
    navigate("/");
  };

  return (
    <>
      <NavBar />
      {!thanksPage ? (
        <>
        <Flex justifyContent="center" mt="50px">
          <VStack align="start" className="container" spacing="4" width="100%">
            <Heading as="h1">Sign up For E-Reminder</Heading>

            <Divider className="divider" />

            <Flex justifyContent="center"  width="100%">
              
              <Flex width={{base:"100%", md:"50%"}}>
                <FormControl>
                  <Grid
                    templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                    gap={3}
                    alignItems="center"
                    width="100%"
                  >
                    <GridItem>
                      <FormLabel>
                        First Name
                      </FormLabel>
                    </GridItem>
                    <GridItem>
                      <Input
                        w={{ base: "100%", md: "30ch" }}
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                      />
                    </GridItem>
                    <GridItem>
                      <FormLabel>
                        Last Name
                      </FormLabel>
                    </GridItem>
                    <GridItem>
                      <Input
                        w={{ base: "100%", md: "30ch" }}
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                      />
                    </GridItem>
                    <GridItem>
                      <FormLabel>
                        Phone Number
                      </FormLabel>
                    </GridItem>
                    <GridItem>
                      <Input
                        type="tel"
                        w={{ base: "100%", md: "30ch" }}
                        name="phone"
                        placeholder="403-222-2222"
                        value={formData.phone}
                        onChange={handleInputChange}
                        border={
                          phoneValid ? "inherit" : "1px solid red !important"
                        }
                      />
                    </GridItem>
                    <GridItem>
                      <FormLabel>
                        Email
                      </FormLabel>
                    </GridItem>
                    <GridItem>
                      <Input
                        type="email"
                        w={{ base: "100%", md: "30ch" }}
                        name="email"
                        placeholder="johndoe@testing.com"
                        value={formData.email}
                        onChange={handleInputChange}
                        border={
                          emailValid ? "inherit" : "1px solid red !important"
                        }
                      />
                    </GridItem>
                  </Grid>

                  <br/>
                  <br />

                  <VStack align="start" gap="6">
                    <Flex width="100%">
                      <HStack justifyContent="space-between" width="100%">
                        <FormLabel>Vehicle Registration</FormLabel>
                        <Switch
                          name="vehicleRegistration"
                          isChecked={formData.vehicleRegistration}
                          onChange={handleSwitchChange("vehicleRegistration")}
                          borderRadius="full"
                          size="lg"
                          align="right"
                        />
                      </HStack>
                    </Flex>
                
                    {formData.vehicleRegistration && (
                      <Text fontSize="16px !important">
                        Please ensure you have filled your First Name and Last Name in the above section. <br /><br />We will start sending you vehicle Registration reminders.
                      </Text>
                    )}
                    <Flex width="100%">
                      <HStack justifyContent="space-between" width="100%">
                        <FormLabel>Driver License</FormLabel>
                        <Switch
                          name="driverLicense"
                          isChecked={formData.driverLicense}
                          onChange={handleSwitchChange("driverLicense")}
                          borderRadius="full"
                          size="lg"
                        />
                      </HStack>
                    </Flex>
                    {formData.driverLicense && (
                      <Flex justifyContent="center" width="100%">
                        <VStack spacing="2">
                          <Text fontSize="16px !important">
                            Additional information for Driver License:
                          </Text>
                          <Select
                            placeholder="Select Year"
                            border="1px solid black"
                            width="300px"
                          >
                            {[...Array(8)].map((_, index) => (
                              <option key={index}>{2024 + index}</option>
                            ))}
                          </Select>
                          <Select
                            placeholder="Select Month"
                            border="1px solid black"
                            width="300px"
                          >
                            {[
                              "January",
                              "February",
                              "March",
                              "April",
                              "May",
                              "June",
                              "July",
                              "August",
                              "September",
                              "October",
                              "November",
                              "December",
                            ].map((month) => (
                              <option key={month}>{month}</option>
                            ))}
                          </Select>
                        </VStack>
                      </Flex>
                    )}
                    <Flex width="100%">
                      <HStack justifyContent="space-between" width="100%">  
                        <FormLabel>Corporate Annual Returns</FormLabel>
                        <Switch
                          name="corporateAnnualReturns"
                          isChecked={formData.corporateAnnualReturns}
                          onChange={handleSwitchChange("corporateAnnualReturns")}
                          borderRadius="full"
                          size="lg"
                        />
                      </HStack>
                    </Flex>
                    {formData.corporateAnnualReturns && (
                      <Flex justifyContent="center" width="100%">
                        <VStack spacing="2">
                          <Text fontSize="16px !important">
                            Additional information for Corporate Annual Returns:
                          </Text>
                          <Input
                            type="text"
                            name="corporateAnnualReturnName"
                            value={formData.corporateAnnualReturnName}
                            onChange={handleInputChange}
                            placeholder="Company Name"
                            border="1px solid black"                          
                            width="300px"
                          />
                          <Select
                            placeholder="Select Filing Month"
                            name="corporateAnnualReturnFilingMonth"
                            value={formData.corporateAnnualReturnFilingMonth}
                            onChange={handleInputChange}
                            border="1px solid black"
                            width="300px"
                          >
                            {[
                              "January",
                              "February",
                              "March",
                              "April",
                              "May",
                              "June",
                              "July",
                              "August",
                              "September",
                              "October",
                              "November",
                              "December",
                            ].map((month) => (
                              <option key={month}>{month}</option>
                            ))}
                          </Select>
                        </VStack>
                      </Flex>
                    )}
                    <Flex width="100%">
                      <HStack justifyContent="space-between" width="100%">
                        <FormLabel>
                          Company Vehicle Registration
                        </FormLabel>
                        <Switch
                          name="companyVehicleRegistration"
                          isChecked={formData.companyVehicleRegistration}
                          onChange={handleSwitchChange("companyVehicleRegistration")}
                          borderRadius="full"
                          size="lg"
                        />
                      </HStack>
                    </Flex>
                    {formData.companyVehicleRegistration && (
                      <Flex justifyContent="center" width="100%">
                        <VStack spacing="2">
                          <Text fontSize="16px !important">
                            Additional information for Company Vehicle Registration:
                          </Text>
                          <Input
                            type="text"
                            autoFocus={true}
                            name="companyVehicleName"
                            value={formData.companyVehicleName}
                            onChange={handleInputChange}
                            placeholder="Company Name"
                            border="1px solid black"
                            width="300px"
                          />
                          <Checkbox
                            border="1px solid #666"
                            p={2}
                            borderRadius="md"
                            name="companyVehicleQuarterlyReminders"
                            isChecked={formData.companyVehicleQuarterlyReminders}
                            onChange={handleSwitchChange(
                              "companyVehicleQuarterlyReminders"
                            )}
                            colorScheme="blue"
                          >
                            Quarterly Reminders?
                          </Checkbox>
                        </VStack>
                      </Flex>
                    )}
                    <Flex justifyContent="center" width="100%">
                    <Button
                      onClick={handleSubmit}
                      colorScheme="green"
                      size="lg"
                      isDisabled={isSubmitDisabled}
                      mt="24px"
                      width={{base:"100%", md:"auto"}}
                    >
                      Submit
                    </Button>
                    </Flex>
                  </VStack>
                </FormControl>
              </Flex>
              
            </Flex>

          </VStack>
        </Flex>

        <Disclaimer />
        </>
      ) : (
        <VStack justifyContent="center" mt="4">
          <Image
            src={imageURL}
            alt="Queue Image"
            height="200px"
            mb="4"
          />

          <Heading fontSize="8xl" fontWeight="bold" mb="4">
            Thank You!
          </Heading>

          <Text fontSize="4xl" mb="4">
            You will recieve a text message within next 24 hours with an authorizing link, open the link to authorize us to send you reminders on the above phone / email.
          </Text>
          <VStack>
            <Button
              colorScheme="green"
              size={"lg"}
              onClick={() => {
                window.location.reload()
              }}
            >
              Add new Reminder
            </Button>
            <Button
              colorScheme="blue"
              size={"lg"}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </Button>
          </VStack>
        </VStack>
      )}
    </>
  );
};

export default ReminderForm;
