import React from 'react'
import {
  Heading,
  VStack,
  Text,
  Divider,
  Center,
  List,
  ListItem,
} from "@chakra-ui/react";
import NavBar from '../../../utils/NavBar'
import AboutUs from '../../AboutUs';

const Terms = () => {
  return (
    <>
      <NavBar/>

      <Center mt="40px" className="tac" pb="48px">
        <VStack align="left" justifyContent="center" className="container">

          <Heading as="h1">
            Terms and Conditions
          </Heading>

          <Divider className="divider" />

          <Text>
            In these terms and conditions (the <b>“Terms”</b>), <b>“we”</b>, <b>“us”</b> and <b>“our”</b> means Snappy Corporations Corp. and its affiliates, subsidiaries, employees and representatives and <b>“you”</b> and <b>“your”</b> means the person who uses a Device to access services from us through our website (the <b>“Website”</b>). These Terms govern your access and use of the Website and any services provided therein (the <b>“Services”</b>, as further discussed and defined herein).

            These terms are a legally binding contract between you and us, before you can use the Website and access the Services.
          </Text>

          <Heading as="h2">Definitions</Heading>

          <Text>
            Capitalized terms not otherwise defined in these Terms shall have the meanings ascribed below:
          </Text>

          <List>
            <ListItem>
              <b>Device</b> means any mobile device, computer or other device you use to access the Website and the Services.
            </ListItem>
            <ListItem>
              <b>Offers</b> means any offers, rate discounts or promotions of a Third Party presented by us as part of the Services.
            </ListItem>
            <ListItem>
              <b>Snappy</b> means Royal Bank of Canada.
            </ListItem>
            <ListItem>
              <b>Services</b> means the features, functionality, content and information provided by us via the Website and include any services that may be provided by Third Parties.
            </ListItem>
            <ListItem>
              <b>Sign-In Credentials</b> means usernames, passwords, personal verification questions or other information required to access the Website and the Services.
            </ListItem>
            <ListItem>
              <b>Third Party</b> means any party other than you, us or a party when acting as a Service Provider.
            </ListItem>
          </List>

          <Heading as="h2">Fees and Third Party Payment Providers</Heading>

          <Text>
            The Services include both paid for Services and free services. The fees for accessing the paid for Services available on the Website are set out in the Website. Applicable taxes apply in addition to all fees charged. For purposes of processing payments, we use the following third party service provider: <b>[Janet – are you using a third party service provider for payments?]</b> Before using the paid for Services, you must first review and approve the terms and conditions governing the use of third-party payment processors, which are available at the following website: <b>[x]</b>. We may change the amount of our fees as the Services develop or introduce new fees for Services. We will provide you with notice of any changes in fees, in accordance with the notice section of these Terms. All amounts are expressed in Canadian dollars. You are responsible for all costs, fees, data plans and related charges associated with your use of any Devices, and they are not reimbursable by us.
          </Text>

          <Heading as="h2">Privacy and Personal Information</Heading>

          <Text>
            We will collect information from and about you such as:
          </Text>

          <List>
            <ListItem>
              information establishing your identity (for example name, address, phone number, date of birth, etc.); and
            </ListItem>
            <ListItem>
              information for the provision of the Services.
            </ListItem>
          </List>

          <Text>
            Any use of such information shall be in accordance with any applicable law of any governmental authority in Canada relating to the collection, use, storage and/or disclosure of information about an identifiable individual, including the Personal Information and Protection of Electronic Documents Act (Canada) and similar Canadian provincial legislation.
          </Text>

          <Text>
            Such information we obtain from may be used from time to time for the following purposes:
          </Text>

          <List>
            <ListItem>
              to create your profile on the Website and provide you with the Services;
            </ListItem>
            <ListItem>
              to determine your eligibility for products and services;
            </ListItem>
            <ListItem>
              to help us better understand the current and future needs of our users; and
            </ListItem>
            <ListItem>
              to help us better manage our business and your relationship with us; and as required or permitted by law.
            </ListItem>
          </List>

          <Heading as="h2">Termination</Heading>

        <Text>
          Without prior notice and for any reason, we can suspend or terminate your use of the Website and access to the Services, and/or terminate part or all of these Terms for the following reasons: (i) any actual or intended violation of these Terms, including without limitation your failure to pay any fees owing; and (ii) any unlawful or inappropriate behavior, as determined by us, including without limitation unlawful or inappropriate use of the Website and the Services. We may also suspend or terminate your access to the Website and the Services, and/or terminate part or all of these Terms for convenience upon 15 days prior notice to you. We will not be responsible for any loss or inconvenience that may result from such suspension or termination for any loss or inconvenience that may result from such suspension or termination.
          <br /><br />
          To terminate your use of the Website and access to the Services and these Terms, you can contact us at the contact information provided on the Website.
        </Text>

        <Heading as="h2">Changes</Heading>

        <Text>
          We can change (add, remove or alter) any part or feature of the Services, without giving you notice. Following any such change, these Terms will continue to apply to all or parts of the Services. Subject to legal and regulatory requirements, we can change these Terms. If you use the Website or access the Services after a change, you are deemed to have reviewed and accepted the new version of these Terms.
        </Text>

        <Heading as="h2">Third Party Services</Heading>

        <Text>
          We may use Third Parties to provide or to assist us in providing the Services, When using the Website and accessing the Services.
        </Text>

        <List>
          <ListItem>
            You may use Third Party services solely for your own personal use;
          </ListItem>
          <ListItem>
            You may not sell, distribute or otherwise use Third Party services or other information from the Website or the Services, and you may not permit such distribution or use by anyone else; and
          </ListItem>
          <ListItem>
            You may not provide or make available any Third Party services to any provider of products or services similar to those products and services provided by the Service Providers.
          </ListItem>
        </List>

        <Text>
          You understand and agree that (i) your use of any Third Party services is at your sole risk and may be subject to the terms and privacy policy of the Third Party and (ii) Third Party services are provided "as-is" and "as available", and that we expressly disclaim all warranties (express and implied) in connection with any Third Party services and all information, products and other content included in or accessible from any Third Party services, including, without limitation, any warranties regarding accuracy, completeness, merchantability or fitness for a particular purpose. Third Party service providers retain all ownership rights, including all intellectual property rights, in Third Party services and to their trademarks and logos that appear on the Website and in the Services. Nothing in these Terms, the Website or the Services should be interpreted as conferring any right for you: (i) to use Third Party services in any way other than as expressly permitted in these Terms; or (ii) to use any Third Party service provier trademarks or logos. You agree that we, Third Parties may use information and content you provide through the Website and the Services to create use and distribute statistical, profiling, performance or operational reports about the Website and the Services. These reports may be shared with Third Parties, in which case, the information in the reports will not be attributable to you.
        </Text>

        <Heading as="h2">Use of the Website</Heading>

        <Text>
          Your use of the Website and access to the Services must comply with these Terms and all applicable laws. When using the Website and accessing the Services, you will not:
        </Text>

        <List>
          <ListItem>
            Provide untrue, inaccurate or incomplete information;
          </ListItem>
          <ListItem>
            Use the Website or access the Services for any illegal, fraudulent, malicious, harassing or defamatory activity or purpose;
          </ListItem>
          <ListItem>
            Use any robot, spider or other indexing device when using the Website or accessing the Services;
          </ListItem>
          <ListItem>
            Use any part of the Services to provide internet, service bureau, outsourcing or third-party services or redistribute all or any part of the Services;
          </ListItem>
          <ListItem>
            Take steps or actions that could or do undermine the security, integrity, effectiveness, goodwill or connectivity of the Website or the Services (including illegal, fraudulent, malicious, defamatory or other activities that threaten to harm or cause harm to any other person); or
          </ListItem>
          <ListItem>
            Reverse engineer or reverse compile the source code for the Website or any of the service technology.
          </ListItem>
        </List>

        <Text>
          When you make use of a feature or Service that allows you to input or upload content to the Service, you declare and warrant that you have all necessary rights to submit such content and that such content does not infringe the rights of third parties.
        </Text>

        <Heading as="h2">Ownership</Heading>

        <Text>
          We grant you a non-exclusive and non-transferable single-user (non-concurrent) license to use the Website, in accordance with these Terms. At any time, and at our sole discretion, we may limit, restrict, suspend, declare a moratorium on, or terminate your ability to access the Website, or any of the Services, or both; and with notice to you, we may end the terms relating to the Website, including the Services. If these Terms are terminated, you will destroy all copies of the Website and all copies of any documentation for the Website then in your possession. The grant of this license may not be assigned by you unless agreed upon in writing by us. We retain at all times all ownership rights, including without limitation, copyright, in the Website. You agree not to copy, reproduce, transfer copies or reverse engineer the Website and not to disclose or distribute the Website to Third Parties. We have no obligation to provide any training, maintenance, or other assistance for the Website. Snappy and/or its affiliates are the owners, or licensees, of all intellectual property rights subsisting on each screen made available through the Website. Unless otherwise indicated, trademarks and logos, and all works, including texts, images, illustrations, software, HTML codes, audio clips and videos appearing on the Services are the property Snappy and/or its affiliates, or those of our licensors, and without our express written permission, may not be reproduced, republished, downloaded, posted, transmitted, distributed or modified, in whole or in part, in any form whatsoever, except for personal and non-commercial use, including viewing, printing or archiving of electronic copies of your Website activities, in accordance with these Terms and as we may further instruct you. Nothing in these Terms or on the Website is to be interpreted as conferring a right to use our works, trademarks or logos in any other way, or those of any Snappy companies or Service Providers.
        </Text>

        <Heading as="h2">No Representations or Warranties</Heading>

        <Text>
          We are providing you with the Website and the Services on an "as is" and "as available" basis and we do not make any representations or provide any warranties concerning them. Without limiting the foregoing, we expressly disclaim all warranties in connection with the Website and in connection with the Services, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement.
        </Text>

        <Heading as="h2">Liabilities and Indemnities</Heading>

        <Text>
          You are solely responsible for all information or content that you give us through the Website, the Services and the Third Party services. We will not be responsible for any loss, damage, harm, injury, delay or inconvenience suffered or incurred by you with respect to: (i) these Terms, the Website, the Services or the Third Party services, (ii) any instruction given to, by or purported to be given by you, using sign-in credentials, in connection with the Services or Third Party services; or (iii) any products or services you obtain or purchase from us or from a Third Party to whom we refer you, or otherwise. Such losses, damages, injuries, delays and inconveniences include, without limitation, (a) direct, incidental, indirect, consequential, special, aggravated, punitive or exemplary damages, (b) loss of data, profits, information, opportunity, revenues and goodwill, and (c) any other business interruption, commercial or economic losses. The foregoing limitation of liability applies regardless of the cause of action, even if we or a Snappy company have been advised of the possibility of such damages. In addition, in no event, even if we are negligent or any Snappy company is negligent, will we or any Snappy company be liable for any loss or damage suffered by you that is caused by any one or more of:
        </Text>

        <List>
          <ListItem>
            The actions of, or any failure to act by a Third Party or Service Provider (and no such Third Party will be considered to be acting as our agent);
          </ListItem>
          <ListItem>
            Mistakes, errors, omissions, inaccuracies or other inadequacies of, or contained in the Services or Third Party services or any data given by you to us, any Snappy company or any Third Party or service provider, including your failure to update;
          </ListItem>
          <ListItem>
            Any action or decision made by you in reliance on the Services or the Website, or information or content they contain, or results obtained from the use of the Services;
          </ListItem>
          <ListItem>
            Any delay, error, interruption or failure by us, any Snappy company or any Third Party to perform or fulfill any of our obligations to you due to any cause beyond our control or their control, any system malfunctions or any technical failures;
          </ListItem>
          <ListItem>
            Unsecured communication being inaccurate, intercepted, reviewed or altered by others, or not received by you;
          </ListItem>
          <ListItem>
            Your access to the Services or Third Party services, including, without limitation, any delay or inability to access the Services or Third Party services;
          </ListItem>
          <ListItem>
            Your failure to receive or view any communication that has been presented to you, and we will not be responsible, and no such Snappy company will be responsible, for any delay, damage or inconvenience that such failure may cause; or
          </ListItem>
          <ListItem>
            Your failure to fulfill any of your obligations under these Terms, or to comply with any instructions we may provide to you from time to time in connection with the Website and the Services.
          </ListItem>
        </List>

        <Text>
          You release and indemnify us for any claim, cost and liability incurred: (i) as a result of your use of the Website any of the Services and Third Party Services; or (ii) as a result of your breach of these Terms.
        </Text>

        <Heading as="h2">Records</Heading>

        <Text>
          Our records, including electronic records, including those of any Service Providers (whether used by them or us), regarding the Website or the Services, including the retrieval, consolidation, organization and presentment of information, are final and conclusive. These records will be admissible in any legal, administrative or other proceedings. Furthermore, electronic data and records will be admissible as conclusive evidence of the contents of those records in the same manner as original paper documents. You waive any right to object to the introduction of any such electronic data or records into evidence.
        </Text>

        <Heading as="h2">Electronic Agreement</Heading>

        <Text>
          These Terms and any related terms, conditions, instructions, disclaimers or documents may be accepted in electronic form and your acceptance is binding between you and us. Neither you nor we will contest the validity or enforceability of these Terms and any related documents because they were accepted or signed in electronic form.
        </Text>

        <Heading as="h2">Assignment and Severability</Heading>

        <Text>
          If any provision of these Terms is found to be invalid or unenforceable, this will not affect the validity or enforceability of the other provisions of these Terms. Our failure to enforce strict performance of any provisions of these Terms does not mean we have waived any provision or right. Neither the course of conduct between you and us, nor trade practice modifies any provision of these Terms. We may assign or transfer these Terms on notice to you. You may not assign or transfer these Terms or any of the obligations or rights under these Terms to any other person. We have the right at all times to assign any or all of our rights and obligations under these Terms to an Snappy company. The Services may then be delivered by the Snappy company to whom we assign these Terms.
        </Text>

        <Heading as="h2">Governing Law</Heading>

        <Text>
          These Terms are entered into and governed by the laws of the Province of Alberta and the federal laws of Canada applicable therein. You and we agree that the Alberta courts shall have exclusive jurisdiction over each of us for the determination of any matters arising out of these Terms.
        </Text>
          

        </VStack>
      </Center>

      <AboutUs />
    </>
  )
}

export default Terms
