import React, { useState } from "react";

import {
  Heading,
  Text,
  Input,
  HStack,
  FormLabel,
  FormControl,
  Grid,
  GridItem
} from "@chakra-ui/react";
import InfoButton from "../../../../utils/Popover";
import YesNoField from "../../../../utils/YesNoField.js";
import Province from "../../../../utils/Province.js";

const IncServInformation = ({ request, setRequest }) => {
  const handleChangeBusinessInfo = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      businessInfo: {
        ...prevRequest.businessInfo,
        [field]: value,
      },
    }));
  };


  const [yesChangeRegisteredAddress, setYesChangeRegisteredAddress] = useState(false);
  const [noChangeRegisteredAddress, setNoChangeRegisteredAddress] = useState(false);

  const handlePriceChange = (value, priceType) => {
    request.togglePrice(value, priceType);

    if (value === 'Yes'){
      setYesChangeRegisteredAddress(true);
      setNoChangeRegisteredAddress(false);

      if (priceType == "ChangeRegisteredAddress"){
        setRequest((prevRequest) => ({
          ...prevRequest,
          reviewFieldHidden: {
            ...prevRequest.reviewFieldHidden,
            businessInfo: {
              ...prevRequest.reviewFieldHidden.businessInfo,
              streetLine1: undefined,
              streetLine2: undefined,
              city: undefined,
              province: undefined,
              country: undefined,
              postalCode: undefined
            }
          }
        }));
      }
    } else {
      setYesChangeRegisteredAddress(false);
      setNoChangeRegisteredAddress(true);

      if (priceType == "ChangeRegisteredAddress"){
        setRequest((prevRequest) => ({
          ...prevRequest,
          reviewFieldHidden: {
            ...prevRequest.reviewFieldHidden,
            businessInfo: {
              ...prevRequest.reviewFieldHidden.businessInfo,
              streetLine1: false,
              streetLine2: false,
              city: false,
              province: false,
              country: false,
              postalCode: false
            }
          }
        }));
      }
    }
  };

  const enabled = request.prices.ChangeRegisteredAddress.enabled;

  return (
    <>
      <Heading mb="15px">Business Information</Heading>

      <FormControl>
        <Grid
          templateColumns={{ base: '1fr', md: 'auto 1fr' }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <HStack>
              <FormLabel>
                My company's business name is
              </FormLabel>
            </HStack>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.businessName}
              onChange={(e) => handleChangeBusinessInfo("businessName", e.target.value)}
              autoFocus
            />
          </GridItem>

          <GridItem>
            <FormLabel>
              My company's Corporate Access Number is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.corporateAccessNumber}
              onChange={(e) => handleChangeBusinessInfo("corporateAccessNumber", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My company's email address is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.businessInfo.email}
              placeholder="example@snappycorporations.com"
              onChange={(e) => handleChangeBusinessInfo("email", e.target.value)}
            />
          </GridItem>
        </Grid>
      </FormControl>
      
      <br />

      <YesNoField
        label={"Is there change of registered address?"}
        setValue={(value) => handlePriceChange(value, 'ChangeRegisteredAddress')}
        enabled={enabled}
        price={request.prices.ChangeRegisteredAddress.value}
      />

      {(noChangeRegisteredAddress || enabled === false) && <Text mt="12px !important">All set!</Text>}

      {(yesChangeRegisteredAddress || enabled === true) && (
        <>
          <Text mt="24px !important" fontWeight="bold"> 
            The new street address of its registered office will be:
          </Text>
          
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
            gap={3}
          >
            <GridItem>
              <FormLabel>Address Line 1</FormLabel>
              <Input
                value={request.businessInfo.streetLine1}
                onChange={(e) => handleChangeBusinessInfo("streetLine1", e.target.value)}
              />
            </GridItem>
            <GridItem>
              <FormLabel>Address Line 2</FormLabel>
              <Input
                value={request.businessInfo.streetLine2}
                onChange={(e) => handleChangeBusinessInfo("streetLine2", e.target.value)}
              />
            </GridItem>
            <GridItem>
              <FormLabel>City</FormLabel>
              <Input
                value={request.businessInfo.city}
                onChange={(e) => handleChangeBusinessInfo("city", e.target.value)}
              />
            </GridItem>
            <GridItem>
              <FormLabel>Province</FormLabel>
              <Province
                value={request.businessInfo.province}
                onChange={(e) =>
                  handleChangeBusinessInfo("province", e.target.value)
                }
              />
            </GridItem>
            <GridItem>
              <FormLabel>Country</FormLabel>
              <Input
                value={request.businessInfo.country}
                onChange={(e) => handleChangeBusinessInfo("country", e.target.value)}
              />
            </GridItem>
            <GridItem>
              <FormLabel>Postal Code</FormLabel>
              <Input
                value={request.businessInfo.postalCode}
                onChange={(e) => handleChangeBusinessInfo("postalCode", e.target.value)}
              />
            </GridItem>
          </Grid>
        </>
      )}
      
    </>
  );
};
export default IncServInformation;
