import React, { useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  useToast,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Th,
  Tr,
  Td,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { createRequest } from "../../../services/requests";
import { useNavigate } from "react-router-dom";

const Payment = ({ request, setRequest, companyType }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const reviewMap = request.review ? (companyType ? request.review[companyType] : request.review) : false;

  const reviewFieldNotRequiredMap = request.reviewFieldNotRequired ? (companyType ? request.reviewFieldNotRequired[companyType] : request.reviewFieldNotRequired) : false;

  const reviewFieldHiddenMap = request.reviewFieldHidden ? (companyType ? request.reviewFieldHidden[companyType] : request.reviewFieldHidden) : false;

  const reviewFieldReadOnlyMap = request.reviewFieldReadOnly ? (companyType ? request.reviewFieldReadOnly[companyType] : request.reviewFieldReadOnly) : false;

  const addSpacesToCamelCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  const formatPriceWithCommas = (price) => {
    return price.toLocaleString("en-CA");
  };

  const submit = async () => {
    if (request.reviewFieldNoDataCount >= 1){
      alert(`*** REVIEW Required ***\n\nYou must fix (${request.reviewFieldNoDataCount}) data entry errors and/or missing fields before proceeding to Payment.\n\nPlease go back to the Review page and review your data before continuing.`);
      return false;
    }

    try {
      const resp = await createRequest(request); // Assuming createRequest is an asynchronous function
      if (resp.status === 200) {
        toast({
          title: "Success!",
          description: "Your request is successfully submitted.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/thankYou");
      } else {
        toast({
          title: "Error",
          description:
            "There was a problem submitting your request. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Failed to connect to server", error);
      toast({
        title: "Error",
        description: "Failed to connect to server. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const countFields = () => {
    let updatedReviewFieldCount = 0;
    let updatedReviewFieldNoDataCount = 0;

    if (reviewMap){
      reviewMap.map((section, index) => {
        if (typeof request[section] != 'string'){
          Object.entries(request[section]).map(([key, value]) => {
            if (!reviewFieldHiddenMap || (
              (reviewFieldHiddenMap[section] == undefined || (reviewFieldHiddenMap[section] && reviewFieldHiddenMap[section][key] == undefined)))){
              // if shareholders, directors, etc
              // NOTE: don't need to count (shareholders, directors, partners) because their respective modals already has validation!
              if (Array.isArray(value)){
               // can skip this
              } else {
                if (!reviewFieldNotRequiredMap || (
                  (reviewFieldNotRequiredMap[section] == undefined || (reviewFieldNotRequiredMap[section] && reviewFieldNotRequiredMap[section][key] == undefined)))) {
                  updatedReviewFieldCount++;
                  if (value == ''){
                    updatedReviewFieldNoDataCount++;
                  }
                }
              }
            }
          });
        }
      });
    }

    if (request.reviewFieldCount != undefined){
      setRequest((prevRequest) => {
        return {
          ...prevRequest,
          reviewFieldCount: updatedReviewFieldCount,
          reviewFieldNoDataCount: updatedReviewFieldNoDataCount
        };
      });
    }

    console.log('updatedReviewFieldCount', updatedReviewFieldCount);
    console.log('updatedReviewFieldNoDataCount', updatedReviewFieldNoDataCount);
  };

  useEffect(() => {
    countFields();
  }, [1]);

  return (
    <>
      <Heading as="h2" mb={4}>
        Price
      </Heading>

      <Grid
        templateColumns={{ base: "1fr", md: "auto 1fr" }}
        alignItems="center"
      >
        <GridItem>
          <Heading as="h3" mb="0 !important">
            Total amount will be:{" "}
          </Heading>
        </GridItem>
        <GridItem>
          <Text
            className="price"
            fontSize="24px !important"
            mb="0 !important"
            ml="12px !important"
          >
            ${formatPriceWithCommas(request.price)} CAD
          </Text>
        </GridItem>
      </Grid>

      {/* <Text mb={4}>
        We will charge you only after complete verification of documents.
      </Text> */}

      <Table my="24px" className="payment">
        <Thead>
          <Tr>
            <Th width="60%">Item</Th>
            <Th width="40%" textAlign="right">
              Price
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {request.prices ? (
            Object.entries(request.prices).map(([price, priceObj]) => {
              if (price === "default" || priceObj.enabled === true) {
                return (
                  <Tr key={price}>
                    <Td>
                      {price !== "default"
                        ? " +  " + addSpacesToCamelCase(price)
                        : "Service Fee"}
                    </Td>
                    <Td textAlign="right">
                      $
                      {price !== "default"
                        ? formatPriceWithCommas(priceObj.value)
                        : formatPriceWithCommas(priceObj)}{" "}
                    </Td>
                  </Tr>
                );
              }
            })
          ) : (
            <Tr key={request.price}>
              <Td>Service Fee</Td>
              <Td textAlign="right">{formatPriceWithCommas(request.price)}</Td>
            </Tr>
          )}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td>TOTAL:</Td>
            <Td textAlign="right">
              <b>${formatPriceWithCommas(request.price)} CAD</b>
            </Td>
          </Tr>
        </Tfoot>
      </Table>

      <Box textAlign="right" mb="24px">
        <Button
          colorScheme="green"
          size="lg"
          onClick={submit}
          _hover={{ bg: "blue.700" }}
          w={{ base: "100%", md: "auto" }}
        >
          Review and Submit Application
        </Button>
      </Box>
    </>
  );
};

export default Payment;
