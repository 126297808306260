import React from "react";
import {
  Flex,
  HStack,
  Heading,
  VStack,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../utils/NavBar.js";
import Disclaimer from "../../utils/Disclaimer.js";

const ExtraProvincial = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing={4}>
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/main/location/extra-provincial");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading mb="24px">
            Is your company originally registered in BC, MB or SK?
          </Heading>

          <HStack spacing={4}>
            <Button className="btn-black" onClick={() => { navigate('/main/new/extra-provincial/nwpta'); }}>
              Yes
            </Button>
            <Button className="btn-black" onClick={() => { navigate('/main/new/extra-provincial'); }}>
              No
            </Button>
          </HStack>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Important Information</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                According to <b>NWPTA (New West Partnership Trade Agreement) </b>, which creates a single economic region encompassing British Columbia, Alberta, Saskatchwen and Manitoba, we <i>cannot</i> register any corporation from the above provinces. You would have to contact a corporate registry in your home jurisdiction for further assistance.
                <br /><br /><b>Thanks! ;-)</b>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </VStack>
      </Flex>

      <Disclaimer />
    </>
  );
};

export default ExtraProvincial;
