import React from "react";
import { Route, Routes } from "react-router-dom";
import ExistingCorpServices from "./changeIndividuals/Main";
import AmendCorporationHome from "./AmendCorporationHome";
import ChangeAddress from "./changeAddress/ChangeAddress";
import ChangeShareStructure from "./amendArticles/ChangeShareStructure";
import ChangeName from "./amendArticles/ChangeName";
import ChangeDirectors from "./changeDirectors/Main";
import ChangeShareholders from "./changeShareholders/Main";
import ChangeAgent from "./changeAgent/Main";

const ExistingIndex = () => {
  return (
    <Routes>
      <Route path="/changeAddress" element={<ChangeAddress />} />
      <Route path="/changeName" element={<ChangeName />} />
      <Route path="/amendShareStructure" element={<ChangeShareStructure />} /> 
      <Route path="/changeCorporateInd" element={<ExistingCorpServices />} />
      <Route path="/changeDirectors" element={<ChangeDirectors />} />
      <Route path="/changeShareholder" element={<ChangeShareholders />} />
      <Route path="/changeAgent" element={<ChangeAgent />} />
      <Route path="/" element={<AmendCorporationHome />} />
    </Routes>
  );
};

export default ExistingIndex;
