import React from "react";
import { Grid, GridItem, Center, Heading } from "@chakra-ui/react";
import StartPageCard from "../../utils/StartPCard";
import BannerLine from "../../utils/BannerLine.js";
import AboutUs from "../AboutUs";
import NavBar from "../../utils/NavBar.js";

const Services = () => {
  return (
    <>
      <NavBar />
      <div className="home-main">
        <Heading as="h1" align="center">
          Snappy Services
        </Heading>
        <Center>
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={6}
            p={6}
          >
            {mainServicesData2.map((card, index) => (
              <GridItem key={index} colSpan={{ base: 1, md: 1, lg: 1 }}>
                <StartPageCard {...card} />
              </GridItem>
            ))}
          </Grid>
        </Center>

        <BannerLine
          line="Let us be your corporation's Agent for Service."
          linkText="Know more"
          link="existingCorporate/changeAgent"
        />
      </div>
      <AboutUs />
    </>
  );
};

export default Services;

const mainServicesData2 = [
  {
    heading: "Amend Corporation",
    img: "/images/AI-photos/amend-corp.png",
    link: "/existingCorporate",
    list: [
      "Change Directors",
      "Change Shareholders",
      "Change Agent for Service",
      "Change Corporate Addresses (mailing address, records address)",
      "Change Name, share structure or articles of incorporation",
    ],
  },
  {
    heading: "Annual Returns",
    img: "/images/AI-photos/annual-returns.png",
    link: "/annualReturn",
    list: [
      "File Annual Returns",
      "Update Shareholders (no extra cost)",
      "Update Agent for Service",
    ],
  },
  {
    heading: "Corporate Search",
    img: "/images/AI-photos/corporate-search.png",
    link: "/corpSearch",
    list: [
      "Search information about Alberta corporations",
      "Articles of Incorporation, certificate of company's status",
    ],
  },
  {
    heading: "Amend Partnership",
    img: "/images/AI-photos/amend-partnership.png",
    link: "/existingPartnership/amendPartners",
    list: ["Change or Modify Partners", "Change Address"],
  },
  {
    heading: "Continue Business in Alberta",
    img: "/images/AI-photos/continue-business.png",
    link: "/continueInAB",
    list: [
      "Register your business in Alberta",
      "Extra-Provincial Registration",
      "See how we can make this process easier for you",
      "Moving in to Alberta, move your business as well",
    ],
  },
  {
    heading: "Amalgamate Corporations",
    img: "/images/AI-photos/amalgamate-corporation.png",
    link: "/amalgamate",
    list: [
      "Amalgamate two or more corporations",
      "Previous corporations will cease to exist as a new corporation is created",
    ],
  },
  {
    heading: "Move Alberta Corporation to Other Province",
    img: "/images/AI-photos/move-corporation.png",
    link: "/moveOut",
    list: ["We can help you with extending your Alberta corporation"],
  },
  {
    heading: "Cancel / Dissolve Corporation",
    img: "/images/AI-photos/dissolve-corporation.png",
    link: "/cancel-dissolve",
    list: [
      "Dissolve Alberta Corporation",
      "Cancel Extra-Provincial Registration",
    ],
  },
  {
    heading: "Start a New Business",
    img: "/images/AI-photos/start-new-business.png",
    link: "/main",
    list: [
      "Corporation",
      "Trade Name / Sole Proprietorship",
      "Partnership (General / Limited)",
      "Extra-Provincial Registration",
    ],
  },
];
export { mainServicesData2 };
