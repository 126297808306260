import React from "react";
import {
  Heading,
  VStack,
  Text,
} from "@chakra-ui/react";
import YesNoField from "../../../../utils/YesNoField.js";

const AddOns = ({ request, setRequest }) => {
  const handleAnnualMgmtChange = (value, priceType) => {
    let bool = value ? true : false;
    setRequest((prevReq) => ({ ...prevReq, annualMgmt: bool }));
    request.togglePrice(value, priceType);
  };

  return (
    <>
      <VStack align="left">
        <Heading>Add Ons</Heading>
        <VStack spacing={4} width="100%">
          {/* <Button colorScheme="blue" onClick={onOpen}>
                Yes
              </Button>
              <Button colorScheme="blue" onClick={handleBuyNow}>
                No
              </Button> */}

          <YesNoField
            label={
              "Would you like to subscribe for annual maintainence services from Snappy Subscriptions?"
            }
            about={
              "Contact us for more information. Subscribe annually and save more."
            }
            setValue={(value) => handleAnnualMgmtChange(value, 'SubscribeAnnualMaintenance')}
            enabled={request.prices.SubscribeAnnualMaintenance.enabled}
            price={request.prices.SubscribeAnnualMaintenance.value}
            link="/help/annualSubscription"
          />
        </VStack>
        <Text
          align={"left"}
          ml="15"
          mt="12px !important"
          color="green.500"
          fontWeight="bold"
        >
          Unlimited changes in shareholder, directors, and agent for service will be filled with no extra fees. Please reach out to a representative from Snappy Corporations to learn more.
        </Text>
      </VStack>
    </>
  );
};

export default AddOns;
