import React from "react";
import { Route, Routes } from "react-router-dom";
import RouteNotFound from "../../../utils/RouteNotFound";
import Help from "./Help";
import Disclaimer from "./Disclaimer";
import Terms from "./Terms";
import AnnualSubscription from "./annualSubscriptions";

const HelpIndex = () => {
  return (
    <Routes>
      <Route path="/" element={<Help />} />

      <Route path="/annualSubscription" element={<AnnualSubscription />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/Disclaimer" element={<Disclaimer fullPage={true} />} />

      <Route path="/*" element={<RouteNotFound />} />
    </Routes>
  );
};

export default HelpIndex;
