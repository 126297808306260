import React from "react";
import NavBar from "../../../utils/NavBar";
import { Button, Center, Grid, GridItem, Heading, VStack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Disclaimer from "../../../utils/Disclaimer";
import ModalDisclaimer from "../../../utils/ModalDisclaimer";

const AmendCorporationHome = () => {
  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <Center mt="50px">
        <VStack className="container" spacing="4">
          <Heading as="h1">Amend Corporation</Heading>
          <Heading as="h2">Choose from the following:</Heading>
          {/*  }
          <Button
            variant={"solid"}
            colorScheme="green"
            p="10"
            fontSize="180%"
            m="2"
            onClick={() => {
              navigate("changeCorporateInd");
            }}>
            Change Shareholder / Agent for Service / Director
          </Button>
          {  */}

          <Grid
            width="100%"
            className="gridParent"
            templateColumns={{ base: "auto", lg: "auto auto" }}
            gap={3}
            alignItems="center"
          >
            <GridItem>
              <VStack spacing="3">
                <Button
                  variant={"solid"}
                  colorScheme="blue"
                  p="10"
                  fontSize={{base:"120%", lg:"160%"}}
                  w="100%"
                  onClick={() => {
                    navigate("changeDirectors");
                  }}
                >
                  Change Directors
                </Button>
                <Button
                  variant={"solid"}
                  colorScheme="blue"
                  p="10"
                  fontSize={{base:"120%", lg:"160%"}}
                  w="100%"
                  onClick={() => {
                    navigate("changeShareHolder");
                  }}
                >
                  Change Shareholders
                </Button>
                <Button
                  variant={"solid"}
                  colorScheme="blue"
                  p="10"
                  fontSize={{base:"120%", lg:"160%"}}
                  w="100%"
                  onClick={() => {
                    navigate("changeAgent");
                  }}
                >
                  Change Agent for Service
                </Button>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack spacing="3">
                <Button
                  variant={"solid"}
                  colorScheme="yellow"
                  p="10"
                  fontSize={{base:"120%", lg:"160%"}}
                  w="100%"
                  onClick={() => {
                    navigate("changeAddress");
                  }}
                >
                  Change Corporate Address
                </Button>
                <Button
                  variant={"solid"}
                  colorScheme="yellow"
                  p="10"
                  fontSize={{base:"120%", lg:"160%"}}
                  w="100%"
                  onClick={() => {
                    navigate("changeName");
                  }}
                >
                  Change Corporate Name
                </Button>
                <Button
                  variant={"solid"}
                  colorScheme="yellow"
                  p="10"
                  fontSize={{base:"120%", lg:"160%"}}
                  w="100%"
                  onClick={() => {
                    navigate("amendShareStructure");
                  }}
                >
                  Change Share Structure
                </Button>
              </VStack>
            </GridItem>
          </Grid>

          <Grid
            width="100%"
            className="gridParent"
            templateColumns={{ base: "auto", lg: "1fr 1fr" }}
            gap={2}
            mt="24px"
          >
            <GridItem textAlign={{base: "center", lg: "right"}}>
              <Text>
                For all other services call us directly at:
              </Text>
            </GridItem>
            <GridItem>
              <Text textAlign={{base: "center", lg: "left"}} fontWeight="bold">
               {process.env.REACT_APP_PHONE}
              </Text>
            </GridItem>
          </Grid>
          
        </VStack>
      </Center>
      
      <Disclaimer />

      <ModalDisclaimer />
    </>
  );
};

export default AmendCorporationHome;
