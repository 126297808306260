import React from "react";
import NavBar from "../../../utils/NavBar";
import { Button, Center, Heading, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Disclaimer from "../../../utils/Disclaimer";
import ModalDisclaimer from "../../../utils/ModalDisclaimer";

const AmendPartnershipHome = () => {
  const navigate = useNavigate();
  return (
    <>
      <NavBar />
      
      <Center mt="50px">
        <VStack className="container">

          <Heading as="h1">Amend Partnership</Heading>
          <Heading as="h2">Choose from the following:</Heading>

          <VStack spacing="3" width="100%">
            <Button
              width={{base: "100%", lg: "50%"}}
              variant={"solid"}
              colorScheme="purple"
              p="10"
              fontSize={{base:"120%", lg:"180%"}}
              onClick={() => {
                navigate("amendPartners");
              }}
            >
              Modify / Update Partners' Info
            </Button>
            <Button
              width={{base: "100%", lg: "50%"}}
              variant={"solid"}
              colorScheme="blue"
              p="10"
              fontSize={{base:"110%", lg:"150%"}}
              onClick={() => {
                navigate("changeAddress");
              }}
            >
              Change Partnership Address
            </Button>
            {/* <Button
              variant={"solid"}
              colorScheme="yellow"
              p="10"
              fontSize="150%"
              m="2"
              w="80%"
              onClick={() => {
                navigate("changeName");
              }}
            >
              Change corporate name
            </Button> */}
            </VStack>
        </VStack>
      </Center>

      <Disclaimer />

      <ModalDisclaimer />
    </>
  );
};

export default AmendPartnershipHome;
