import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Input,
  IconButton,
  HStack,
  ListItem,
  List,
  Modal,
  Divider,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Center,
  RadioGroup,
  Radio
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";

const New = ({ request, setRequest }) => {
  const [isEditable, setIsEditable] = useState(false);
  // const [officers, sets] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    key: Date.now(),
    firstName: "",
    lastName: "",
    email: "",
    corpName: "",
    corpAccessNumber: "",
    occupation: "",
    appointmentDate: new Date().toISOString().split("T")[0],
    streetLine1: "",
    streetLine2: "",
    city: "",
    postalCode: "",
    country: "Canada",
    province: "Alberta"
  });

  const [modalPartnerType, setModalPartnerType] = useState('individual');

  const handlePartnerAdd = () => {
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      email: "",
      corpName: "",
      corpAccessNumber: "",
      occupation: "",
      appointmentDate: new Date().toISOString().split("T")[0],
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta"
    });
    setShowModal(true);
  };

  const handleEditOfficerPartner = (partner) => {
    setFormData({ ...partner });
    setIsEditable(true);
    setShowModal(true);
  };

  const handleDeleteNewOfficerPartner = (key) => {
    if (window.confirm("Are you sure you want to delete this new partner?")) {
      const updatedNewPartners = request.partners.newPartners.filter(
        (partner) => partner.key !== key
      );

      setRequest((prevRequest) => ({
        ...prevRequest,
        partners: {
          ...prevRequest.partners,
          newPartners: updatedNewPartners,
          results: [
            ...prevRequest.partners.currentPartners,
            ...updatedNewPartners,
          ].filter(
            (partner) =>
              !prevRequest.partners.deletedPartners.some(
                deleted => deleted.key === partner.key
              )
          ),
        }
      }));
    
      setFormData({
        key: Date.now(),
        firstName: "",
        lastName: "",
        email: "",
        corpName: "",
        corpAccessNumber: "",
        occupation: "",
        appointmentDate: new Date().toISOString().split("T")[0],
        streetLine1: "",
        streetLine2: "",
        city: "",
        postalCode: "",
        country: "Canada",
        province: "Alberta"
      });
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // Check if all required fields are filled
    let requiredFields = [
      "occupation",
      "streetLine1",
      "city",
      "postalCode",
      "province",
      "country"
    ];

    if (modalPartnerType === 'corporation'){
      requiredFields.push("corpName");
      requiredFields.push("corpAccessNumber");
    } else {
      requiredFields.push("firstName");
      requiredFields.push("lastName");
    }

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      alert("Please fill all required fields.");
      return;
    }

    setRequest((prevRequest) => {
      const updatedNewPartners = isEditable
        ? prevRequest.partners.newPartners.map((partner) =>
            partner.key === formData.key
              ? { ...partner, ...formData }
              : partner
            )
        : [...prevRequest.partners.newPartners, formData];

      const updatedPartners = {
        ...prevRequest.partners,
        newPartners: updatedNewPartners,
        results: [
          ...prevRequest.partners.currentPartners,
          ...updatedNewPartners
        ].filter(
          (partner) =>
            !prevRequest.partners.deletedPartners.some(
              (deleted) => deleted.key === partner.key
            )
        ),
      };

      return {
        ...prevRequest,
        partners: updatedPartners
      };
    });

    setShowModal(false);
    setFormData({
      key: Date.now(),
      firstName: "",
      lastName: "",
      email: "",
      corpName: "",
      corpAccessNumber: "",
      occupation: "",
      appointmentDate: new Date().toISOString().split("T")[0],
      streetLine1: "",
      streetLine2: "",
      city: "",
      postalCode: "",
      country: "Canada",
      province: "Alberta"
    });
    setIsEditable(false);
  };

  const handleClose = () => {
    setIsEditable(false);
    setShowModal(false);
  }

  return (
    <>
      <HStack justify={"space-between"}>
        <Heading as="h4" color="green.700">
          New Partners
        </Heading>
        <Button
          colorScheme="green"
          variant="solid"
          leftIcon={<AddIcon />}
          onClick={handlePartnerAdd}
        >
          Add
        </Button>
      </HStack>
      <FormLabel color={"gray.600"}>
        Partners that are being added are as follows:
      </FormLabel>
      <Box my={4}>
        <List
          borderBottom="solid 1px gray"
          ml={{base:"0", md:"10%"}}
          w={{base:"100%", md:"60%"}}
          color="green.500"
        >
          {request.partners.newPartners.map((partner, index) => (
            <ListItem key={partner.key}>
              <HStack justify="space-between">
                <Text fontWeight="bold" mb="0 !important">
                  {partner.firstName} {partner.lastName}
                  {partner.corpName} {partner.corpAccessNumber}
                </Text>
                <HStack>
                  <IconButton
                    icon={<EditIcon />}
                    onClick={() =>
                      handleEditOfficerPartner(partner)
                    }
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() =>
                      handleDeleteNewOfficerPartner(partner.key)
                    }
                  />
                </HStack>
              </HStack>
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider h="50px" />
      <Modal 
        isOpen={showModal}
        onClose={handleClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditable ? "Edit Partner" : "Add Partner"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            {!isEditable && (
            <Center>
              <RadioGroup
                onChange={setModalPartnerType}
                value={modalPartnerType}
                mb="24px"
              >
                <HStack>
                  <Radio value='individual'>Individual</Radio>
                  <Radio value='corporation'>Corporation</Radio>
                </HStack>
              </RadioGroup>
            </Center>
            )}

            <FormControl isRequired>

             {((!isEditable && modalPartnerType === 'individual') || formData.firstName !== "") && (
              <>
                <FormLabel>First Name:</FormLabel>
                <Input
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleFormChange}
                  mb="4"
                />
                <FormLabel>Last Name:</FormLabel>
                <Input
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleFormChange}
                  mb="4"
                />
              </>
              )}

              {((!isEditable && modalPartnerType === 'corporation') || formData.corpName !== "") && (
              <>
                <FormLabel>Corporation Name:</FormLabel>
                <Input
                  name="corpName"
                  value={formData.corpName}
                  onChange={handleFormChange}
                  mb="4"
                />
                
                <FormLabel>Corporate Access Number:</FormLabel>
                <Input
                  name="corpAccessNumber"
                  value={formData.corpAccessNumber}
                  onChange={handleFormChange}
                  mb="4"
                />
              </>
              )}

              <FormControl>
                <FormLabel>Email:</FormLabel>
                <Input
                  name="email"
                  value={formData.email}
                  onChange={handleFormChange}
                  mb="4"
                />
              </FormControl>

              <FormLabel>Occupation:</FormLabel>
              <Input
                name="occupation"
                value={formData.occupation}
                onChange={handleFormChange}
                mb="4"
              />
              
              <Text fontWeight="bold">Residential Address:</Text>
              {formData && (
                <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Address Line 1</FormLabel>
                      <Input                      
                        name="streetLine1"
                        value={formData.streetLine1}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl>
                      <FormLabel>Address Line 2</FormLabel>
                      <Input
                        name="streetLine2"
                        value={formData.streetLine2}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>City</FormLabel>
                      <Input
                        name="city"
                        value={formData.city}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Postal Code</FormLabel>
                      <Input
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Province</FormLabel>
                      <Input
                        name="province"
                        value={formData.province}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Country</FormLabel>
                      <Input
                        name="country"
                        value={formData.country}
                        onChange={handleFormChange}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme={isEditable ? "blue" : "green"} onClick={handleSubmit}>
              {isEditable ? "Update" : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default New;
