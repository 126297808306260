import React from "react";
import {
  Select
} from "@chakra-ui/react";

const Province = ({
  type,
  context,
  borderColor,
  name,
  value,
  onChange,
  width,
}) => {
  
  let provinces = [
    {name: "Alberta", value: "AB"},
    {name: "British Columbia", value: "BC"},
    {name: "Manitoba", value: "MB"},
    {name: "New Brunswick", value: "NB"},
    {name: "Newfoundland and Labrador", value: "NL"},
    {name: "Northwest Territories", value: "NT"},
    {name: "Nova Scotia", value: "NS"},
    {name: "Nunavut", value: "NU"},
    {name: "Ontario", value: "ON"},
    {name: "Prince Edward Island", value: "PE"},
    {name: "Quebec", value: "QC"},
    {name: "Saskatchewan", value: "SK"},
    {name: "Yukon", value: "YK"},
  ];

  if (type == 'homeJurisdiction'){
    if (context && context === "nwpta"){
      provinces = [
        {name: "British Columbia", value: "BC"},
        {name: "Manitoba", value: "MB"},
        {name: "Saskatchewan", value: "SK"},
      ];
    } else {
      provinces = [
        {name: "New Brunswick", value: "NB"},
        {name: "Newfoundland and Labrador", value: "NL"},
        {name: "Northwest Territories", value: "NT"},
        {name: "Nova Scotia", value: "NS"},
        {name: "Nunavut", value: "NU"},
        {name: "Ontario", value: "ON"},
        {name: "Prince Edward Island", value: "PE"},
        {name: "Quebec", value: "QC"},
        {name: "Yukon", value: "YK"},
        {name: "Federal (Canada)", value: "Federal"},
      ];
    }
  }
  
  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      width={width || { base: "100%", md: "30ch" }}
      borderColor={borderColor}
    >
      {provinces.map((province, index) => (
        <option key={index} value={province.value}>
          {province.name}
        </option>
      ))}
    </Select>
  );
};

export default Province;
