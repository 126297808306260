// import React from "react";
import { useEffect, useState } from "react";
import {
  Heading,
  Text,
  Input,
  HStack,
  Select,
  FormLabel,
  FormControl,
  Grid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import InfoButton from "../../../../utils/Popover";
import Province from "../../../../utils/Province";
import YesNoField from "../../../../utils/YesNoField";

const HomeJurisdictionInfo = ({ companyType, request, setRequest, context }) => {

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChangeHomeJurisdictionInfo = (field, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
     homeJurisdictionInfo: {
        ...prevRequest.homeJurisdictionInfo,
        [field]: value,
      },
    }));

    if (field === "currentHomeJurisdiction" && value === "Federal"){
      request.togglePrice("Yes", "FederalDiscount");
    } else {
      request.togglePrice("No", "FederalDiscount");
    }
  };

  return (
    <>
      <Grid
        templateColumns={{ base: "1fr", md: "auto 1fr" }}
        gap={0}        
      >
        <GridItem>
          <Heading mb="16px !important" pr="20">
            Home Jurisdiction Information
          </Heading>
        </GridItem>
        <GridItem textAlign="center">
          <Button colorScheme="blue" variant="ghost" onClick={onOpen} mb="24px"> 
            Where to find?
          </Button>
        </GridItem>
      </Grid>
      <FormControl>
        <Grid
          templateColumns={{ base: "1fr", md: "auto 1fr" }}
          gap={3}
          alignItems="center"
        >
          <GridItem>
            <HStack>
              <FormLabel>
                My company's principal business is
              </FormLabel>
              <InfoButton info="Type of business / industry like Real Estate, Barber shop, Construction" />
            </HStack>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              placeholder="Hotels, Barber, Construction, Real Estate Listing, Agriculture etc"
              value={request.homeJurisdictionInfo.principalBusiness}
              onChange={(e) => handleChangeHomeJurisdictionInfo("principalBusiness", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My company's current home jurisdiction is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Province
              type="homeJurisdiction"
              context={context}
              value={request.homeJurisdictionInfo.currentHomeJurisdiction}
              onChange={(e) =>
                handleChangeHomeJurisdictionInfo("currentHomeJurisdiction", e.target.value)
              }
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My company's home jurisdiction registration number is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.homeJurisdictionInfo.homeRegistrationNumber}
              onChange={(e) =>
                handleChangeHomeJurisdictionInfo("homeRegistrationNumber", e.target.value)
              }
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              My company's home jurisdiction business number is
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              value={request.homeJurisdictionInfo.homeBusinessNumber}
              onChange={(e) => handleChangeHomeJurisdictionInfo("homeBusinessNumber", e.target.value)}
            />
          </GridItem>

          <GridItem>
            <FormLabel pr="20">
              Date of incorporation in home jurisdiction
            </FormLabel>
          </GridItem>
          <GridItem>
            <Input
              w={{ base: "100%", md: "30ch" }}
              type="date"
              value={request.homeJurisdictionInfo.homeIncorporationDate}
              onChange={(e) => handleChangeHomeJurisdictionInfo("homeIncorporationDate", e.target.value)}
            />
          </GridItem>
        </Grid>
      </FormControl>

      <br />
      <br />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Home Jurisdiction Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as="h3" color="green.500">
              Where to find?
            </Heading>
            <Text>
              You can find all the below information on the company's
              Articles of Incorporation, given by your home jurisdiction.
            </Text>
            <Text>
              Articles of Incorporation are given to you at the time of registration by your home jurisdiction.
              You can also obtain one via doing a corporate search through us (paid).
            </Text>
          </ModalBody>
          <ModalFooter>
            <HStack spacing="2">
              <Button
                variant="ghost"
                color="black"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>

              <Button
                colorScheme="yellow"
                variant="solid"
                onClick={() => {
                  window.location.href = "tel:+1(403) 262 9999";
                }}
              >
                Call Us
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default HomeJurisdictionInfo;
