import React from "react";
import {
  Flex,
  HStack,
  Heading,
  VStack,
  Button,
  Divider,
  Text,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../utils/NavBar.js";
import Disclaimer from "../../../utils/Disclaimer.js";

const DecideBNameProtection = ({ setBNameProtection }) => {
  const navigate = useNavigate();

  return (
    <>
      <NavBar />

      <Flex justifyContent="center" pt="50px">
        <VStack align="start" className="container" spacing={4}>
          <Button
            colorScheme="gray"
            variant="outline"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              navigate("/decide");
            }}
          >
            Back
          </Button>

          <Divider className="divider" />

          <Heading>Do you want to secure your business name?</Heading>

          <Text fontSize="150%" mt="24px">
            If you want to stop other entities from using your business name, so that it remains unique to your business, 
            select <b>Yes</b>.
          </Text>
          {/* <Text fontSize="120%">
            If you want to secure your business name, please select Yes
          </Text> */}
          <Text fontSize="120%" mb="24px" mt="12px">If you have no need to secure a unique business name, please select <b>No</b>.</Text>
          
          <HStack spacing={4}>
            <Button className="btn-black" onClick={()=>{navigate('/decide/ltd-liability')}}>Yes</Button>
            <Button className="btn-black" onClick={()=>{navigate('/decide/ltd-liability')}}>No</Button>
          </HStack>
        </VStack>
      </Flex>

      <Disclaimer />
    </>
  );
};

export default DecideBNameProtection;
