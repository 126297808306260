const empReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOGIN":
      // handle adding to the cart
      return {
        state: action.payload,
      };

    case "LOGOUT":
      // handle removing from the cart
      return {
        state: {},
      };

    default:
      return state;
  }
};

export default empReducer;
