import { useState } from "react";
import {
  Heading,
  Textarea,
  Text,
  FormLabel,
  HStack,
  Flex,
} from "@chakra-ui/react";

import YesNoField from "../../../../utils/YesNoField";

const UnanimousShareholderAgreement = ({ request, setRequest }) => {
  const [ownUSA, setOwnUSA] = useState("");
  const [NURUSA, setNURUSA] = useState("");

  const handleYesNoChange = (value, type) => {
    request.toggleYesNo(value, type);

    if (value === 'Yes'){
      setOwnUSA(true);
      setNURUSA(false);
      request.togglePrice('No', 'UnanimousShareholderAgreement');
      setRequest((prevReq) => ({
        ...prevReq,
        minuteBookInfo: {
          ...prevReq.minuteBookInfo,
          unanimousShareholderAgreement: "",
          unanimousShareholderAgreementRequirements: ""
        }
      }));
    } else {
      setOwnUSA(false);
      setRequest((prevReq) => ({
        ...prevReq,
        minuteBookInfo: {
          ...prevReq.minuteBookInfo,
          unanimousShareholderAgreement: ""
        }
      }));
    }
  };

  const handleYesNoNURChange = (value, type) => {
    request.togglePrice(value, type);

    if (value === 'Yes'){
      setNURUSA(true);
      setRequest((prevReq) => ({
        ...prevReq,
        minuteBookInfo: {
          ...prevReq.minuteBookInfo,
          unanimousShareholderAgreement: "Snappy Corp"
        }
      }));
    } else {
      setNURUSA(false);
      setRequest((prevReq) => ({
        ...prevReq,
        minuteBookInfo: {
          ...prevReq.minuteBookInfo,
          unanimousShareholderAgreement: "",
          unanimousShareholderAgreementRequirements: ""
        }
      }));
    }
  };

  const ownEnabled = request.yesno.UnanimousShareholderAgreement_Own.enabled;
  const templateEnabled = request.prices.UnanimousShareholderAgreement.enabled;

  return (
    <>
      <HStack flex="space-between" alignItems="center">
        <Heading as="h3" mt="24px !important">
          Unanimous Shareholder Agreement
        </Heading>
        <Text color="green" fontSize="250%" fontWeight={"bold"} mt="28px !important">
          <span className="price">+$50 CAD</span>
        </Text>
      </HStack>
      
      <Text>
        A unanimous shareholder agreement is an agreement among all the shareholders of a corporation in relation to the management of the corporation.
      </Text>

      <YesNoField
        label={"Do you have your own Unanimous Shareholder Agreement?"}
        about={"If you have your own unanimous shareholder agreement, usually lawyers or your previous experience might help you choose this option."}
        setValue={(value) => handleYesNoChange(value, 'UnanimousShareholderAgreement_Own')}
        enabled={ownEnabled}
      />

      {(ownUSA === true || ownEnabled === true) && (
        <>
          <FormLabel color="blue.500" mt="12px">
            Please enter your own unanimous shareholder agreement:
          </FormLabel>
          <Flex>
            <Textarea
              h="300px"
              border={"solid 2px orange"}
              autoFocus
              value={request.minuteBookInfo.unanimousShareholderAgreement}
              onChange={(e) =>
                setRequest((prevReq) => ({
                  ...prevReq,
                  minuteBookInfo:{
                    ...prevReq.minuteBookInfo,
                    unanimousShareholderAgreement: e.target.value,
                  }
                }))
              }
            />
          </Flex>
        </>
      )}

      {(ownUSA === false || ownEnabled === false) && (

        <YesNoField
          label={"Do you want us to assist you in creating a unanimous shareholder agreement?"}
          about={"We with the help of lawyers have created a generalized Unanimous Shareholder Agreement, which hundreds of companies found the right fit for them."}
          setValue={(value) => handleYesNoNURChange(value, 'UnanimousShareholderAgreement')}
          enabled={templateEnabled}
          price={request.prices.UnanimousShareholderAgreement.value}
        />

      )}

      {((ownUSA === false && NURUSA === true)
      || (ownEnabled === false && templateEnabled === true))
      && (
        <>
          <FormLabel color="blue.500" mt="12px">
            Please enter any requirements for the agreement:
          </FormLabel>
          <Flex>
            <Textarea
              h="300px"
              border={"solid 2px orange"}
              autoFocus
              value={request.minuteBookInfo.unanimousShareholderAgreementRequirements}
              onChange={(e) =>
                setRequest((prevReq) => ({
                  ...prevReq,
                  minuteBookInfo:{
                    ...prevReq.minuteBookInfo,
                    unanimousShareholderAgreementRequirements: e.target.value,
                  }
                }))
              }
            />
          </Flex>
        </>
      )}
      {((ownUSA === false && NURUSA === false)
      || (ownEnabled === false && templateEnabled === false))
      && (
        <>
          <Text color="red" fontWeight="bold" my="24px !important">
            It is recommended to have your company's unanimous shareholder agreement to avoid future problems.
          </Text>
        </>
      )}
    </>
  );
};

export default UnanimousShareholderAgreement;
